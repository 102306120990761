import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SubLayout from "../../components/layout/SubLayout";
import UserDetails from "../../components/common/user/userDetail";

function UserDetail() {
  const { type } = useParams();
  const name = `${type?.charAt(0)?.toUpperCase() + type?.substring(1, type.length)} user detail`;
  const nameView = `User detail ${type}`;
  const navigate = useNavigate();
  const { state } = useLocation();
  const backUrlFunction = () => {
    navigate("/user-management", {
      state: {
        page: state?.page,
        filter: state?.filter,
        search: state?.search,
        path: state?.path,
      },
    });
  };
  return (
    <SubLayout
      page={type === 'view' ? nameView : name}
      showBack
      backUrlFunction={backUrlFunction}
      isBackFunc={true}
    >
      <UserDetails />
    </SubLayout>
  );
}

export default UserDetail;
