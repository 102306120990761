import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Row, Col, Table, Input, message, Button, Switch, Tooltip } from "antd";
import { DeleteCandidate } from "../../components/common/Popup";
import API_MANAGER from "../../API";
import DeleteIcon from "../../Assets/delete.svg";
import EditIcon from "../../Assets/editIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import GreenTracker from "../../Assets/client/GreenTracker.svg";
import RedTracker from "../../Assets/client/RedTracker.svg";
import CustomPagination from "../../components/common/CustomPagination";

function RecruiterList() {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [filter, setFilter] = useState("all");
  const [page, setPage] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();

  const getData = async (search) => {
    setLoading(true);
    if (page) {
      try {
        const response = await API_MANAGER.getRecruiterList({
          search: search,
          page: page,
          sort: filter === "all" ? "" : filter,
        });
        setData(response?.data?.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        message.error("Something went wrong!");
        setLoading(false);
      }
    }
  };
  const toggleStatus = async (status, id) => {
    setLoading(true);
    try {
      await API_MANAGER.toggleUser({ isActive: status }, id);
      message.success(
        `Successfully ${status === true ? "enabled" : "disabled"} client.`
      );
      getData();
    } catch {
      message.error("Something went wrong");
    }
    setLoading(false);
  };
  const userType = localStorage.getItem("userType");
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page - 1) * 10 + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.companyName}</span>;
      },
    },
    {
      title: "Trading Name",
      dataIndex: "tradingName",
      key: "tradingName",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.tradingName}</span>;
      },
    },
    {
      title: "ACN/ABN",
      dataIndex: "companyAbn",
      key: "abn",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.companyAbn}</span>;
      },
    },
    {
      title: "Billing Name",
      dataIndex: "billingName",
      key: "billingName",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.billingName}</span>;
      },
    },
    {
      title: "Billing Email",
      dataIndex: "billingEmail",
      key: "billingEmail",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.billingEmail}</span>;
      },
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
      render: (_, row) => {
        return (
          <span className="cursor-pointer">
            {row?.subscription
              ? row?.subscription?.subscriptionProductId?.name
              : "-"}
          </span>
        );
      },
      align: "center",
    },
    {
      title: "Marketing Permissions",
      dataIndex: "marketingPermission",
      key: "marketingPermission",
      width: "150px",
      align: 'center',
      filters: [
        {
          text: "ALL",
          value: "all",
        },
        {
          text: "Checked",
          value: "-marketingPermission",
        },
        {
          text: "Unchecked",
          value: "marketingPermission",
        },
      ],
      defaultFilteredValue: ["all"],
      filterMultiple: false,
      onFilter: (value, record) => {
        setFilter(value);
        return record;
      },
      render: (_, row) => {
        return (
          <span className="cursor-pointer">
            {row.marketingPermission ? (
              <img src={GreenTracker} />
            ) : (
              <img src={RedTracker} />
            )}
          </span>
        );
      },
    },
    {
      title: (
        <span>
          Enable / <br /> Disable
        </span>
      ),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (text, row) => (
        <span onClick={(e)=> e.stopPropagation()}>
        <Switch
          defaultChecked={row?.userId?.isActive}
          onClick={(e) => {
            toggleStatus(e, row?.userId?._id);
          }}
        />
         </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      render: (text, row) => {
        return (
          <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
            <div onClick={(e)=> e.stopPropagation()} style={{ display: "flex" }}>
              <span>
                <img
                  style={{ width: "35px" }}
                  className="cursor-pointer"
                  src={EditIcon}
                  alt="edit"
                  onClick={() => {
                    userType === "admin"
                      ? navigate(
                          `/client-list/client-detail/edit/${row?.userId?._id}`
                        )
                      : "";
                  }}
                />
              </span>
              <span>
                {userType === "admin" ? (
                  <img
                    src={DeleteIcon}
                    alt="delete"
                    style={{ width: "35px" }}
                    className="cursor-pointer"
                    onClick={() => {
                      setDeleteId(row?.userId?._id);
                      setDeleteModal(true);
                    }}
                  />
                ) : (
                  <img
                    src={DeleteIcon}
                    alt="delete"
                    style={{ width: "35px" }}
                    className="cursor-pointer"
                  />
                )}
              </span>
            </div>
          </Tooltip>
        );
      },
    },
  ];
  const deleteConfirm = async () => {
    setConfirmLoading(true);
    try {
      await API_MANAGER.deleteRecruiter(deleteId);
      message.success("Successfully deleted client");
      getData(search);
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message || "Something went wrong"
      );
    }
    setConfirmLoading(false);
    setDeleteModal(false);
  };

  useEffect(() => {
    if (page) {
      getData(search);
    }
  }, [search, page,filter]);

  useEffect(() => {
    if (state && state?.path === "/client-list") {
      setSearch(state.search);
      if (state?.page) {
        setPage(state.page);
      } else {
        setPage(1);
      }
      if(state?.filter){
        setFilter(state.filter);
      }
      if(state?.search){
        setSearch(state?.search);
      }
      getData(search);
      // navigate("", { state: {} });
    } else {
      setPage(1);
      setFilter('all');
    }
  }, [state]);
  return (
    <SubLayout page="Client List">
      <Row className="search_container" align={"middle"} gutter={5}>
        <Col>
          <Input.Search
            placeholder="Search"
            allowClear
            value={search}
            onChange={(e) => {
              setSearch(e?.target?.value), setPage(1);
            }}
          />
        </Col>
        <Col className="btn_Col">
          <Button
            className="bulk_button"
            onClick={() => navigate(`/client-list/client-detail/create`)}
          >
            Add Client
          </Button>
        </Col>
      </Row>
      <Row className="table_container">
        <Table
          columns={columns}
          dataSource={data?.result}
          pagination={false}
          className="w-100"
          rowKey={"id"}
          loading={loading}
          scroll={{
            y: "calc(100vh - 355px)",
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(
                  `/client-list/client-detail/view/${record?.userId?._id}`,
                  {
                    state: {
                      page: page,
                      filter: filter,
                      search: search,
                      path: "/client-list",
                    },
                  }
                );
              }, // click row
            };
          }}
        />
        <div className="w-100">
          <CustomPagination
            currentPage={page}
            setCurrentPage={setPage}
            total={data?.totalRecruiter}
            itemPerPage={10}
          />
        </div>
      </Row>
      <DeleteCandidate
        confirmDelete={deleteConfirm}
        visible={openDeleteModal}
        text={"Client"}
        setVisible={setDeleteModal}
        loading={confirmLoading}
      />
    </SubLayout>
  );
}

export default RecruiterList;
