import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Image, Progress } from "antd";
import PasswordIcon from "../../../Assets/login/password.svg";
import AlertImg from "../../../Assets/login/alert.svg";

import API_MANAGER from "../../../API";
import { useNavigate } from "react-router-dom";
function NewPassword({ token }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [currentPassword, setCurrentPassword] = useState("");

  const navigate = useNavigate();
  const handleSubmit = async (value) => {
    if (value?.password !== value?.confirm_password) {
      message.warning("Both password should be same!");
    } else if (!value?.password.match(/[A-Z]/)) {
      message.warning("Password needs to have at least 1 capital letter");
      return;
    } else if (!value?.password.match(/[a-z]/)) {
      message.warning("Password needs to have at least 1 small letter");
      return;
    } else if (
      !value?.password.match(/[!@#$%^&*(),.?":{}|<>]/) ||
      !value?.password.match(/[1-9]/)
    ) {
      message.warning(
        "Password needs to have at least 1 special character and 1 numeric character"
      );
      return;
    } else if (!(value?.password.length >= 8)) {
      message.warning("Password needs to be at least 8 characters long");
      return;
    }
    setLoading(true);
    try {
      await API_MANAGER.createNewPassword(
        {
          password: value?.password,
        },
        token
      );
      message.success("Your password successfully changed!");
      navigate("/login");
    } catch (error) {
      message.error(
        error.response.data.message.message === "Cannot set old password"
          ? "Current password should be different from an old password"
          : "Something went wrong"
      );
    }
    setLoading(false);
  };

  const validatePassword = (e) => {
    setProgressPercentage(0);
    if (e?.match(/[A-Z]/) || e?.match(/[a-z]/) || e?.match(/[1-9]/)) {
      setProgressPercentage(25);
    }
    if (
      (e?.match(/[a-z]/) && e?.match(/[A-Z]/)) ||
      (e?.match(/[a-z]/) && e?.match(/[1-9]/)) ||
      (e?.match(/[1-9]/) && e?.match(/[A-Z]/))
    ) {
      setProgressPercentage(50);
    }
    if (e?.match(/[!@#$%^&*(),.?":{}|<>]/)) {
      setProgressPercentage(75);
    }
    if (e?.match(/[1-9]/) && e?.match(/[a-z]/) && e?.match(/[A-Z]/)) {
      setProgressPercentage(85);
    }
    if (
      e?.length >= 8 &&
      e?.match(/[1-9]/) &&
      e?.match(/[a-z]/) &&
      e?.match(/[A-Z]/) &&
      e?.match(/[!@#$%^&*(),.?":{}|<>]/)
    ) {
      setProgressPercentage(100);
    }
  };
  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword) {
      setPasswordNotMatch(false);
    } else if (
      password?.length > 0 &&
      confirmPassword?.length > 0 &&
      password !== confirmPassword
    ) {
      setPasswordNotMatch(true);
    } else {
      setPasswordNotMatch(false);
    }
  }, [confirmPassword, password]);
  return (
    <div className="login_main_container">
      <div className="login_container">
        <Row>
          <Col span={12} className="login-form-container">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/login");
              }}
            >
              <Image src="/assets/login/banner.svg" preview={false} />
            </div>
            <div className="form-outer-div">
              <div className="form-inner-div">
                <p className="heading1 forgot-text"> Create New Password</p>
                <p className="desc"> Please enter your details</p>
                <Form form={form} onFinish={handleSubmit}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "New password is required",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter new password"
                          type="password"
                          onChange={(e) => {
                            validatePassword(e.target.value);
                            setPassword(e?.target?.value);
                          }}
                          prefix={<img src={PasswordIcon} alt="lock" />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    {progressPercentage ? (
                      <Row align={"middle"} gutter={16}>
                        <Col
                          className="progressbar_margin"
                          style={{
                            width: "209px",
                          }}
                        >
                          <Progress
                            percent={progressPercentage}
                            className="formProgressBar"
                            showInfo={false}
                          />
                        </Col>
                        <Col className="progressbar_margin">
                          {progressPercentage < 100 ? (
                            <span className="weakText">Weak</span>
                          ) : (
                            <span className="strongText">Strong</span>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Row>
                  <div>
                    {progressPercentage > 0 && progressPercentage !== 100 && (
                      <Row style={{ marginBottom: "20px" }}>
                        <div className="errorDescBox">
                          Your password must contain at least:{" "}
                          <span>
                            8 Characters, 1 Upper Case Letter, 1 Lower Case
                            Letter, 1 Number
                          </span>
                        </div>
                      </Row>
                    )}
                  </div>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="confirm_password"
                        rules={[
                          {
                            required: true,
                            message: "Confirm password is required",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Confirm your password ff"
                          type="password"
                          onChange={(e) => setConfirmPassword(e?.target?.value)}
                          prefix={<img src={PasswordIcon} alt="lock" />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {passwordNotMatch ? (
                    <Row align={"middle"} gutter={8} className="alertMsg">
                      <Col>
                        <img src={AlertImg} alt="alert" />
                      </Col>
                      <Col>
                        <span>Passwords doesn’t match</span>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          style={{ width: "100%" }}
                          className="submitBtn"
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
          <Col span={12} className="banner-image">
            <Image src="/assets/login/login-banner.png" preview={false} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default NewPassword;
