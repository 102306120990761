import "antd/dist/reset.css";
import "./styles/index.scss";
import CustomRoutes from "./Routes/Routes";
import ProtectedRoute from "./Routes/ProtectedRoute";
import Login from "./pages/login";
import SetPassword from "./pages/login/setPassword";
import ForgotPassword from "./pages/forgotpassword";
import React from "react";
import { Route, Routes } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route path="*" element={<div>404</div>} /> */}
        <Route path="/set-password/:token" element={<SetPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/*" element={<CustomRoutes />} />
        </Route>
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </div>
  );
}

export default App;
