import { Col, Row, message } from "antd";
import SubLayout from "../../components/layout/SubLayout";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API_MANAGER from "../../API";
import { PcView, PhoneView } from "../../Assets/campaign/svgs";
import moment from "moment";
import PhoneViewModal from "./PhoneViewModal";

function AdvertDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [phoneModal, setPhoneModal] = useState(false);

  const handlePhoneView = () => {
    setPhoneModal(!phoneModal);
  };

  const getData = async () => {
    try {
      const response = await API_MANAGER.getAdvertDetail(id);
      setData(response?.data?.data);
    } catch {
      message.error("Something went wrong");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <SubLayout page={"Advert Detail"} showBack>
      <div className="detail_container">
        <Row>
          <Col xs={24} className="tab-btn-holder">
            <div
              style={{ borderRadius: "12px 0px 0px 12px " }}
              className={`${!phoneModal ? "active" : "inactive"}`}
              onClick={handlePhoneView}
            >
              <PcView
                color={!phoneModal ? "white" : "rgba(135, 158, 241, 1)"}
              />
            </div>
            <div
              style={{ borderRadius: "0px 12px 12px 0px " }}
              className={`${!phoneModal ? "inactive" : "active"}`}
              // onClick={() => setscreenView(false)}
              onClick={handlePhoneView}
            >
              <PhoneView
                color={!phoneModal ? "rgba(135, 158, 241, 1)" : "white"}
              />
            </div>
          </Col>
        </Row>
        {phoneModal ? (
          <PhoneViewModal
            phoneViewData={data}
            phoneModal={phoneModal}
            setPhoneModal={setPhoneModal}
          />
        ) : (
          <>
            <Row className="detail_row">
              <Col xs={4} className="detail_title">
                Advert Name:
              </Col>
              <Col xs={8} className="detail_title_value">
                {data?.jobTitle?.name}
              </Col>
              <Col xs={4} className="detail_title">
                Salary/Rate:
              </Col>
              <Col xs={8} className="detail_title_value">
                {data?.salary}
              </Col>
            </Row>
            <Row className="detail_row">
              <Col xs={4} className="detail_title">
                Hours (Per Week):
              </Col>
              <Col xs={8} className="detail_title_value">
                {data?.hours}
              </Col>
              <Col xs={4} className="detail_title">
                Roster:
              </Col>
              <Col xs={8} className="detail_title_value">
                {data?.roster?.name}
              </Col>
            </Row>
            <Row className="detail_row">
              <Col xs={4} className="detail_title">
                Discipline:
              </Col>
              <Col xs={8} className="detail_title_value">
                {data?.discipline?.name}
              </Col>
              <Col xs={4} className="detail_title">
                Job Duration:
              </Col>
              <Col xs={8} className="detail_title_value">
                {data?.jobDuration?.name}
              </Col>
            </Row>
            <Row className="detail_row">
              <Col xs={4} className="detail_title">
                Employment Type:
              </Col>
              <Col xs={8} className="detail_title_value">
                {data?.employmentType?.name}
              </Col>
              <Col xs={4} className="detail_title">
                Industry:
              </Col>
              <Col xs={8} className="detail_title_value">
                {data?.industry?.name}
              </Col>
            </Row>
            <Row className="detail_row">
              <Col xs={4} className="detail_title">
                Start Date:
              </Col>
              <Col xs={8} className="detail_title_value">
                {moment(data?.startDate).format("DD/MM/YYYY")}
              </Col>
              <Col xs={4} className="detail_title">
                End Date:
              </Col>
              <Col xs={8} className="detail_title_value">
                {moment(data?.endDate).format("DD/MM/YYYY")}
              </Col>
            </Row>
            <Row className="detail_row">
              <Col xs={4} className="detail_title">
                Location:
              </Col>
              <Col xs={8} className="detail_title_value">
                {data?.state?.name + ", " + data?.suburb + ", "  + data?.postalCode}
              </Col>
            </Row>
            <Row className="detail_row">
              <Col xs={4} className="detail_title">
                Job Description:
              </Col>
              <Col xs={20} className="detail_title_value">
                {data?.jobDescription}
              </Col>
            </Row>
          </>
        )}
      </div>
    </SubLayout>
  );
}
export default AdvertDetail;
