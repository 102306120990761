import React from "react";
import { Row, Col, Card } from "antd";

import ProjectIcon from "../../../Assets/job/Project.svg";
import LocationIcon from "../../../Assets/job/Location.svg";
import JobTitleIcon from "../../../Assets/job/jobTitle.svg";
import SalaryIcon from "../../../Assets/job/salary.svg";
import RosterIcon from "../../../Assets/job/roster.svg";
import JobDurationIcon from "../../../Assets/job/Time.svg";
import EmploymentTypeIcon from "../../../Assets/job/employment.svg";
import CloseDateIcon from "../../../Assets/job/Time.svg";
function JobCard({ data }) {
  return (
    <div>
      <Card title={data?.companyName} bordered={false}>
        <Row align={"middle"} gutter={[8, 8]} className="mb-8">
          <Col>
            <Row align={"middle"} gutter={8}>
              <Col>
                <img
                  src={ProjectIcon}
                  alt="pr"
                  style={{ width: "18px", height: "18px" }}
                />
              </Col>
              <Col className="heading">Project Name : </Col>
            </Row>
          </Col>
          <Col className="item_value"> {` ${data?.projectName}`}</Col>
        </Row>
        <Row align={"middle"} gutter={[8, 8]} className="mb-8">
          <Col>
            <Row align={"middle"} gutter={8}>
              <Col>
                <img
                  src={LocationIcon}
                  alt="location"
                  style={{ width: "18px", height: "18px" }}
                />
              </Col>
              <Col className="heading">Location : </Col>
            </Row>
          </Col>
          <Col className="item_value"> {` ${data?.location}`}</Col>
        </Row>
        <Row align={"middle"} gutter={[8, 8]} className="mb-8">
          <Col>
            <Row align={"middle"} gutter={8}>
              <Col>
                <img
                  alt="img"
                  src={JobTitleIcon}
                  style={{ width: "18px", height: "18px" }}
                />
              </Col>
              <Col className="heading">Job Title : </Col>
            </Row>
          </Col>
          <Col className="item_value"> {` ${data?.jobTitle}`}</Col>
        </Row>
        <Row align={"middle"} gutter={[8, 8]} className="mb-8">
          <Col>
            <Row align={"middle"} gutter={8}>
              <Col>
                <img
                  src={SalaryIcon}
                  alt="img"
                  style={{ width: "18px", height: "18px" }}
                />
              </Col>
              <Col className="heading">Salary/Rate : </Col>
            </Row>
          </Col>
          <Col className="item_value"> {` ${data?.salary}`}</Col>
        </Row>
        <Row align={"middle"} gutter={[8, 8]} className="mb-8">
          <Col>
            <Row align={"middle"} gutter={8}>
              <Col>
                <img
                  alt="img"
                  src={RosterIcon}
                  style={{ width: "18px", height: "18px" }}
                />
              </Col>
              <Col className="heading">Roster/Hours : </Col>
            </Row>
          </Col>
          <Col className="item_value"> {` ${data?.roster}`}</Col>
        </Row>
        <Row align={"middle"} gutter={[8, 8]} className="mb-8">
          <Col>
            <Row align={"middle"} gutter={8}>
              <Col>
                <img
                  alt="img"
                  src={JobDurationIcon}
                  style={{ width: "18px", height: "18px" }}
                />
              </Col>
              <Col className="heading">Job Duration : </Col>
            </Row>
          </Col>
          <Col className="item_value"> {` ${data?.jobDuration}`}</Col>
        </Row>
        <Row align={"middle"} gutter={[8, 8]} className="mb-8">
          <Col>
            <Row align={"middle"} gutter={8}>
              <Col>
                <img
                  alt="img"
                  src={EmploymentTypeIcon}
                  style={{ width: "18px", height: "18px" }}
                />
              </Col>
              <Col className="heading">Employment Type : </Col>
            </Row>
          </Col>
          <Col className="item_value"> {` ${data?.employmentType}`}</Col>
        </Row>
        <Row align={"middle"} gutter={[8, 8]} className="mb-8">
          <Col>
            <Row align={"middle"} gutter={8}>
              <Col>
                <img
                  src={CloseDateIcon}
                  alt="time"
                  style={{ width: "18px", height: "18px" }}
                />
              </Col>
              <Col className="heading">Close Date : </Col>
            </Row>
          </Col>
          <Col className="item_value"> {` ${data?.closeDate}`}</Col>
        </Row>
      </Card>
    </div>
  );
}

export default JobCard;
