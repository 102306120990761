import React from "react";
import { Col, Form, Input, Row } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function phoneNumber() {
  return (
    <>
      <Row>
        <Col style={{ marginTop: "8px" }} xs={4}>
          <Form.Item
            name="countryCode"
            rules={[
              {
                required: true,
                message: "Please select country code",
              },
            ]}
            initialValue={'+61'}
          >
            <PhoneInput
              international
              className="phoneInputField input-phn"
              placeholder="Enter contact number"
              defaultCountry="AU"
            />
          </Form.Item>
        </Col>
        <Col style={{ marginTop: "8px" }} xs={20}>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please input contact number",
                type: "tel",
              },
            ]}
          >
            <Input
              type="number"
              className="input-phn-no"
              placeholder="Contact number"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
export default phoneNumber;
