import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Divider,
  Collapse,
  Modal,
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Switch,
  DatePicker,
  message,
  Spin,
} from "antd";
import moment from "moment";
import EditCandidateIcon from "../../../Assets/editCandidateIcon.svg";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import API_MANAGER from "../../../API";

function JobPositionCard({ data, discipline, induction, industry, skills, profileId, jobTitle, onUpdate }) {
  const { type } = useParams();
  const [expForm] = Form.useForm();
  const [showReferee, setShowReferee] = useState(data?.hasReferee);
  const [loading, setLoading] = useState(false);
  const [expEdit, setExpEdit] = useState(false);
  const handleUpdate = async (values) => {
    try {
      setLoading(true);
      values.profileId = profileId
      values.experiencId = data._id;
      values.profileId = profileId;
      await API_MANAGER.editCandidateExperience(values, data._id);
      message.success('Successfully Edited Experience');
      setExpEdit(false);
      setLoading(false);
      onUpdate && onUpdate()
    } catch (error) {
      message.error(error?.response?.data?.message?.message)
    }
  };
  const setFields = () => {
    expForm.setFieldsValue({
      employerName: data.employerName,
      projectName: data.projectName,
      industry: data.industry._id,
      discipline: data.discipline._id,
      induction: data?.inductions?.map((item) => {
        return item?.id;
      }),
      skills: data?.skills?.map((item) => {
        return item?.id;
      }),
      refereeName: data.refereeName,
      refereePosition: data.refereePosition,
      refereeContact: data.refereeContact,
      jobTitle: data.jobTitle._id,
      hasReferee: data.hasReferee,
      currentRole: data.currentRole,
      startDate: dayjs(data.startDate),
      endDate: data.endDate ? dayjs(data.endDate) : null,
    });
  };
  useEffect(() => {
    setFields();
  }, []);
  return (
    <>
      <div className="jobPositionCard">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "5px",
          }}
        >
          {type === "edit" ? (
            <img src={EditCandidateIcon} onClick={() => setExpEdit(true)} style={{ cursor: 'pointer' }} />
          ) : (
            ""
          )}
        </div>
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <p className="title">{data?.jobTitle?.name}</p>
          </Col>
          {data?.currentRole && (
            <Col>
              <div className="currentRole">Current Role</div>
            </Col>
          )}
        </Row>
        <div className="mt-20">
          <Row gutter={8}>
            <Col span={16}>
              <p className="item">
                Start Date:{" "}
                <span>{moment(data?.startDate)?.format("MMM YYYY")}</span>
              </p>
            </Col>
            <Col span={8}>
              <p className="item">
                End Date:{" "}
                <span>
                  {data?.endDate
                    ? moment(data?.endDate)?.format("MMM YYYY")
                    : "----"}
                </span>
              </p>
            </Col>
          </Row>
        </div>
        <Divider className="horizontalDivider" />
        <div>
          <Row gutter={16} className="itemRow">
            <Col span={8}>
              <p className="item">Employer</p>
              <p className="value">{data?.employerName}</p>
            </Col>
            <Col span={8}>
              <p className="item">Project Name</p>
              <p className="value">{data?.projectName}</p>
            </Col>
            <Col span={8}>
              <p className="item">Industry</p>
              <p className="value">{data?.industry?.name}</p>
            </Col>
          </Row>
          <Row gutter={16} className="itemRow">
            <Col span={8}>
              <p className="item">Discipline</p>
              <p className="value">{data?.discipline?.name}</p>
            </Col>
            <Col span={8}>
              <p className="item">Inductions</p>
              <p className="value">
                {data?.inductions?.map((item, index) => (
                  <span key={index}>
                    {index === 0 ? (
                      item?.name
                    ) : (
                      <>
                        {`, `}
                        <br />
                        {item?.name}
                      </>
                    )}
                  </span>
                ))}
              </p>
            </Col>
            <Col span={8}>
              <p className="item">Skills</p>
              <p className="value">
                {data?.skills?.map((item, index) => (
                  <span key={index}>
                    {index === 0 ? (
                      item?.name
                    ) : (
                      <>
                        {`, `}
                        <br />
                        {item?.name}
                      </>
                    )}
                  </span>
                ))}
              </p>
            </Col>
          </Row>
        </div>
        {data?.hasReferee && (
          <div>
            <Divider className="horizontalDivider" />
            <div className="collapseContainer">
              <Collapse
                // size="small"
                expandIconPosition={"end"}
                bordered={false}
                items={[
                  {
                    key: "1",
                    label: "Referee Details ",
                    children: (
                      <Row gutter={16} className="itemRow">
                        <Col span={8}>
                          <p className="item">Referee Name</p>
                          <p className="value">{data?.refereeName}</p>
                        </Col>
                        <Col span={8}>
                          <p className="item">Referee Position</p>
                          <p className="value">{data?.refereePosition}</p>
                        </Col>
                        <Col span={8}>
                          <p className="item">Referee Email/Phone No.</p>
                          <p className="value">{data?.refereeContact}</p>
                        </Col>
                      </Row>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        centered
        footer={false}
        open={expEdit}
        title={"Professional Experience Edit"}
        className="resumeModal"
        onCancel={() => setExpEdit(false)}
      >
        <Form form={expForm} layout="vertical" onFinish={handleUpdate}>
          <Spin spinning={loading} />
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="Employer*" name="employerName">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Project Name*" name="projectName">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="Job Title*" name="jobTitle">
                <Select className="" placeholder="industry">
                  {jobTitle?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Industry*" name="industry">
                <Select className="" placeholder="industry">
                  {industry?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="Discipline*" name="discipline">
                <Select className="" placeholder="discipline">
                  {discipline?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Inductions*" name="induction">
                <Select
                  className=""
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="induction"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {induction?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item
                label="Job Description, Responsibilties, Skills*"
                name="skills"
              >
                <Select
                  className=""
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="skills"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {skills?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Start Date*" name="startDate">
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select start date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="End Date*" name="endDate">
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select end date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item name="hasReferee">
                <Checkbox
                  defaultChecked={data.hasReferee}
                  onChange={() => setShowReferee(!showReferee)}
                >
                  Referee Details
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item name="currentRole">
                <span style={{ marginRight: "10px" }}>Current Role</span>
                <Switch
                  defaultChecked={data.currentRole}
                // onClick={(e) => setCurrentStatus(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          {showReferee ? (
            <>
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item label="Referee Name" name="refereeName">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Referee Position" name="refereePosition">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item
                    label="Referee Email/ Phone Number"
                    name="refereeContact"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>{" "}
            </>
          ) : (
            ""
          )}

          <Row>
            <Col xs={24} className="buttonCol">
              <Button className="btn1" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default JobPositionCard;
