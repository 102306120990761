import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Modal,
  Checkbox,
  Upload,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import DocumentBox from "../../../components/common/DocumentBox";
import { EditFilled, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import API_MANAGER from "../../../API";
import axios from "axios";
import { s3PreUrl } from "../../../constants";

function QualificationEdit({
  data,
  qualification,
  qualificationEdit,
  handleQualificationEdit,
  licenceType,
  profileId,
  stateTags
}) {
  const [qualificationFrom] = useForm();
  const [showSOA, setShowSOA] = useState(data?.hasSOA);
  const [showVOC, setShowVOC] = useState(data?.hasVOC);
  const [uploadDocument, setUploadDocument] = useState(false);
  const [uploadVOCDocument, setUploadVOCDocument] = useState(false);
  const [uploadSOADocument, setUploadSOADocument] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileListSOA, setFileListSOA] = useState([]);
  const [fileListVOC, setFileListVOC] = useState([]);

  const onFinish = async (values) => {
    try {
      //Upload Doc

      const dataQualification = {
        extension: fileList?.length > 0 ? fileList[0]?.type.split("/")[1] : "",
        type: "DOCUMENT",
        name: fileList?.length > 0 ? fileList[0].originFileObj.name : "",
        contentType: fileList?.length > 0 ? fileList[0]?.type : "",
      };
      const responseQualification =
        await API_MANAGER.fileUpload(dataQualification);
      await axios.put(
        responseQualification?.data?.data?.url,
        fileList[0]?.originFileObj
      );
      values.uploadedDocument =
        fileList?.length > 0
          ? s3PreUrl + responseQualification.data?.data?.key
          : data?.uploadedDocument;
      //SOA Doc
      const dataSOA = {
        extension:
          fileListSOA?.length > 0 ? fileListSOA[0]?.type.split("/")[1] : "",
        type: "DOCUMENT",
        name: fileListSOA?.length > 0 ? fileListSOA[0].originFileObj.name : "",
        contentType: fileListSOA?.length > 0 ? fileListSOA[0]?.type : "",
      };
      const responseSOA = await API_MANAGER.fileUpload(dataSOA);
      await axios.put(
        responseSOA?.data?.data?.url,
        fileListSOA[0]?.originFileObj
      );
      values.SOADocument =
        fileListSOA?.length > 0
          ? s3PreUrl + responseSOA.data?.data?.key
          : data?.SOADocument;
      //VOC Doc
      const dataVOC = {
        extension:
          fileListVOC?.length > 0 ? fileListVOC[0]?.type.split("/")[1] : "",
        type: "DOCUMENT",
        name: fileListVOC?.length > 0 ? fileListVOC[0].originFileObj.name : "",
        contentType: fileListVOC?.length > 0 ? fileListVOC[0]?.type : "",
      };
      const responseVOC = await API_MANAGER.fileUpload(dataVOC);
      await axios.put(
        responseVOC?.data?.data?.url,
        fileListVOC[0]?.originFileObj
      );
      values.VOCDocument =
        fileListVOC?.length > 0
          ? s3PreUrl + responseVOC.data?.data?.key
          : data?.VOCDocument;
      values.profileId = profileId;
      await API_MANAGER.editCandidateQualification(values, data._id);
      message.success("Successfully updated qualification");
      handleQualificationEdit();
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
  };
  const setFields = () => {
    qualificationFrom.setFieldsValue({
      qualificationType: data?.qualificationType?._id,
      licenceType: data?.licenceType?.map((item) => item.id),
      memberId: data?.memberId,
      stateOrCountry: data?.stateOrCountry,
      VOCIssueDate: data?.VOCIssueDate ? dayjs(data?.VOCIssueDate) : null,
      SOAIssueDate: data?.SOAIssueDate ? dayjs(data?.SOAIssueDate) : null,
      issueDate: data.issueDate ? dayjs(data?.issueDate) : null,
      expiryDate: data.expiryDate ? dayjs(data?.expiryDate) : null,
      hasVOC: data.hasVOC,
      hasSOA: data.hasSOA,
    });
  };
  const props = {
    name: "file",
    accept: "image/jpg,image/jpeg,.pdf",
    multiple: false,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const handleDocument = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const handleSOADocument = ({ fileList: newFileList }) => {
    setFileListSOA(newFileList);
  };
  const handleVOCDocument = ({ fileList: newFileList }) => {
    setFileListVOC(newFileList);
  };
  useEffect(() => {
    setFields();
  }, []);
  return (
    <>
      <Modal
        centered
        footer={false}
        open={qualificationEdit}
        title={"Qualification Edit"}
        className="resumeModal"
        onCancel={() => handleQualificationEdit()}
      >
        <Form form={qualificationFrom} layout="vertical" onFinish={onFinish}>
          <Row className="containner">
            <Col xs={18}>
              <Form.Item
                label="Qualification/Certification/Ticket/Licence*"
                name="qualificationType"
              >
                <Select
                  className=""
                  placeholder="Qualification"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {qualification?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e?.id}>
                        {e?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="containner">
            <Col xs={18}>
              {uploadDocument ? (
                <Upload {...props} onChange={handleDocument}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              ) : (
                <DocumentBox
                  date={data?.issueDate}
                  url={data?.uploadedDocument}
                />
              )}
            </Col>
            <Col className="editResume" xs={6}>
              <EditFilled onClick={() => setUploadDocument(true)} />
            </Col>
          </Row>
          <Row className="containner" gutter={24}>
            <Col xs={12}>
              <Form.Item label="Member No./ID*" name="memberId">
                <Input placeholder="Member no./id" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Licence Type/Class/Category*"
                name="licenceType"
              >
                <Select
                  className=""
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="Licence Type/Class/Category"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {licenceType?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="State/Country*" name="stateOrCountry">
                <Select
                  className=""
                  maxTagCount="responsive"
                  placeholder="State/Country"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                > {console.log(stateTags,"----a")}
                  {stateTags?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.name}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Issue Date*" name="issueDate">
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select issue date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="Expiry Date*" name="expiryDate">
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select expiry date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item name="hasSOA">
                <Checkbox
                  defaultChecked={showSOA}
                  onChange={() => setShowSOA(!showSOA)}
                >
                  Statement of Attainment
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {showSOA && (
            <Row className="containner">
              <Col xs={18}>
                {uploadSOADocument ? (
                  <Upload {...props} onChange={handleSOADocument}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                ) : (
                  <DocumentBox
                    date={data?.SOAIssueDate}
                    url={data?.uploadedDocument}
                  />
                )}
              </Col>
              <Col className="editResume" xs={6}>
                <EditFilled onClick={() => setUploadSOADocument(true)} />
              </Col>
              <Col xs={12}>
                <Form.Item label="Issue Date*" name="SOAIssueDate">
                  <DatePicker
                    className="w-100"
                    format={"DD-MM-YYYY"}
                    placeholder="Select issue date"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Form.Item name="hasVOC">
                <Checkbox
                  defaultChecked={showVOC}
                  onChange={() => setShowVOC(!showVOC)}
                >
                  Verification of Competency
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {showVOC && (
            <Row className="containner">
              <Col xs={18}>
                {uploadVOCDocument ? (
                  <Upload {...props} onChange={handleVOCDocument}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                ) : (
                  <DocumentBox
                    date={data?.issueDate}
                    url={data?.uploadedDocument}
                  />
                )}
              </Col>
              <Col className="editResume" xs={6}>
                <EditFilled onClick={() => setUploadVOCDocument(true)} />
              </Col>
              <Col xs={12}>
                <Form.Item label="Issue Date*" name="VOCIssueDate">
                  <DatePicker
                    className="w-100"
                    format={"DD-MM-YYYY"}
                    placeholder="Select issue date"
                    // style={{ height: "38px" }}
                    // onChange={(e, date) => {setStartDate(e,date)}}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} className="buttonCol">
              <Button className="btn1" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
export default QualificationEdit;
