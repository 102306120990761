import React from "react";
export const PcView = ({ color }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M2.24832 2.25C2.04942 2.25002 1.85866 2.32904 1.71801 2.46969C1.57737 2.61034 1.49834 2.80109 1.49832 3V8.87402C1.48493 8.95502 1.48493 9.03766 1.49832 9.11865V15C1.49834 15.1989 1.57737 15.3897 1.71801 15.5303C1.85866 15.671 2.04942 15.75 2.24832 15.75H15.7483C15.9472 15.75 16.138 15.671 16.2786 15.5303C16.4193 15.3897 16.4983 15.1989 16.4983 15V9.12598C16.5117 9.04498 16.5117 8.96234 16.4983 8.88135V3C16.4983 2.80109 16.4193 2.61034 16.2786 2.46969C16.138 2.32904 15.9472 2.25002 15.7483 2.25H2.24832ZM2.99832 3.75H14.9983V8.25H2.99832V3.75ZM2.99832 9.75H14.9983V14.25H2.99832V9.75Z"
        fill={`${color}`}
      />
    </svg>
  );
  export const PhoneView = ({ color }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14.25 15.375V2.625C14.25 1.59 13.41 0.75 12.375 0.75H5.625C4.59 0.75 3.75 1.59 3.75 2.625V15.375C3.75 16.41 4.59 17.25 5.625 17.25H12.375C13.41 17.25 14.25 16.41 14.25 15.375ZM12.75 15.75H5.25V2.25H6.46875L7.01175 3.33525C7.1385 3.5895 7.398 3.75 7.68225 3.75H10.2863C10.5705 3.75 10.83 3.5895 10.9567 3.33525L11.4998 2.25H12.75V15.75Z"
        fill={`${color}`}
      />
    </svg>
  );
  