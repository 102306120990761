import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Row, Col, Input, Table } from "antd";
import { useNavigate } from "react-router-dom";
import GreenTick from "../../Assets/campaign/greenTick.svg";
import RedTracker from "../../Assets/client/RedTracker.svg";
function Picklist() {
  const [search, setSearch] = useState("");
  const [dataSourceList, setDataSourceList] = useState([]);
  const navigate = useNavigate();
  let dataSource = [
    { title: "DISCIPLINE", value: "DISCIPLINE", app: "yes", web: "yes" },
    {
      title: "PERSONAL DETAILS - ETHNICITY",
      value: "ETHNICITY",
      app: "yes",
      web: "yes",
    },
    {
      title: "PERSONAL DETAILS - GENDER",
      value: "GENDER",
      app: "yes",
      web: "yes",
    },
    { title: "STATE", value: "STATE", app: "yes", web: "yes" },
    { title: "COUNTRY", value: "COUNTRY", app: "yes", web: "yes" },
    {
      title: "QUALIFICATION NAME",
      value: "QUALIFICATION",
      app: "yes",
      web: "yes",
    },
    {
      title: "QUALIFICATION - LICENCE TYPE",
      value: "LICENCETYPE",
      app: "yes",
      web: "yes",
    },
    { title: "VOC TYPE", value: "VOC", app: "yes", web: "no" },
    { title: "ADVERT - JOB TITLE", value: "JOBTITLE", app: "no", web: "yes" },
    {
      title: "DRIVER LICENCE - CLASS",
      value: "LICENCE_CLASS",
      app: "yes",
      web: "yes",
    },
    {
      title: "DRIVER LICENCE - CONDITION",
      value: "LICENCE_CONDITION",
      app: "yes",
      web: "yes",
    },
    {
      title: "MEDICAL LIST",
      value: "MEDICAL_ASSESSMENT",
      app: "yes",
      web: "no",
    },
    { title: "VISA TYPE NO.", value: "VISA", app: "yes", web: "yes" },
    { title: "ID DOCUEMENT TYPE", value: "ID_TYPE", app: "yes", web: "yes" },
    { title: "INDUCTION LIST", value: "INDUCTION", app: "yes", web: "yes" },
    { title: "INDUSTRY", value: "INDUSTRY", app: "yes", web: "yes" },
    { title: "SUPER FUND NAME", value: "FUND_NAME", app: "yes", web: "no" },
    {
      title: "SUPER FUND USI/ABN",
      value: "FUND_USI_ABN",
      app: "yes",
      web: "no",
    },
    {
      title: "POLICE CL. CERTIFYING AUTHORITY",
      value: "CERTIFYING_AUTHORITY",
      app: "yes",
      web: "no",
    },
    // {
    //   title: "MEDICAL ASSESSMENT",
    //   value: "MEDICAL_ASSESSMENT",
    //   app: "yes",
    //   web: "no",
    // },
    { title: "SKILL LIST", value: "SKILL", app: "yes", web: "yes" },
    { title: "ADVERT - ROSTER TYPE", value: "ROSTER", app: "no", web: "yes" },
    {
      title: "ADVERT - EMPLOYMENT TYPE",
      value: "EMPLOYMENT_TYPE",
      app: "no",
      web: "yes",
    },
    {
      title: "ADVERT - JOB DURATION",
      value: "JOB_DURATION",
      app: "no",
      web: "yes",
    },
    {
      title: "CONTACT US SUBJECT LIST",
      value: "SUBJECT",
      app: "yes",
      web: "yes",
    },
    {
      title: "HIDE ACCOUNT LIST",
      value: "HIDE_ACCOUNT",
      app: "yes",
      web: "no",
    },
    // {
    //   title: "DELETE ACCOUNT LIST",
    //   value: "DELETE_ACCOUNT",
    //   app: "yes",
    //   web: "no",
    // },
  ];
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (item, row, index) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() =>
              navigate(`/picklist/picklist-detail/view`, {
                state: { title: row?.value, heading: row?.title },
              })
            }
          >
            {10 * (1 - 1) + index + 1}
          </span>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (text, row) => (
        <span
          className="cursor-pointer color-primary"
          onClick={() =>
            navigate(`/picklist/picklist-detail/view`, {
              state: { title: row?.value, heading: row?.title },
            })
          }
        >
          {row.title}
        </span>
      ),
    },
    {
      title: "App",
      dataIndex: "app",
      render: (text, row) => {
        return (
          <span
            className="cursor-pointer color-primary"
            onClick={() =>
              navigate(`/picklist/picklist-detail/view`, {
                state: { title: row?.value, heading: row?.title },
              })
            }
          >
            {text === "yes" ? (
              <img style={{ width: "15px" }} src={GreenTick} />
            ) : (
              <img src={RedTracker} />
            )}
          </span>
        );
      },
    },
    {
      title: "Web",
      dataIndex: "web",
      render: (text, row) => {
        return (
          <span
            className="cursor-pointer color-primary"
            onClick={() =>
              navigate(`/picklist/picklist-detail/view`, {
                state: { title: row?.title },
              })
            }
          >
            {text === "yes" ? (
              <img style={{ width: "15px" }} src={GreenTick} />
            ) : (
              <img src={RedTracker} />
            )}
          </span>
        );
      },
    },
  ];
  useEffect(() => {
    dataSource = dataSource.sort(function (a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
    if (search) {
      const temp = dataSource.filter((e) =>
        e?.title?.toLowerCase().includes(search?.toLowerCase())
      );
      setDataSourceList(temp);
    } else {
      setDataSourceList(dataSource);
    }
  }, [search]);
  return (
    <SubLayout page="Picklist">
      <Row className="search_container">
        <Col>
          <Input.Search
            placeholder="Search"
            value={search}
            allowClear
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col xs={24} className="table_container">
          <Table
            columns={columns}
            dataSource={dataSourceList}
            pagination={false}
            className="w-100 table"
            rowKey={"id"}
            scroll={{
              y: "calc(100vh - 300px)",
            }}
            // loading={loading}
            // onChange={(e) => setPage(e?.current)}
          />
        </Col>
      </Row>
    </SubLayout>
  );
}
export default Picklist;
