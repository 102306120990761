export const getFileNameFromUrl = (url) => {
  const splittedUrl = url?.split("/");
  if (splittedUrl.length >= 2) {
    return splittedUrl[splittedUrl.length - 2]?.split("_%").join(" ");
  } else {
    return splittedUrl.pop();
  }
};
export const getFileSizeFromUrl = async (url) => {
  try {
    const resp = await fetch(url);
    const docBody = await resp.blob();
    return `${(docBody?.size / (1024 * 1024)).toFixed(2)}`;
  } catch (e) {
    return "0";
  }
};

export const numberWithCommas = (number) => {
  var parts = number?.toString()?.split(".");
  if (parts?.length > 0) {
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Ensure there are two digits after the decimal point
    if (parts[1]?.length > 2) {
      parts[1] = parts[1]?.substring(0, 2);
    }

    return parts?.join(".");
  }
  return number;
};
export const s3PreUrl =
  "https://dev-documents-cinchy.s3.ap-southeast-2.amazonaws.com/";
