import React, { useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Modal,
  Checkbox,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import API_MANAGER from "../../../API";
import axios from "axios";
import { s3PreUrl } from "../../../constants";

function AddQualification({
  setAddQualification,
  addQualification,
  qualification,
  licenceType,
  profileId,
  stateTags
}) {
  const [qualificationFrom] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [showSOA, setShowSOA] = useState(false);
  const [showVOC, setShowVOC] = useState(false);
  const [fileListSOA, setFileListSOA] = useState([]);
  const [fileListVOC, setFileListVOC] = useState([]);

  const handleDocument = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    fileList;
    fileListSOA;
    fileListVOC;
  };
  const handleSOADocument = ({ fileList: newFileList }) => {
    setFileListSOA(newFileList);
  };
  const handleVOCDocument = ({ fileList: newFileList }) => {
    setFileListVOC(newFileList);
  };
  const props = {
    name: "file",
    accept: "image/jpg,image/jpeg,.pdf",
    multiple: false,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const onFinish = async (values) => {
    try{
            //Upload Doc
            const dataQualification = {
              extension: fileList?.length > 0 ? fileList[0]?.type.split("/")[1] : "",
              type: "DOCUMENT",
              name: fileList?.length > 0 ? fileList[0].originFileObj.name : '',
              contentType: fileList?.length > 0 ? fileList[0]?.type : "",
            };
            const responseQualification = await API_MANAGER.fileUpload(dataQualification);
            await axios.put(
              responseQualification?.data?.data?.url,
              fileList[0]?.originFileObj
            );
            values.uploadedDocument = fileList?.length > 0 ? s3PreUrl + responseQualification.data?.data?.key : '';
            //SOA Doc
            const dataSOA = {
              extension: fileListSOA?.length > 0 ? fileListSOA[0]?.type.split("/")[1] : "",
              type: "DOCUMENT",
              name: fileListSOA?.length > 0 ? fileListSOA[0].originFileObj.name : '',
              contentType: fileListSOA?.length > 0 ? fileListSOA[0]?.type : "",
            };
            const responseSOA = await API_MANAGER.fileUpload(dataSOA);
            await axios.put(
              responseSOA?.data?.data?.url,
              fileListSOA[0]?.originFileObj
            );
            values.SOADocument = fileListSOA?.length > 0 ? s3PreUrl + responseSOA.data?.data?.key : '';
            //VOC Doc
            const dataVOC = {
              extension: fileListVOC?.length > 0 ? fileListVOC[0]?.type.split("/")[1] : "",
              type: "DOCUMENT",
              name: fileListVOC?.length > 0 ? fileListVOC[0].originFileObj.name : '',
              contentType: fileListVOC?.length > 0 ? fileListVOC[0]?.type : "",
            };
            const responseVOC = await API_MANAGER.fileUpload(dataVOC);
            await axios.put(
              responseVOC?.data?.data?.url,
              fileListVOC[0]?.originFileObj
            );
            values.hasSOA=showSOA;
            values.hasVOC=showVOC;
            values.VOCDocument = fileListVOC?.length > 0 ? s3PreUrl + responseVOC.data?.data?.key : '';
            values.profileId = profileId;
        await API_MANAGER.createCandidateQualification(values);
        message.success('Successfully added qualification');
        qualificationFrom.resetFields();
        setAddQualification(false);
    } catch (error) {
        message.error(error.response.data.message.message)
    }
  };
  return (
    <>
      <Modal
        centered
        footer={false}
        open={addQualification}
        title={"Qualification Add"}
        className="resumeModal"
        onCancel={() => setAddQualification(false)}
      >
        <Form form={qualificationFrom} layout="vertical" onFinish={onFinish}>
          <Row className="containner">
            <Col xs={18}>
              <Form.Item
                label="Qualification/Certification/Ticket/Licence*"
                name="qualificationType"
              >
                <Select className="" placeholder="Qualification">
                  {qualification?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e?.id}>
                        {e?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="containner">
            <Col xs={18}>
              <Upload {...props} onChange={handleDocument}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Col>
          </Row>
          <Row className="containner" gutter={24}>
            <Col xs={12}>
              <Form.Item label="Member No./ID*" name="memberId">
                <Input placeholder="Member no./id" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Licence Type/Class/Category*"
                name="licenceType"
              >
                <Select
                  className=""
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="Licence Type/Class/Category"
                >
                  {licenceType?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="State/Country*" name="stateOrCountry">
                <Select
                  className=""
                  maxTagCount="responsive"
                  placeholder="State/Country"
                >
                  {stateTags?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.name}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Issue Date*" name="issueDate">
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select issue date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="Expiry Date*" name="expiryDate">
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select expiry date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item name="hasSOA">
                <Checkbox
                  checked={showSOA}
                  onChange={() => setShowSOA(!showSOA)}
                >
                  Statement of Attainment
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {showSOA && (
            <Row className="containner">
              <Col xs={24}>
                <Upload {...props} onChange={handleSOADocument}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Col>
              <Col xs={12}>
                <Form.Item label="Issue Date*" name="SOAIssueDate">
                  <DatePicker
                    className="w-100"
                    format={"DD-MM-YYYY"}
                    placeholder="Select issue date"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Form.Item name="hasVOC">
                <Checkbox
                  checked={showVOC}
                  onChange={() => setShowVOC(!showVOC)}
                >
                  Verification of Competency
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {showVOC && (
            <Row className="containner">
              <Col xs={24}>
                <Upload {...props} onChange={handleVOCDocument}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Col>
              <Col xs={12}>
                <Form.Item label="Issue Date*" name="VOCIssueDate">
                  <DatePicker
                    className="w-100"
                    format={"DD-MM-YYYY"}
                    placeholder="Select issue date"
                    // style={{ height: "38px" }}
                    // onChange={(e, date) => {setStartDate(e,date)}}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} className="buttonCol">
              <Button className="btn1" htmlType="submit">
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
export default AddQualification;
