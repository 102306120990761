import HELPERS from "../utils/helper";
// const baseURL = `https://staging-api.cinchy.me/api/v1/`;
const baseURL = `https://prod-api.cinchy.me/api/v1/`;
// const baseURL = `https://dev-api.cinchy.me/api/v1/`;
const API_MANAGER = {
  login: (data) => {
    return HELPERS.request({
      baseURL,
      url: "admin-auth/login",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  lastLoginOtp: (token) => {
    return HELPERS.request({
      baseURL,
      url: "user/last-login/otp",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  verifyDocument: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/details/validate/document`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifyMedicalDocument: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/details/validate/medical`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifySoaVocDocument: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/qualification/validate/additional-doc/${id}`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifyQualificationDocument: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/qualification/validate/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifyPoliceDocument: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/details/validate/pollice-clearance/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  forgotPassword: (data) => {
    return HELPERS.request({
      baseURL,
      url: "auth/forgot-password",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifyOtp: (data) => {
    return HELPERS.request({
      baseURL,
      url: "auth/verify-otp",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  verifyLoginOtp: (data, token) => {
    return HELPERS.request({
      baseURL,
      url: "user/verify/last-login/otp",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  createNewPassword: (data, token) => {
    return HELPERS.request({
      baseURL,
      url: "user/change-password",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // "Access-Control-Allow-Credentials": true,
      },
    });
  },
  resendMail: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "auth/resend-verification-link",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  setPassword: (data, token) => {
    return HELPERS.request({
      baseURL,
      url: "auth/verify-user",
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getRTCDashboard: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: "admin/dashboard",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getOtherDashboard: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "admin/dashboard/other-details",
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getReportDashboard: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "admin/dashboard-reports",
      method: "GET",
      params: data,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/octet-stream",
        // "Content-Disposition": "attached; filename=my-workbook.xls",
      },
    });
  },
  getCandidateList: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "candidate/candidates-list?limit=10",
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCandidateListNoProfile: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "user/candidate-noprofile",
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCandidateListNoProfileExport: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "user/candidate-noprofile-export",
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "arraybuffer",
    });
  },
  createCandidateExperience: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "/experience",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createCandidateQualification: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/qualification/admin-create`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createCandidateDocument: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/details/document`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  editCandidatePersonalDetails: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "/candidate",
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  editCandidateResume: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/resume/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  uploadCandidateResume: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/resume/upload/admin`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  editCandidateExperience: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/experience/admin-update/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  editCandidateQualification: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/qualification/admin-update/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  editCandidateDocument: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/details/document/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  editCandidateAdditionalDetails: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/details`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteCandidate: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/admin/admin-delete-candidate?userId=${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getRecruiterList: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "recruiter/profile-list?limit=10",
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getRecruiterDetail: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `admin/admin-get-recruiter`,
      params: data,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createRecruiter: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "admin/admin-create-recruiter",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  editRecruiter: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "admin/admin-update-recruiter",
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteRecruiter: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `admin/admin-delete-recruiter?userId=${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getNewsFeed: (search, page) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `newsfeed/admin?limit=10&page=${page}&search=${search ? `${search}` : ""
        }`,
      method: "GET",
      // params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getNewsFeedDetail: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `newsfeed/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createNews: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "newsfeed",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  EditNews: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `newsfeed/${data.id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteNewsFeed: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `newsfeed/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCampaignList: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "campaign?limit=10",
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteCampaign: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  fileUpload: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "file/upload-url",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  uploadFileOnAws: (url, data) => {
    return HELPERS.request({
      baseURL: url,
      // url: url,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getContacts: (params) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `contact?limit=10`,
      method: "GET",
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getContactById: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `contact/${id}`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCandidateDetail: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAppliedJobs: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: `application/candidate`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteUser: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/delete-user/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  hideUser: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getUserList: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "admin?limit=10",
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getUserDetail: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `admin/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createUser: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "admin/create-admin",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  editUserAdmin: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `admin/update-admin/${data.id}`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  toggleUser: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteUserAdmin: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `admin/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getCampaignDetail: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `campaign/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  toggleCampaign: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `advert/campaign/status/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  toggleAdvert: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `advert/status/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAdvertList: (id, data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `advert/campaignAdverts/${id}?limit=10`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAdvertDetail: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `advert/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteAdvert: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `advert/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  bulkUpload: (data) => {
    const form_data = HELPERS.converToFormData(data);
    return HELPERS.secureRequest({
      baseURL,
      url: "admin/bulk-upload",
      method: "POST",
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  createSubscription: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "subscription-product/create",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  editSubscription: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `subscription-product/${data?._id}`,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getSubscriptions: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: "subscription-product/",
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  subscriptionToggle: (data, id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `subscription-product/edit-status/${id}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteSubscription: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `subscription-product/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getPickListTags: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `tags`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createPickListTag: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `tags`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getPickListSingleTag: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `tags/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updatePickListTag: (data, title) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `tags`,
      method: "PATCH",
      params: { type: title },
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deletePicklist: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `tags/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updatePassword: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `user/update-password`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAboutUs: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: `about/`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createAboutUs: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `about/`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getPrivacyPolicy: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: `policy/`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createPrivacyPolicy: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `policy/`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getTermsandCondition: () => {
    return HELPERS.secureRequest({
      baseURL,
      url: `terms-conditions/`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  createTermsandCondition: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `terms-conditions/`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateSupport: (id) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `contact/contact/${id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  sendNotification: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `notification`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  sendBulkNotification: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/notification/bulk-notification`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getManualNotification: (page) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/notification/bulk-notification?limit=10&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  getAllSubscriptions: (data) => {
    return HELPERS.secureRequest({
      baseURL,
      url: `/subscription/all`,
      method: "GET",
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default API_MANAGER;
