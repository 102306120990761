import React, { useState } from "react";
import { Row, Col, Card, Button, Modal, message } from "antd";
import GreenCheck from "../../../Assets/support/greenCheckIcon.png";
import API_MANAGER from "../../../API";
import { useNavigate } from "react-router-dom";
function SupportCard({ item, getData, page, filter, profile }) {
  const [replybtn, setReplyBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Id, setId] = useState("");
  const navigate = useNavigate();
  const handleReply = async () => {
    setLoading(true);
    try {
      await API_MANAGER.updateSupport(Id);
      getData();
    } catch {
      message.error("Something Went Wrong");
    }
    setReplyBtn(false);
    setLoading(false);
  };

  let contactNo = (item) => {
    return item?.contactedBy === "CANDIDATE"
      ? "+" + item?.candidate?.countryCode + "-" + item?.candidate?.phoneNumber
      : item?.userId?.countryCode + "-" + item?.userId?.phoneNumber;
  };
  return (
    <div className="support_card">
      <Card className="supportCardContainer">
        <Row>
          <Col
            className="cursor-pointer"
            onClick={() =>
              navigate("/support/support-detail", {
                state: {
                  item,
                  page: page,
                  filter: filter,
                  profile: profile,
                  path: "/support",
                },
              })
            }
            style={{ padding: "24px" }}
            xs={21}
          >
            {/* <Row align={"middle"} justify={"space-between"} className="mb-8">
              <Col
                onClick={() =>
                  navigate("/support/support-detail", { state: item })
                }
              >
                <Row
                  onClick={() =>
                    navigate("/support/support-detail", { state: item })
                  }
                  gutter={16}
                  align={"middle"}
                  className="mb-8"
                >
                  <Col className="heading">Name: </Col>
                  <Col className="value_item">{item?.name}</Col>
                </Row>
                <Row gutter={16} align={"middle"}>
                  <Col className="heading">Email: </Col>
                  <Col className="value_item">
                    {item.contactedBy === "CANDIDATE"
                      ? item?.userId?.email
                      : item.email}
                  </Col>
                </Row>
              </Col>
            </Row> */}
            <Row gutter={16} align={"middle"} className="mb-8">
              <Col className="heading" span={4}>
                Name:{" "}
              </Col>
              <Col className="value_item">{item?.name}</Col>
            </Row>
            <Row gutter={16} align={"middle"} className="mb-8">
              <Col className="heading" span={4}>
                Email:{" "}
              </Col>
              <Col className="value_item">
                {item.contactedBy === "CANDIDATE"
                  ? item?.userId?.email
                  : item.email}
              </Col>
            </Row>
            <Row gutter={16} align={"middle"} className="mb-8">
              <Col className="heading" span={4}>
                Contact Number:{" "}
              </Col>
              <Col className="value_item">{contactNo(item)}</Col>
            </Row>
            <Row
              onClick={() =>
                navigate("/support/support-detail", { state: item })
              }
              gutter={16}
              align={"middle"}
              className="mb-8"
            >
              <Col span={4} className="heading">
                Subject:{" "}
              </Col>
              <Col className="value_item">{item?.subject?.name}</Col>
            </Row>
            <Row
              onClick={() =>
                navigate("/support/support-detail", { state: item })
              }
              gutter={16}
              align={"middle"}
              className="mb8"
            >
              <Col xs={4} className="heading">
                Message:{" "}
              </Col>
              <Col xs={18} className="value_item">
                {item?.summary?.length > 200
                  ? item?.summary.substring("0", "200") + "..."
                  : item?.summary}
              </Col>
            </Row>
          </Col>
          {!item?.isReported && (
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "24px",
              }}
              xs={2}
            >
              <Row>
                <Col
                  xs={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <span>
                    {item.repliedTo ? (
                      <img
                        style={{ height: "30px" }}
                        src={GreenCheck}
                        alt="greencheck"
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Col>
                <Col xs={16}>
                  <Button
                    className="primaryBtn"
                    loading={loading}
                    onClick={() => {
                      setReplyBtn(true);
                      setId(item._id);
                    }}
                  >
                    Reply
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Card>
      <Modal
        footer={false}
        centered
        open={replybtn}
        onCancel={() => setReplyBtn(false)}
        wrapClassName="supportModal"
      >
        <Row>
          <Col md={24}>
            <h1>Choose Options to Reply</h1>
          </Col>
          <Col md={24} className="buttonCol">
            <a
              href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${
                item.contactedBy === "CANDIDATE"
                  ? item?.userId?.email
                  : item.email
              }&su=${item?.subject?.name}
              &body=${item.summary}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                loading={loading}
                className="reply_btn"
                onClick={handleReply}
              >
                Gmail
              </Button>
            </a>
            <a
              href={`mailto:${
                item.contactedBy === "CANDIDATE"
                  ? item?.userId?.email
                  : item.email
              }?subject=${item?.subject?.name}
              &body=${item.summary}
              `}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                loading={loading}
                className="reply_btn"
                onClick={handleReply}
              >
                Outlook
              </Button>
            </a>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default SupportCard;
