import { Row, Col, Modal, Button, Radio } from "antd";
import React from "react";

export function DeleteCandidate({
  confirmDelete,
  visible,
  setVisible,
  loading,
  text,
}) {
  return (
    <Modal
      className="candidateDeleteModal"
      centered
      onCancel={() => {
        setVisible(false);
      }}
      open={visible}
      footer={null}
      title={`Delete ${text}?`}
    >
      <Row className="header">
        <Col>
          <p className="desc">
            Are you sure you want to delete selected {text}?
          </p>
        </Col>
      </Row>

      <Row align={"middle"} gutter={16} justify={"end"}>
        <Col>
          <Button
            className="confirmBtn"
            onClick={() => confirmDelete()}
            loading={loading}
          >
            Delete
          </Button>
        </Col>
        <Col>
          <Button
            className="cancelBtn"
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
export function UpdateApplicantProfileStatus({ visible, setVisible }) {
  return (
    <Modal
      className="updateStatusModal"
      centered
      onCancel={() => setVisible(false)}
      open={visible}
      footer={null}
      title={"Update Status For:"}
    >
      <Row className="header">
        <Col>
          <p className="desc">Robert Dave, Jonothan Wake and Jake Dave</p>
        </Col>
      </Row>
      <Row>
        <Radio.Group className="radioBtns">
          <p className="radioStage">Current Stage</p>
          <Radio value={"shortlisted"}>Shortlist Offered</Radio>
          <p className="radioStage">Next Stage</p>
          <Radio value={"offered"}>Role Offered</Radio>
        </Radio.Group>
      </Row>
      <Row align={"middle"} gutter={16} justify={"end"} className="btnBox">
        <Col>
          <Button className="cancelBtn" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Col>
        <Col>
          <Button className="confirmBtn">Update</Button>
        </Col>
      </Row>
    </Modal>
  );
}
