import React, { useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Modal,
  Upload,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import API_MANAGER from "../../../API";
import axios from "axios";
import { s3PreUrl } from "../../../constants";

function AddDocuments({
  addDocument,
  setAddDocument,
  classData,
  conditions,
  idType,
  citizen,
  userId,
  stateTags,
  visaTypeNo,
}) {
  const [docType, setDocType] = useState("Passport");
  const [fileList, setFileList] = useState([]);

  const handleUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const [otherDetailForm] = Form.useForm();
  const props = {
    name: "file",
    accept: "image/jpg,image/jpeg,.pdf",
    multiple: false,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const handleFinish = async (values) => {
    try {
      const dataDocument = {
        extension: fileList?.length > 0 ? fileList[0]?.type.split("/")[1] : "",
        type: "DOCUMENT",
        name: fileList?.length > 0 ? fileList[0].originFileObj.name : "",
        contentType: fileList?.length > 0 ? fileList[0]?.type : "",
      };
      const responseDocument = await API_MANAGER.fileUpload(dataDocument);
      await axios.put(
        responseDocument?.data?.data?.url,
        fileList[0]?.originFileObj
      );
      values.document =
        fileList?.length > 0 ? s3PreUrl + responseDocument.data?.data?.key : "";
      let documentType = idType.filter((e) => {
        if (e.name === docType) return e._id;
      });
      const objData = {
        userId: userId,
        document: values.document,
        documentData: {
          ...values,
        },
        documentType: documentType[0]._id,
      };
      values.documentData = { ...values };
      values.userId = userId;
      await API_MANAGER.createCandidateDocument(objData);
      message.success("Successfully added document");
      otherDetailForm.resetFields();
    } catch (error) {
      message.error(error.response.data.message.message);
    }
    setAddDocument(false);
  };
  return (
    <Modal
      centered
      footer={false}
      open={addDocument}
      title={"Add Document"}
      className="resumeModal"
      onCancel={() => setAddDocument(false)}
    >
      <Form form={otherDetailForm} layout="vertical" onFinish={handleFinish}>
        <Row>
          <Col xs={24}>
            <Row>
              <Col xs={12}>
                <Form.Item name="docType">
                  <Select
                    placeholder="Select Id"
                    defaultValue={"Passport"}
                    onChange={(e) => setDocType(e)}
                  >
                    <Select.Option value="Passport">Passport</Select.Option>
                    <Select.Option value="Driver Licence">
                      Driver Licence
                    </Select.Option>
                    <Select.Option value="Proof of Age/Photo Card">
                      Proof of Age/Photo Card
                    </Select.Option>
                    {citizen !== "australian" && (
                      <Select.Option value="VISA">Visa</Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={{ margin: "10px 0px 20px 0px" }}>
                <Upload {...props} onChange={(e) => handleUpload(e)}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                {docType === "VISA" && (
                  <Form.Item name="visaTypeNo" label="Visa Type No*">
                    <Select
                      placeholder="Visa Type No"
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {visaTypeNo?.map((e, key) => {
                        return (
                          <Select.Option key={key} value={e?._id}>
                            {e?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {docType === "Passport" && (
                  <Form.Item name="passportNumber" label="Passport Number*">
                    <Input placeholder="Passport number" />
                  </Form.Item>
                )}
                {docType === "Driver Licence" && (
                  <Form.Item name="licenseNumber" label="License Number*">
                    <Input placeholder="Licence number" />
                  </Form.Item>
                )}
                {docType === "Proof of Age/Photo Card" && (
                  <Form.Item name="idNumber" label="ID Number*">
                    <Input placeholder="Id number" />
                  </Form.Item>
                )}
              </Col>
              <Col xs={12}>
                {docType === "VISA" ? (
                  <Form.Item name="grantNo" label="Grant No*">
                    <Input placeholder="Grant No" />
                  </Form.Item>
                ) : docType === "Passport" ? (
                  <Form.Item name="nationality" label="Nationality*">
                    <Input placeholder="Nationality" />
                  </Form.Item>
                ) : docType === "Driver Licence" ? (
                  <Form.Item name="stateDL" label="State*">
                    <Select  showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                      {stateTags?.map((e, key) => {
                        return (
                          <Select.Option key={key} value={e?.name}>
                            {e?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item name="state" label="State*">
                    <Select  showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                      {stateTags?.map((e, key) => {
                        return (
                          <Select.Option key={key} value={e?.id}>
                            {e?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
            {docType === "Driver Licence" && (
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item name="class" label="Class">
                    <Select className="" placeholder="Class"  showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                      {classData?.map((e, key) => {
                        return (
                          <Select.Option key={key} value={e?.id}>
                            {e?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item name="conditions" label="Conditions">
                    <Select className="" placeholder="Conditions"  showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                      {conditions?.map((e, key) => {
                        return (
                          <Select.Option key={key} value={e?.id}>
                            {e?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={24}>
              {docType === "VISA" && (
                <Col xs={12}>
                  <Form.Item label="Issue Date*" name="issueDate">
                    <DatePicker
                      className="w-100"
                      format={"DD-MM-YYYY"}
                      placeholder="Select expiry date"
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={12}>
                <Form.Item label="Expiry*" name="expiry">
                  <DatePicker
                    className="w-100"
                    format={"DD-MM-YYYY"}
                    placeholder="Select expiry date"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="buttonCol">
            <Button className="btn1" htmlType="submit">
              Create
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default AddDocuments;
