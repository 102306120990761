import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Row, Col, Form, Button, message, Image } from "antd";
import API_MANAGER from "../../../API";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const OTP = ({
  email,
  setOtpVerified,
  setToken,
  isLogin,
  lastLoginOtp,
  token,
}) => {
  const [seconds, setSeconds] = useState(60);
  const [otp, setOtp] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [otpBtnDisabled, setOtpBtnDisabled] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleChange = (newValue) => {
    if (newValue.length == 6) {
      setOtpBtnDisabled(false);
    } else {
      setOtpBtnDisabled(true);
    }
    setOtp(newValue);
  };
  const resendOTP = async () => {
    if (isLogin) {
      lastLoginOtp(token);
    } else {
      try {
        setSeconds(60);
        await API_MANAGER.forgotPassword({ email: email });
        message.success("OTP sent to your mail.");
      } catch (err) {
        if (err?.response?.data?.message?.message) {
          message.warning(err?.response?.data?.message?.message);
        } else {
          message.warning("Something went wrong, please try again!");
        }
      }
    }
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    console.log(values);
    try {
      if (isLogin) {
        const response = await API_MANAGER.verifyLoginOtp(
          {
            otp: otp,
          },
          token
        );
        let expireTime = new Date().getTime() + 3 * 3600 * 1000;
        Cookies.set("access_token", token, {
          expires: expireTime,
        });
        localStorage.setItem("expireTime", expireTime);
        console.log(response);
        navigate("/dashboard");
      } else {
        const response = await API_MANAGER.verifyOtp({
          email: email,
          otp: otp,
        });
        console.log(response);
        setToken(response?.data?.data);
        setOtpVerified(true);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error("Please enter valid otp!");
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <div className="login_main_container">
      <div className="login_container">
        <Row>
          <Col span={12} className="login-form-container">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate("/login");
              }}
            >
              <Image src="/assets/login/banner.svg" preview={false} />
            </div>

            <div className="form-outer-div">
              <div className="form-inner-div">
                <p className="heading1 forgot-text">
                  {isLogin ? "Verify" : "Forgot Password"}
                </p>
                <p className="subHeading1">
                  Enter the 6-digit verification code to verify.
                </p>
                <Form form={form} onFinish={handleSubmit}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="otp">
                        <OtpInput
                          value={otp}
                          onChange={handleChange}
                          numInputs={6}
                          inputType={"tel"}
                          shouldAutoFocus={true}
                          isInputNum={true}
                          inputStyle="otpInput"
                          containerStyle="otpContainer"
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          className="submitBtn w-100"
                          htmlType="submit"
                          disabled={otpBtnDisabled}
                          loading={loading}
                        >
                          Verify
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <Row container justifyContent={"center"}>
                  <p className="resendText">
                    {seconds > 0 ? (
                      <span>
                        Resend the code in{" "}
                        {seconds < 10 ? `0${seconds} ` : seconds} seconds.
                      </span>
                    ) : (
                      <span>
                        Not received your code?{" "}
                        <span
                          onClick={resendOTP}
                          style={{
                            color: "#3F98EA",
                            cursor: "pointer",
                          }}
                        >
                          Resend Code
                        </span>
                      </span>
                    )}
                  </p>
                </Row>
              </div>
            </div>
          </Col>
          <Col span={12} className="banner-image">
            <Image src="/assets/login/login-banner.png" preview={false} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OTP;
