import React from "react";
import SubLayout from "../../components/layout/SubLayout";
import AdvertList from "../../components/common/campaign/AdvertList";
import { useLocation, useNavigate } from "react-router-dom";

function AdvertLists() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const backUrlFunction = () => {
    navigate("/campaign-list", {
      state: {
        page: state?.page,
        filter: state?.filter,
        search: state?.search,
        path: state?.path,
      },
    });
  };
  return (
    <SubLayout
      page={"Advert List"}
      showBack
      backUrlFunction={backUrlFunction}
      isBackFunc={true}
    >
      <AdvertList />
    </SubLayout>
  );
}
export default AdvertLists;
