import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Row, Col, Table, Input, message, Button, Switch, Tooltip } from "antd";
import { DeleteCandidate } from "../../components/common/Popup";
import API_MANAGER from "../../API";
import DeleteIcon from "../../Assets/delete.svg";
import EditIcon from "../../Assets/editIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import GreenDot from "../../Assets/greenStatus.svg";
import YellowDot from "../../Assets/yellowStatus.svg";
import MailIcon from "../../Assets/MailIcon.svg";
import CustomPagination from "../../components/common/CustomPagination";

function UserManagement() {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState();
  const { state } = useLocation();
  const navigate = useNavigate();

  const getData = async (search, page) => {
    setLoading(true);
    if(page){
      try {
        const response = await API_MANAGER.getUserList({
          sort: "-createdAt",
          page: page,
          search: search,
          role: 2,
        });
        setData(response?.data?.data);
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
    setLoading(false);
  };
  const toggleStatus = async (status, id) => {
    setLoading(true);
    try {
      await API_MANAGER.toggleUser({ isActive: status }, id);
      message.success(
        `Successfully ${status !== true ? "disabled" : "enabled"} user.`
      );
      getData(search, page);
    } catch {
      message.error("Something went wrong");
    }
    setLoading(false);
  };
  const userType = localStorage.getItem("userType");
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() =>
              navigate(`/user-management/user-detail/view/${row?._id}`)
            }
          >
            <img
              src={row?.userId?.isVerified ? GreenDot : YellowDot}
              alt="greendot"
            />{" "}
            {(page - 1) * 10 + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() =>
              navigate(`/user-management/user-detail/view/${row?._id}`)
            }
          >
            {row?.firstName + " " + row?.lastName}
          </span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() =>
              navigate(`/user-management/user-detail/view/${row?._id}`)
            }
          >
            {row?.userId?.email}
          </span>
        );
      },
    },
    {
      title: "Contact No.",
      dataIndex: "contactNo",
      key: "contactNo",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() =>
              navigate(`/user-management/user-detail/view/${row?._id}`)
            }
          >
            {row?.countryCode + "-" + row?.phoneNumber}
          </span>
        );
      },
    },
    {
      title: "Permissions",
      dataIndex: "standardAdmin",
      key: "standardAdmin",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() =>
              navigate(`/user-management/user-detail/view/${row?._id}`)
            }
          >
            {row?.userId?.standardAdmin === true ? "Standard User" : "Admin"}
          </span>
        );
      },
    },
    {
      title: (
        <span>
          Enable / <br /> Disable
        </span>
      ),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (_, row) => {
        return (
          <span onClick={(e)=> e.stopPropagation()}>
          <Switch
            defaultChecked={row?.userId?.isActive}
            onClick={(e) => {
              toggleStatus(e, row?.userId?._id);
            }}
          />
             </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      render: (text, row) => {
        return (
          <div onClick={(e)=> e.stopPropagation()}>
            <span>
              <img
                style={{ width: "35px" }}
                src={MailIcon}
                alt="mailIcon"
                className={"cursor-pointer"}
                onClick={() => {
                  userType === "admin"
                    ? handleResendMail(row?.userId?._id)
                    : "";
                }}
              />
            </span>
            <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
              <span>
                <img
                  style={{ width: "35px" }}
                  className="cursor-pointer"
                  src={EditIcon}
                  alt="edit"
                  onClick={() => {
                    userType === "admin"
                      ? navigate(
                          `/user-management/user-detail/edit/${row?._id}`
                        )
                      : "";
                  }}
                />
              </span>
              {userType === "admin" ? (
                <span>
                  <img
                    style={{ width: "35px" }}
                    src={DeleteIcon}
                    alt="delete"
                    className="cursor-pointer"
                    onClick={() => {
                      setDeleteId(row?._id);
                      setDeleteModal(true);
                    }}
                  />
                </span>
              ) : (
                <img
                  style={{ width: "35px" }}
                  src={DeleteIcon}
                  alt="delete"
                  className="cursor-pointer"
                />
              )}
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const handleResendMail = async (id) => {
    setLoading(true);
    try {
      await API_MANAGER.resendMail({
        id: id,
        userType: "admin",
      });
      message.success("Successfully sent mail");
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
    setLoading(false);
  };
  const deleteConfirm = async () => {
    setConfirmLoading(true);
    try {
      await API_MANAGER.deleteUserAdmin(deleteId);
      message.success("Successfully Deleted User");
      setDeleteModal(false);
      getData(search, 1);
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message || "Something went wrong!"
      );
    }
    setConfirmLoading(false);
  };
  useEffect(() => {
    if(page){
      getData(search, page);
    }
  }, [search, page]);
  useEffect(() => {
    if (state && state?.path === "/user-management") {
      setSearch(state.search);
      if (state?.page) {
        setPage(state.page);
      } else {
        setPage(1);
      }
      getData(search);
    } else {
      setPage(1);
    }
  }, [state]);
  return (
    <SubLayout page="User List">
      <Row className="search_container" gutter={32} align={"middle"}>
        <Col>
          <Input.Search
            placeholder="Search"
            value={search}
            allowClear
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </Col>
        <Col className="btn_Col">
          <Button
            className="bulk_button"
            onClick={() => navigate(`/user-management/user-detail/create`)}
          >
            Add User
          </Button>
        </Col>
      </Row>
      <Row className="table_container">
          <Table
            columns={columns}
            dataSource={data?.result}
            pagination={false}
            className="w-100"
            rowKey={"id"}
            loading={loading}
            scroll={{
              y: "calc(100vh - 355px)",
              // x:"calc(768px + 40%)"
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(
                    `/user-management/user-detail/view/${record?._id}`,
                    {
                      state: {
                        page: page,
                        search: search,
                        path: "/user-management",
                      },
                    }
                  );
                }, // click row
              };
            }}
          />
        <div className="w-100">
        <CustomPagination
          currentPage={page}
          setCurrentPage={setPage}
          total={data?.totalCount}
          itemPerPage={10}
        />
      </div>
      </Row>
      <DeleteCandidate
        confirmDelete={deleteConfirm}
        visible={openDeleteModal}
        text={"User"}
        setVisible={setDeleteModal}
        loading={confirmLoading}
      />
    </SubLayout>
  );
}
export default UserManagement;
