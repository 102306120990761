import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import CandidateDetail from "../../components/common/candidate/CandidateDetail";
import ValidationModal from "./ValidationModal";
import CandidateEdit from "./candidateEdit/CandidateEdit";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function CandidateDetails() {
  const [isValidate, setIsValidate] = useState(false);
  const [validateModal, setValidateModal] = useState(true);
  const { type } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    console.log(setIsValidate);
  }, []);
  const backUrlFunction = () => {
    navigate("/candidate-list", {
      state: {
        page: state?.page,
        filter: state?.filter,
        search: state?.search,
        path: state?.path,
      },
    });
  };
  return (
    <SubLayout
      page={type === "edit" ? "Edit candidate details" : "Candidate details"}
      showBack
      backUrlFunction={backUrlFunction}
      isBackFunc={true}
    >
      {!isValidate && (
        <>
          <ValidationModal
            validateModal={validateModal}
            setValidateModal={setValidateModal}
            setIsValidate={setIsValidate}
          />
          <CandidateDetail />
        </>
      )}
      {type === "edit"
        ? isValidate && <CandidateEdit />
        : isValidate && <CandidateDetail />}
    </SubLayout>
  );
}

export default CandidateDetails;
