import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Image, Checkbox } from "antd";
import MailIcon from "../../Assets/login/mail.svg";
import PasswordIcon from "../../Assets/login/password.svg";
import { useNavigate } from "react-router-dom";
import API_MANAGER from "../../API";
import Cookies from "js-cookie";
import LoaderGif from "../../Assets/loader.gif";
import Logo from "../../Assets/login/Logo.svg";
import OTP from "../../components/common/forgot/OTP";
function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [rememberMe, setRememberMe] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [token, setToken] = useState("");
  const lastLoginOtp = async (token) => {
    try {
      await API_MANAGER.lastLoginOtp(token);
      setIsOtp(true);
      setLoading(false);
    } catch (error) {
      message.error("Something went wrong!");
    }
  };
  const LoginUser = async (values) => {
    try {
      setLoading(true);
      const response = await API_MANAGER.login(values);
      console.log(response?.data?.data, "data");
      if (rememberMe) {
        localStorage.setItem("email", values?.email);
        localStorage.setItem("password", values?.password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      let expireTime = new Date().getTime() + 3 * 3600 * 1000;
      localStorage.setItem("email", response?.data?.data?.admin?.email);
      setToken(response?.data?.data?.accessToken);
      localStorage.setItem(
        "userType",
        response?.data?.data?.admin?.standardAdmin === true
          ? "standard"
          : "admin"
      );
      localStorage.setItem(
        "firstName",
        response?.data?.data?.admin?.adminProfile?.firstName
      );
      localStorage.setItem(
        "lastName",
        response?.data?.data?.admin?.adminProfile?.lastName
      );
      localStorage.setItem(
        "userId",
        response?.data?.data?.admin?.adminProfile?.id
      );

      if (response?.data?.data?.isOtp) {
        lastLoginOtp(response?.data?.data?.accessToken);
      } else {
        localStorage.setItem("expireTime", expireTime);
        Cookies.set("access_token", response?.data?.data?.accessToken, {
          expires: expireTime,
        });
        setLoading(false);

        navigate(`/dashboard`);
      }
    } catch (err) {
      if (err?.response?.data?.message?.message) {
        message.warning(err?.response?.data?.message?.message);
      } else {
        message.warning("Something went wrong, please try again!");
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    const Email = localStorage.getItem("email");
    const Password = localStorage.getItem("password");
    if (Email && Password) {
      form.setFieldsValue({
        email: Email,
        password: Password,
      });
    }
  });
  return (
    <>
      {isOtp ? (
        <OTP isLogin={true} lastLoginOtp={lastLoginOtp} token={token} />
      ) : (
        <>
          {loading ? (
            <div className="loaderContainer">
              <img src={LoaderGif} alt="" />
            </div>
          ) : (
            <div className="login_main_container">
              <div className="login_container">
                <Row className="h-100">
                  {/* login form section */}
                  <Col span={12} className="login-form-container">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      <Image src={Logo} preview={false} />
                    </div>
                    {/* login form start */}
                    <div className="form-outer-div">
                      <div className="form-inner-div">
                        <p className="heading1">Login</p>

                        <Form
                          onFinish={LoginUser}
                          form={form}
                          layout="vertical"
                        >
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                name="email"
                                autoComplete="false"
                                label={
                                  <span className="label-items">Email*</span>
                                }
                                required={false}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your email!",
                                  },
                                  {
                                    type: "email",
                                  },
                                ]}
                              >
                                <Input
                                  type="email"
                                  placeholder="Email"
                                  className="form-input-box"
                                  prefix={<img src={MailIcon} alt="mail" />}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item
                                label={
                                  <span className="label-items">Password*</span>
                                }
                                name="password"
                                required={false}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your password!",
                                  },
                                ]}
                              >
                                <Input.Password
                                  placeholder="Password"
                                  type="password"
                                  style={{
                                    background: "transparent",
                                    color: "white",
                                    border: "1px solid white",
                                  }} // Set the input background to transparent
                                  prefix={<img src={PasswordIcon} alt="lock" />}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={12}
                              style={{
                                marginTop: "-6px",
                                marginBottom: "6px",
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Checkbox
                                className="cb_remember_me"
                                onChange={(e) =>
                                  setRememberMe(e?.target?.checked)
                                }
                              >
                                Remember Me
                              </Checkbox>
                            </Col>
                            <Col
                              xs={12}
                              style={{
                                marginTop: "-6px",
                                marginBottom: "6px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <span
                                onClick={() => navigate("/forgot-password")}
                                className="cursor-pointer color-white label-items"
                              >
                                Forgot Password?
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Button
                                  htmlType="submit"
                                  style={{ width: "100%" }}
                                  className="submitBtn"
                                >
                                  Sign In
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </div>
                  </Col>
                  {/* banner image section */}
                  <Col span={12} className="banner-image">
                    <Image
                      src="/assets/login/login-banner.png"
                      preview={false}
                      className="h-100"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Login;
