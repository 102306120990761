import React from "react";
import { Col, Row } from "antd";
import dpImage from "../../Assets/subscription/standard_card_image.svg";
import GreenTick from "../../Assets/campaign/greenTick.svg";

function ClientPreview({ previewData, featureArr }) {
  const numberWithCommas = (number) => {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
  console.log(previewData, "data");
  {
    previewData, featureArr;
  }
  return (
    <Row justify={"center"}>
      <Col md={24} className="detail_container">
        <Row justify={"center"}>
          <Col xs={24} style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <p className="previewTitle">Preview</p>{" "}
          </Col>
          <Row
            justify={"center"}
            className="standard_plan_card_container"
            style={{ background: "#F8F9FF", border: "1px solid #122D8E" }}
          >
            <Col xs={24}>
              <Row gutter={12}>
                <Col>
                  <img src={dpImage} className="dpImage" />
                </Col>
                <Col>
                  <Col xs={24}>
                    <p className="title">{previewData.name}</p>
                  </Col>
                  <Col xs={24} style={{ marginTop: "10px" }}>
                    <p className="title">
                      {" "}
                      {previewData?.default_price
                        ? "$" + numberWithCommas(previewData?.default_price)
                        : ""}
                      {previewData?.recurringType
                        ? "/" + previewData?.recurringType
                        : ""}
                    </p>
                  </Col>
                </Col>
              </Row>
              <Row className="content_row">
                <Col>
                  {featureArr?.map((e, key) => {
                    return (
                      <Col xs={24} key={key} style={{ marginBottom: "10px" }}>
                        <Row gutter={6}>
                          <Col xs={3}>
                            <img src={GreenTick} />
                          </Col>
                          <Col xs={1} />
                          <Col xs={20} className="bulletPoint">
                            {e.name}
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Col>
    </Row>
  );
}
export default ClientPreview;
