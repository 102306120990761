import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Row, Col, Table, Input, message, Switch, Tooltip } from "antd";
import { DeleteCandidate } from "../../components/common/Popup";
import API_MANAGER from "../../API";
import moment from "moment";
import DeleteIcon from "../../Assets/delete.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CustomPagination from "../../components/common/CustomPagination";

function CampaignList() {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const toggleStatus = async (status, id) => {
    setLoading(true);
    try {
      await API_MANAGER.toggleCampaign({ isActive: status }, id);
      message.success(
        `Succefully ${status !== true ? "disabled" : "enabled"} campaign`
      );
    } catch {
      message.error("Something went wrong");
    }
    setLoading(false);
  };
  const userType = localStorage.getItem("userType");
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page - 1) * 10 + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (_, row) => {
        return (
          <span className="cursor-pointer">{row?.companyId?.companyName}</span>
        );
      },
    },
    {
      title: "Campaign Name",
      dataIndex: "name",
      key: "name",
      render: (_, row) => {
        return <span className="cursor-pointer">{row.name}</span>;
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, row) => {
        return (
          <span className="cursor-pointer">
            {moment(row.createdAt).format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      title: "Total Adverts",
      dataIndex: "advertCount",
      key: "advertCount",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.advertCount}</span>;
      },
    },
    {
      title: "Target Headcount",
      dataIndex: "targetHeadCount",
      key: "targetHeadCount",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.targetHeadCount}</span>;
      },
    },
    {
      title: "Total Applications",
      dataIndex: "appliedJobsCount",
      key: "appliedJobsCount",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.appliedJobsCount}</span>;
      },
    },
    {
      title: "Total Shortlisted",
      dataIndex: "shortlistCount",
      key: "shortlistCount",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.shortlistCount}</span>;
      },
    },
    {
      title: "Total Accepted",
      dataIndex: "offerAccepted",
      key: "offerAccepted",
      render: (_, row) => {
        return <span className="cursor-pointer">{row?.offerAccepted}</span>;
      },
    },
    {
      title: (
        <span>
          Enable / <br /> Disable
        </span>
      ),
      dataIndex: "isActive",
      key: "isActive",
      render: (_, row) => {
        return (
          <span className="cursor-pointer" onClick={(e)=> e.stopPropagation()}>
            <Switch
              defaultChecked={row?.isActive}
              onClick={(e) => toggleStatus(e, row._id)}
            />
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "delete",
      key: "delete",
      render: (text, row) => (
        <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
          {userType === "admin" ? (
            <img
              src={DeleteIcon}
              alt="delete"
              style={{ width: "35px" }}
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteId(row._id);
                setDeleteModal(true);
              }}
            />
          ) : (
            <img
              src={DeleteIcon}
              alt="delete"
              style={{ width: "35px" }}
              className="cursor-pointer"
              onClick={(e)=> e.stopPropagation()}
            />
          )}
        </Tooltip>
      ),
    },
  ];
  const getData = async (search, pageNumber) => {
    setLoading(true);
    if (page) {
      try {
        const response = await API_MANAGER.getCampaignList({
          search: search,
          page: pageNumber,
        });
        setData(response?.data?.data);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  };
  const deleteConfirm = async () => {
    setConfirmLoading(true);
    try {
      await API_MANAGER.deleteCampaign(deleteId);
      message.success("Successfully deleted campaign feed");
      getData(search);
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message || "Something went wrong"
      );
    }
    setConfirmLoading(true);
    setDeleteModal(false);
  };
  useEffect(() => {
    if (page) {
      getData(search, page);
    }
  }, [search, page]);

  useEffect(() => {
    if (state && state?.path === "/campaign-list") {
      setSearch(state?.search);
      if (state?.page) {
        setPage(state.page);
      } else {
        setPage(1);
      }
      getData(search);
    } else {
      setPage(1);
    }
  }, [state]);

  return (
    <SubLayout page="Campaign List">
      <Row className="search_container">
        <Col xs={6}>
          <Input.Search
            placeholder="Search"
            value={search}
            allowClear
            onChange={(e) => {setSearch(e?.target?.value),setPage(1)}}
          />
        </Col>
      </Row>
      <Row className="table_container">
        <Table
          columns={columns}
          dataSource={data?.result}
          pagination={false}
          className="w-100 table"
          rowKey={"id"}
          loading={loading}
          scroll={{
            y: "calc(100vh - 375px)",
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`/campaign-list/advert-list/${record._id}`, {
                  state: {
                    page: page,
                    search: search,
                    path: "/campaign-list",
                  },
                });
              }, // click row
            };
          }}
        />
        <div className="w-100">
          <CustomPagination
            currentPage={page}
            setCurrentPage={setPage}
            total={data?.totalCampaign}
            itemPerPage={10}
          />
        </div>
      </Row>
      <DeleteCandidate
        confirmDelete={deleteConfirm}
        visible={openDeleteModal}
        text={"Campaign"}
        setVisible={setDeleteModal}
        loading={confirmLoading}
      />
    </SubLayout>
  );
}

export default CampaignList;
