import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  message,
  DatePicker,
  Radio,
  Collapse,
  Divider,
  Modal,
  Upload,
  Spin,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import PhoneNumber from "../../../components/common/common/phoneNo";
import QualificationEdit from "./QualificationEdit";
import OtherDetailEdit from "./OtherDetailEdit";
import AdditionalDetailEdit from "./AdditionalDetailEdit";
import JobPositionCard from "../../../components/common/campaign/JobPositionCard";
import { useNavigate, useParams } from "react-router-dom";
import API_MANAGER from "../../../API";
import moment from "moment";
import DocumentBox from "../../../components/common/DocumentBox";
import EditCandidateIcon from "../../../Assets/editCandidateIcon.svg";
import { EditFilled, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { s3PreUrl } from "../../../constants/index";
import AddQualification from "./AddQualification";
import AddDocuments from "./AddDocuments";
import AddExperience from "./AddExperience";
const { TextArea } = Input;

function CandidateEdit() {
  const [personalForm] = useForm();
  const [locationForm] = useForm();
  const [resumeForm] = useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [discipline, setDisciplines] = useState([]);
  const [ethnicity, setEthnicity] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [induction, setInduction] = useState([]);
  const [skills, setSkills] = useState([]);
  const [resumeEdit, setResumeEdit] = useState(false);
  const [qualificationEdit, setQualificationEdit] = useState(false);
  const [qualificationData, setQualificationData] = useState({});
  const [qualification, setQualification] = useState([]);
  const [licenceType, setLicenceType] = useState([]);
  const [classData, setClassData] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [citizen, setCitizen] = useState("australian");
  const [data, setData] = useState({});
  const [type, setType] = useState("PD");
  const [otherDetailEdit, setOtherDetailEdit] = useState(false);
  const [resumeUpload, setResumeUpload] = useState(false);
  const [resumeFileList, setResumeFileList] = useState([]);
  const [additionalEdit, setAdditionalEdit] = useState(false);
  const [otherDocumentData, setOtherDocumentData] = useState({});
  const [certifyingAuthority, setCertifyingAuthority] = useState([]);
  const [fundName, setFundName] = useState([]);
  const [stateTags, setStateTags] = useState([]);
  const [visaTypeNo, setVisaTypeNo] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [addQualification, setAddQualification] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const [addExperience, setAddExperience] = useState(false);
  const [idType, setIdType] = useState([]);
  const navigate = useNavigate();
  const props = {
    name: "file",
    accept: "image/jpeg,image/jpg,.pdf",
    multiple: false,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const onFinish = async (values) => {
    setLoading(true);
    try {
      values.userId = data._id;
      if (type === "PD") {
        values.phoneNumber = `${values.phoneNumber}`;
      }
      await API_MANAGER.editCandidatePersonalDetails(values);
      message.success("Successfully edited personal details");
      navigate(-1);
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
    setLoading(false);
  };
  const onResumeFinish = async (values) => {
    setLoading(true);
    try {
      if (resumeFileList.length > 0) {
        const dataResume = {
          extension: resumeFileList?.[0]?.type.split("/")[1],
          type: "DOCUMENT",
          name: resumeFileList?.[0].originFileObj.name,
          contentType:
            resumeFileList?.length > 0 ? resumeFileList[0]?.type : "",
        };
        const responseResume = await API_MANAGER.fileUpload(dataResume);
        await axios.put(
          responseResume?.data?.data?.url,
          resumeFileList?.[0]?.originFileObj
        );
        values.resume =
          resumeFileList?.length > 0
            ? s3PreUrl + responseResume.data?.data?.key
            : data?.candidate?.resume?.[0]?.resume;
        values.userId = data._id;
        if (data?.candidate?.resume?.length > 0) {
          await API_MANAGER.editCandidateResume(
            values,
            data?.candidate?.resume?.[0]?._id
          );
        } else {
          await API_MANAGER.uploadCandidateResume(values);
        }
        message.success("Successfully edited personal details");
      } else {
        values.userId = data._id;
        if (data?.candidate?.resume?.length > 0) {
          await API_MANAGER.editCandidateResume(
            values,
            data?.candidate?.resume?.[0]?._id
          );
        } else {
          values.profileSummary = values.description;
          await API_MANAGER.editCandidatePersonalDetails(values);
        }
        message.success("Successfully edited personal details");
      }
      setResumeFileList([]);
      setResumeUpload(false);
      setResumeEdit(false);
      getData();
      setLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
  };
  const handleResume = ({ fileList: newFileList }) => {
    setResumeFileList(newFileList);
  };
  const handleQualificationEdit = () => {
    setQualificationEdit(!qualificationEdit);
  };
  const handleOtherDetailEdit = () => {
    setOtherDetailEdit(!otherDetailEdit);
  };
  const setFields = () => {
    personalForm.setFieldsValue({
      name: data?.candidate?.name,
      email: data?.candidate?.email,
      dob: data?.candidate?.dob
        ? dayjs(data?.candidate?.dob, "DD-MM-YYYY")
        : null,
      phoneNumber: data?.candidate?.phoneNumber,
      countryCode: `+${data?.candidate?.countryCode}`,
      gender: data?.candidate?.gender,
      ethnicity: data?.candidate?.ethnicity?.id,
      discipline: data?.candidate?.discipline?.id,
      isAvailable: data?.candidate?.isAvailable,
    });
    locationForm.setFieldsValue({
      location: data?.candidate?.location,
      street: data?.candidate?.street,
      city: data?.candidate?.city,
      state: data?.candidate?.state,
      country: data?.candidate?.country,
      postalCode: data?.candidate?.postalCode,
    });
    resumeForm.setFieldsValue({
      description: data?.candidate?.profileSummary,
    });
  };
  const getTags = async (type, setData) => {
    try {
      const response = await API_MANAGER.getPickListTags({
        type,
        limit: 2000,
        sort: "name",
      });
      setData(response?.data?.data?.tags);
    } catch (error) {
      message.error("Something went wrong");
    }
  };
  const getData = async () => {
    try {
      const response = await API_MANAGER.getCandidateDetail(id);
      response?.data?.data?.candidate?.candidateVisa?.length > 0
        ? setCitizen("non_australian")
        : setCitizen("australian");
      setData(response.data.data);
    } catch {
      message.error("Something went wrong");
    }
  };
  let arr = [];
  let currentRole = [];
  data?.candidate?.experiences?.forEach((item) => {
    if (item.currentRole === true) {
      currentRole.push(item);
    } else {
      arr.push(item);
    }
  });
  const getLicenceNo = (e) => {
    return e?.documentData?.licenceNumber
      ? e?.documentData?.licenceNumber
      : e?.documentData?.licenseNumber;
  };
  let profExp = [...currentRole, ...arr];
  useEffect(() => {
    getTags("DISCIPLINE", setDisciplines);
    getTags("ETHNICITY", setEthnicity);
    getTags("INDUSTRY", setIndustry);
    getTags("INDUCTION", setInduction);
    getTags("SKILL", setSkills);
    getTags("QUALIFICATION", setQualification);
    getTags("LICENCETYPE", setLicenceType);
    getTags("LICENCE_CLASS", setClassData);
    getTags("LICENCE_CONDITION", setConditions);
    getTags("CERTIFYING_AUTHORITY", setCertifyingAuthority);
    getTags("JOBTITLE", setJobTitle);
    getTags("FUND_NAME", setFundName);
    getTags("STATE", setStateTags);
    getTags("VISA", setVisaTypeNo);
    getTags("ID_TYPE", setIdType);
  }, []);
  useEffect(() => {
    getData();
  }, [
    resumeEdit,
    qualificationEdit,
    otherDetailEdit,
    additionalEdit,
    addDocument,
    addQualification,
    addExperience,
  ]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setFields();
    }
  }, [data]);
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Row className="professional_detail_container">
          <Col className="radioButtons">
            <Radio.Group
              defaultValue={type}
              onChange={(e) => setType(e?.target?.value)}
            >
              <Radio.Button value="PD">Personal Details</Radio.Button>
              <Radio.Button value="LT">Location</Radio.Button>
              <Radio.Button value="PE">Professional Experience</Radio.Button>
              <Radio.Button value="QT">Qualification</Radio.Button>
              <Radio.Button value="OD">Other Details</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </div>
      {type == "PD" && (
        <div className="create_user">
          <Form
            layout="vertical"
            requiredMark={false}
            form={personalForm}
            onFinish={onFinish}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Name*"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input className="" placeholder="Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email*"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email",
                    },
                    {
                      type: "email",
                      message: "Enter valid email",
                    },
                  ]}
                >
                  <Input
                    className=""
                    placeholder="Email"
                    type="email"
                    disabled={data?.isVerified}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="D.O.B*"
                  name="dob"
                  rules={[
                    {
                      required: true,
                      message: "Please enter D.O.B",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format={"DD-MM-YYYY"}
                    // defaultValue={dayjs(data?.candidate?.candidate?.dob, 'DD-MM-YYYY')}
                    placeholder="Select date of birth"
                    style={{ height: "38px" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} className="inputCol">
                <label className="inputCol1">Contact Number*</label>
                <PhoneNumber />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} className="inputCol">
                <Form.Item
                  label="Gender*"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Please enter gender",
                    },
                  ]}
                >
                  <Select className="" placeholder="Select gender">
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Ethnicity"
                  name="ethnicity"
                  rules={[
                    {
                      required: true,
                      message: "Please select ethnicity",
                    },
                  ]}
                >
                  <Select className="" placeholder="Ethnicity">
                    {ethnicity.map((e, key) => {
                      return (
                        <Select.Option key={key} value={e.id}>
                          {e.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Discipline*"
                  name="discipline"
                  rules={[
                    {
                      required: true,
                      message: "Please select discipline",
                    },
                  ]}
                >
                  <Select className="" placeholder="discipline">
                    {discipline.map((e, key) => {
                      return (
                        <Select.Option key={key} value={e.id}>
                          {e.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} style={{ marginTop: "30px" }}>
                <Form.Item
                  name="isAvailable"
                  rules={[
                    {
                      required: true,
                      message: "Please select availability",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={true}>Available for work</Radio>
                    <Radio value={false}>Not available for work</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6} className="buttonCol">
              <Col>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      {type == "LT" && (
        <div className="create_user">
          <Form
            layout="vertical"
            requiredMark={false}
            form={locationForm}
            onFinish={onFinish}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Choose Location*"
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: "Please enter location",
                    },
                  ]}
                >
                  <Input className="" placeholder="location" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Street Name and No.*"
                  name="street"
                  rules={[
                    {
                      required: true,
                      message: "Please enter street",
                    },
                  ]}
                >
                  <Input className="" placeholder="Street" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="City/Suburb*"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Please enter city",
                    },
                  ]}
                >
                  <Input className="" placeholder="City" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="State*"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "Please enter state",
                    },
                  ]}
                >
                  <Input className="" placeholder="State" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Postal Code*"
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: "Please enter postalCode",
                    },
                  ]}
                >
                  <Input className="" placeholder="Postal Code" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Country*"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please enter country",
                    },
                  ]}
                >
                  <Input className="" placeholder="Country" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={6} className="buttonCol">
              <Col>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
      {type == "PE" && (
        <>
          <Row>
            <Col
              xs={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                className="bulk_button"
                onClick={() => {
                  setAddExperience(true);
                }}
              >
                Add Experience
              </Button>
            </Col>
          </Row>
          <div
            style={{ marginTop: "20px" }}
            className="professional_experience_container"
          >
            <div className="professional_summary_box">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <img
                  src={EditCandidateIcon}
                  onClick={() => setResumeEdit(true)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <p className="title">Profile Summary Description</p>
              <p className="desc">{data?.candidate?.profileSummary}</p>
              {data?.candidate?.resume?.length > 0 && (
                <div className="mt-30">
                  <p className="title">Resume</p>
                  <Row>
                    <Col span={18}>
                      <DocumentBox
                        date={data?.candidate?.resume[0]?.createdAt}
                        url={data?.candidate?.resume[0]?.resume}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <div>
              <Row gutter={18}>
                {profExp?.map((item, index) => (
                  <Col md={24} key={index}>
                    <JobPositionCard
                      data={item}
                      discipline={discipline}
                      induction={induction}
                      industry={industry}
                      skills={skills}
                      profileId={data?.candidate?._id}
                      jobTitle={jobTitle}
                      onUpdate={getData}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </>
      )}
      {type == "QT" && (
        <>
          <Row>
            <Col
              xs={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                className="bulk_button"
                onClick={() => {
                  setAddQualification(true);
                }}
              >
                Add Qualification
              </Button>
            </Col>
          </Row>
          {data?.candidate?.qualifications?.length > 0 && (
            <div className="create_user">
              <div className="qualification_container">
                {data?.candidate?.qualifications.map((e, key) => {
                  return (
                    <div style={{ marginBottom: "20px" }} key={key}>
                      <div className="qualification_summary_box mb-16">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            src={EditCandidateIcon}
                            onClick={() => {
                              setQualificationEdit(true),
                                setQualificationData(e);
                            }}
                          />
                        </div>
                        <Row
                          gutter={8}
                          className="align_center"
                          align={"middle"}
                          style={{ marginBottom: "10px" }}
                        >
                          <Col className="align_center">
                            <p className="title mb-0">
                              {e.qualificationType.name}{" "}
                            </p>
                          </Col>
                          {e?.isValidate && (
                            <Col>
                              <svg
                                style={{ marginBottom: "16px !important" }}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                                  fill="#31B948"
                                />
                              </svg>
                            </Col>
                          )}
                        </Row>
                        <Row gutter={24}>
                          <Col
                            span={15}
                            onClick={() => {
                              if (e?.uploadedDocument) {
                                window.open(e?.uploadedDocument);
                              }
                            }}
                          >
                            <DocumentBox
                              date={e?.issueDate}
                              url={e?.uploadedDocument}
                            />
                          </Col>
                          <Col span={9}>
                            <Row justify={"space-between"}>
                              <Col span={12}>
                                <p className="item">Issue Date</p>
                                <p className="value">
                                  {e?.issueDate
                                    ? moment(e?.issueDate).format("DD MMM YYYY")
                                    : "----"}
                                </p>
                              </Col>
                              <Col span={12}>
                                <p className="item">Expiry Date</p>
                                <p className="value">
                                  {e?.expiryDate
                                    ? moment(e?.expiryDate).format(
                                      "DD MMM YYYY"
                                    )
                                    : "----"}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <div className="mt-30">
                          <Row gutter={24}>
                            <Col span={7}>
                              <p className="item">Member No./ID</p>
                              <p className="value">
                                {e?.memberId ? e?.memberId : "----"}
                              </p>
                            </Col>
                            <Col span={7}>
                              <p className="item">State/Country</p>
                              <p className="value">
                                {e?.stateOrCountry ? e?.stateOrCountry : "----"}
                              </p>
                            </Col>
                            <Col span={10}>
                              <p className="item">
                                Licence Type/Class/Category
                              </p>
                              <p className="value">
                                {e?.licenceType?.map((item, index) => (
                                  <p
                                    key={index}
                                    className="w-100"
                                    style={{ margin: "0", padding: "0" }}
                                  >
                                    {index === e?.licenceType?.length - 1
                                      ? item?.name
                                      : ` ${item?.name}, `}
                                  </p>
                                ))}
                              </p>
                            </Col>
                            <Col xs={24}>
                              {e?.hasSOA || e?.hasVOC ? (
                                <div className="qualification_detail_box">
                                  {e?.hasSOA && (
                                    <div className="collapseContainer">
                                      <Collapse
                                        // size="small"
                                        expandIconPosition={"end"}
                                        bordered={false}
                                        items={[
                                          {
                                            key: "1",
                                            label: "Statement of Attainment",
                                            children: (
                                              <Row gutter={24}>
                                                <Col
                                                  span={14}
                                                  onClick={() => {
                                                    if (e?.SOADocument) {
                                                      window.open(
                                                        e?.SOADocument
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <DocumentBox
                                                    date={e?.SOAIssueDate}
                                                    url={e?.SOADocument}
                                                  />
                                                </Col>
                                                <Col span={110}>
                                                  <p className="item">
                                                    Issue Date
                                                  </p>
                                                  <p className="value">
                                                    {e?.SOAIssueDate
                                                      ? moment(
                                                        e?.SOAIssueDate
                                                      ).format("DD MMM YYYY")
                                                      : "----"}
                                                  </p>
                                                </Col>
                                              </Row>
                                            ),
                                          },
                                        ]}
                                      />
                                    </div>
                                  )}

                                  {e?.hasVOC && (
                                    <>
                                      <Divider className="horizontalDivider" />
                                      <div className="collapseContainer">
                                        <Collapse
                                          // size="small"
                                          expandIconPosition={"end"}
                                          bordered={false}
                                          items={[
                                            {
                                              key: "1",
                                              label:
                                                "Verification of Competency",
                                              children: (
                                                <Row gutter={24}>
                                                  <Col
                                                    span={14}
                                                    onClick={() => {
                                                      if (e?.VOCDocument) {
                                                        window.open(
                                                          e?.VOCDocument
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <DocumentBox
                                                      date={e?.VOCIssueDate}
                                                      url={e?.VOCDocument}
                                                    />
                                                  </Col>
                                                  <Col span={10}>
                                                    <Row
                                                      justify={"space-between"}
                                                    >
                                                      <Col span={12}>
                                                        <p className="item">
                                                          {" "}
                                                          Verification Type
                                                        </p>
                                                        <p className="value">
                                                          {e?.VOCType?.name
                                                            ? e?.VOCType?.name
                                                            : "----"}
                                                        </p>
                                                      </Col>
                                                      <Col span={12}>
                                                        <p className="item">
                                                          Issue Date{" "}
                                                        </p>
                                                        <p className="value">
                                                          {e?.VOCIssueDate
                                                            ? moment(
                                                              e?.VOCIssueDate
                                                            ).format(
                                                              "DD MMM YYYY"
                                                            )
                                                            : "----"}
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              ),
                                            },
                                          ]}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
      {type == "OD" && (
        <div style={{ marginTop: "20px" }} className="other_detail_container">
          <Row>
            <Col
              xs={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "-20px 0px 10px 0px",
              }}
            >
              <Button
                className="bulk_button"
                onClick={() => {
                  setAddDocument(true);
                }}
              >
                Add Document
              </Button>
            </Col>
          </Row>
          <div className="citizen_container">
            {data?.candidate?.documents?.map((e, key) => {
              return (
                <div key={key} style={{ marginBottom: "20px" }}>
                  <div className="titleBox">
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <img
                        src={EditCandidateIcon}
                        onClick={() => {
                          setOtherDetailEdit(true), setOtherDocumentData(e);
                        }}
                      />
                    </div>
                    {e.documentType.name}
                    {/* <div className="justify_end"> */}
                    {e?.isVerfied && (
                      <svg
                        style={{ marginBottom: "16px !important" }}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                          fill="#31B948"
                        />
                      </svg>
                    )}
                  </div>
                  <div className="detail-container">
                    <Row gutter={24}>
                      <Col
                        span={14}
                        onClick={() => {
                          window.open("");
                        }}
                      >
                        <DocumentBox date={e?.createdAt} url={e?.document} />
                      </Col>
                      <Col span={10}>
                        <Row justify={"space-between"}>
                          <Col span={12}>
                            {e.documentType.name === "VISA" && (
                              <>
                                <p className="item">Grant No</p>
                                <p className="value">
                                  {e?.documentData?.grantNo}
                                </p>
                              </>
                            )}
                            {e.documentType.name === "Passport" && (
                              <>
                                <p className="item">Nationality</p>
                                <p className="value">
                                  {e.documentData.nationality}
                                </p>
                              </>
                            )}
                            {e.documentType.name === "Driver Licence" && (
                              <>
                                <p className="item">State</p>
                                <p className="value">
                                  {e.documentData.stateDL}
                                </p>
                              </>
                            )}
                            {e.documentType.name ===
                              "Proof of Age/Photo Card" && (
                                <>
                                  <p className="item">State</p>
                                  <p className="value">{e.documentData.state}</p>
                                </>
                              )}
                            <div className="mt-16">
                              <p className="item">Expiry Date</p>
                              <p className="value">
                                {moment(e?.documentData?.expiryDate).format(
                                  "DD-MMM-YYYY"
                                )}
                              </p>
                            </div>
                          </Col>
                          <Col span={12}>
                            <p className="item">
                              {e?.documentType?.name === "Passport"
                                ? "Passport Number"
                                : e?.documentType?.name === "Driver Licence"
                                  ? "Licence Number"
                                  : e?.documentType?.name === "VISA"
                                    ? "Visa Type No"
                                    : "Id Number"}
                            </p>
                            <p className="value">
                              {e.documentType.name === "Passport"
                                ? e?.documentData?.passportNumber
                                : e?.documentType?.name === "Driver Licence"
                                  ? getLicenceNo(e)
                                  : e?.documentType?.name === "VISA"
                                    ? e?.documentData?.visaTypeNo?.name
                                    : e?.documentData?.idNumber}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="additional_detail_container">
            <div className="titleBox">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <img
                  src={EditCandidateIcon}
                  onClick={() => {
                    setAdditionalEdit(true);
                  }}
                />
              </div>
              Additional Personal Details
            </div>
            <div className="detail-container">
              <Row gutter={16}>
                <Col span={8}>
                  <p className="item">Tax File Number (TFN)</p>
                  <p className="value">
                    {data?.candidate?.otherDetails[0]?.tfnNo
                      ? data?.candidate?.otherDetails[0]?.tfnNo
                      : "----"}
                  </p>
                </Col>
                <Col span={8}>
                  <p className="item">USI No.</p>
                  <p className="value">
                    {data?.candidate?.otherDetails[0]?.usiNo
                      ? data?.candidate?.otherDetails[0]?.usiNo
                      : "----"}
                  </p>
                </Col>
              </Row>
              {data?.candidate?.otherDetails[0]?.isSuperannuation && (
                <>
                  <Divider className="horizontalDivider" />
                  <div>
                    <p className="title">Superannuation</p>
                    <Row gutter={16}>
                      <Col span={8}>
                        <p className="item">Fund Name</p>
                        <p className="value">
                          {
                            data?.candidate?.otherDetails[0]
                              ?.superannuationDetails?.fundName?.name
                          }
                        </p>
                      </Col>
                      <Col span={8}>
                        <p className="item">Fund USI/ABN</p>
                        <p className="value">
                          {
                            data?.candidate?.otherDetails[0]
                              ?.superannuationDetails?.fundName
                              ?.correspondingValue
                          }
                        </p>
                      </Col>
                      <Col span={8}>
                        <p className="item">Member Number</p>
                        <p className="value">
                          {
                            data?.candidate?.otherDetails[0]
                              ?.superannuationDetails?.memberNumber
                          }
                        </p>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              {data?.candidate?.otherDetails[0]?.isLSL && (
                <>
                  <Divider className="horizontalDivider" />
                  <div>
                    <p className="title">Long Service Leave</p>
                    <Row gutter={16}>
                      <Col span={8}>
                        <p className="item">State</p>
                        <p className="value">
                          {" "}
                          {
                            data?.candidate?.otherDetails[0]?.lslDetails?.state
                              ?.name
                          }
                        </p>
                      </Col>

                      <Col span={8}>
                        <p className="item">Member Number</p>
                        <p className="value">
                          {
                            data?.candidate?.otherDetails[0]?.lslDetails
                              ?.memberNumber
                          }
                        </p>
                      </Col>
                    </Row>
                  </div>
                </>
              )}

              {data?.candidate?.otherDetails[0]?.isPPE && (
                <>
                  <Divider className="horizontalDivider" />
                  <div>
                    <p className="title">PPE Details</p>
                    <Row gutter={16}>
                      <Col span={8}>
                        <p className="item">Top</p>
                        <p className="value">
                          {data?.candidate?.otherDetails[0]?.ppeDetails?.top}
                        </p>
                      </Col>

                      <Col span={8}>
                        <p className="item">Bottom</p>
                        <p className="value">
                          {data?.candidate?.otherDetails[0]?.ppeDetails?.bottom}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
              {data?.candidate?.otherDetails[0]?.hasTravelDetails && (
                <>
                  <Divider className="horizontalDivider" />
                  <div>
                    <p className="title">Travel Details</p>
                    <Row gutter={16}>
                      <Col span={8}>
                        <p className="item">Preferred Airline</p>
                        <p className="value">
                          {
                            data?.candidate?.otherDetails[0]?.travelDetails
                              ?.preferredAirline
                          }
                        </p>
                      </Col>

                      <Col span={8}>
                        <p className="item">Frequent Flyer Number</p>
                        <p className="value">
                          {
                            data?.candidate?.otherDetails[0]?.travelDetails
                              ?.frequentFlyerNumber
                          }
                        </p>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </div>
          </div>

          {data?.candidate?.otherDetails[0]?.hasPoliceClearance && (
            <div className="clearance_box">
              <Row>
                <Col xs={5} style={{ marginTop: "7px" }}>
                  <p className="title">Police Clearance</p>
                </Col>
                {data?.candidate?.otherDetails[0]?.isValidate && (
                  <Col>
                    <svg
                      style={{ marginBottom: "16px !important" }}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                        fill="#31B948"
                      />
                    </svg>
                  </Col>
                )}
              </Row>
              <Row
                gutter={24}
                onClick={() =>
                  window.open(
                    data?.candidate?.otherDetails[0]?.policeClearanceDetails
                      ?.document
                  )
                }
              >
                <Col span={14}>
                  <DocumentBox
                    date={
                      data?.candidate?.otherDetails[0]?.policeClearanceDetails
                        ?.issueDate
                    }
                    url={
                      data?.candidate?.otherDetails[0]?.policeClearanceDetails
                        ?.document
                    }
                  />
                </Col>
                <Col span={10}>
                  <Row justify={"space-between"} gutter={12}>
                    <Col span={12}>
                      <p className="item">Certifying Authority</p>
                      <p className="value">
                        {
                          data?.candidate?.otherDetails[0]
                            ?.policeClearanceDetails?.certifyingAuthority?.name
                        }
                      </p>
                    </Col>
                    <Col span={12}>
                      <p className="item">Issue Date </p>
                      <p className="value">
                        {moment(
                          data?.candidate?.otherDetails[0]
                            ?.policeClearanceDetails?.issueDate
                        ).format("DD MMM YYYY")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <Divider className="horizontalDivider" /> */}
            </div>
          )}
          {data?.candidate?.medicalAssessment[0]?.hasMedicalAssessment &&
            data?.candidate?.medicalAssessment[0]?.medicals?.map((e, key) => {
              return (
                <div className="clearance_box" key={key}>
                  <Row>
                    <Col xs={5} style={{ marginTop: "7px" }}>
                      <p className="title">{"Medical Assessment"}</p>
                    </Col>
                    {e?.isValidate && (
                      <Col>
                        <svg
                          style={{
                            marginBottom: "16px !important",
                          }}
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                            fill="#31B948"
                          />
                        </svg>
                      </Col>
                    )}
                  </Row>
                  <Row gutter={24}>
                    <Col span={14}>
                      <DocumentBox
                        date={data?.candidate?.medicalAssessment[0]?.createdAt}
                        url={e?.document}
                      />
                    </Col>
                    <Col span={10}>
                      <Row justify={"space-between"} gutter={12}>
                        <Col span={12}>
                          <p className="item">
                            {citizen === "australian"
                              ? "Medical Assessment"
                              : "Verification Type"}
                          </p>
                          <p className="value">{e?.medicalAssessment?.name}</p>
                        </Col>
                        <Col span={12}>
                          <p className="item">Issue Date </p>
                          <p className="value">
                            {moment(e?.issueDate).format("DD MMM YYYY")}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              );
            })}
        </div>
      )}
      {addQualification && (
        <AddQualification
          setAddQualification={setAddQualification}
          addQualification={addQualification}
          qualification={qualification}
          licenceType={licenceType}
          profileId={data?.candidate?._id}
          stateTags={stateTags}
        />
      )}
      {addDocument && (
        <AddDocuments
          addDocument={addDocument}
          setAddDocument={setAddDocument}
          classData={classData}
          conditions={conditions}
          idType={idType}
          citizen={citizen}
          userId={data?.candidate?.userId}
          stateTags={stateTags}
          visaTypeNo={visaTypeNo}
        />
      )}
      {addExperience && (
        <AddExperience
          addExperience={addExperience}
          setAddExperience={setAddExperience}
          jobTitle={jobTitle}
          industry={industry}
          discipline={discipline}
          induction={induction}
          skills={skills}
          userId={data?.candidate?.userId}
        />
      )}
      {qualificationEdit && (
        <QualificationEdit
          data={qualificationData}
          qualification={qualification}
          qualificationEdit={qualificationEdit}
          handleQualificationEdit={handleQualificationEdit}
          licenceType={licenceType}
          profileId={data?.candidate?._id}
          stateTags={stateTags}
        />
      )}
      {otherDetailEdit && (
        <OtherDetailEdit
          data={otherDocumentData}
          citizen={citizen}
          otherDetailEdit={otherDetailEdit}
          handleOtherDetailEdit={handleOtherDetailEdit}
          classData={classData}
          conditions={conditions}
          userId={data?.candidate?.userId}
          stateTags={stateTags}
          visaTypeNo={visaTypeNo}
        />
      )}
      {additionalEdit && (
        <AdditionalDetailEdit
          additionalEdit={additionalEdit}
          setAdditionalEdit={setAdditionalEdit}
          data={data?.candidate?.otherDetails[0]}
          userId={data?.candidate?.userId}
          certifyingAuthority={certifyingAuthority}
          stateTags={stateTags}
          fundName={fundName}
        />
      )}
      <Modal
        centered
        footer={false}
        open={resumeEdit}
        title={"Professional Summary Edit"}
        className="resumeModal"
        onCancel={() => setResumeEdit(false)}
      >
        <Form form={resumeForm} layout="vertical" onFinish={onResumeFinish}>
          <Spin spinning={loading} />
          <Row className="containner">
            <Col xs={18}>
              {resumeUpload ? (
                <Upload {...props} onChange={handleResume}>
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              ) : (
                <DocumentBox
                  date={data?.candidate?.resume[0]?.createdAt}
                  url={data?.candidate?.resume[0]?.resume}
                />
              )}
            </Col>
            <Col className="editResume" xs={6}>
              <EditFilled onClick={() => setResumeUpload(true)} />
            </Col>
          </Row>
          <Row className="containner">
            <Col xs={24}>
              <Form.Item label="Profile Summary Description" name="description">
                <TextArea
                  placeholder="Enter summary description"
                  rows={4}
                  maxLength={150}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="buttonCol">
              <Button className="btn1" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
export default CandidateEdit;
