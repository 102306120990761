import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  Upload,
  message,
  Button,
  Modal,
  Image,
} from "antd";
import SubLayout from "../../components/layout/SubLayout";
import ReactQuill from "react-quill";
import { PlusOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import API_MANAGER from "../../API";
import axios from "axios";
// const { Dragger } = Upload;
import { useNavigate } from "react-router-dom";
function NewsFeedDetail() {
  const { id } = useParams();
  const { type } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [editFileList, setEditFileList] = useState([]);
  const [fileListCover, setFileListCover] = useState([]);
  const [editFileListCover, setEditFileListCover] = useState([]);

  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [imageRatio, setImageRatio] = useState({ width: 0, height: 0 });
  const [coverRatio, setCoverRatio] = useState({ width: 0, height: 0 });
  const [buttonLiked, setButtonLiked] = useState(false);
  const [loading, setLoading] = useState(false);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewOpen(true);
    setPreviewImage(file.url || file.preview);
  };
  const handleCancel = () => setPreviewOpen(false);
  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setEditFileList(newFileList);
    let uploadedImage = "";
    if (newFileList[0]?.originFileObj) {
      uploadedImage = await getBase64(newFileList[0]?.originFileObj);
      const image = document.createElement("img");
      image.src = uploadedImage;
      setImageRatio({ width: image.naturalWidth, height: image.naturalHeight });
    } else {
      setImageRatio({ width: 0, height: 0 });
    }
  };
  const handleChangeCover = async ({ fileList: newFileList }) => {
    setFileListCover(newFileList);
    setEditFileListCover(newFileList);
    let uploadedImage = "";
    if (newFileList[0]?.originFileObj) {
      uploadedImage = await getBase64(newFileList[0]?.originFileObj);
      const image = document.createElement("img");
      image.src = uploadedImage;
      setCoverRatio({ width: image.naturalWidth, height: image.naturalHeight });
    } else {
      setCoverRatio({ width: 0, height: 0 });
    }
  };

  const props = {
    name: "file",
    accept: "image/png, image/jpeg, image/jpg",
    multiple: false,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const handleSubmit = async (values) => {
    const dataBanner = {
      extension: fileList?.length > 0 ? fileList[0]?.type?.split("/")[1] : "",
      type: "NEWS",
      contentType: fileList?.length > 0 ? fileList[0]?.type : "",
    };
    const dataCover = {
      extension:
        fileListCover?.length > 0 ? fileListCover[0]?.type?.split("/")[1] : "",
      type: "NEWS",
      contentType: fileListCover?.length > 0 ? fileListCover[0]?.type : "",
    };
    try {
      if (
        fileList?.length === 0 &&
        !data.image &&
        fileListCover?.length === 0 &&
        !data.coverImage
      ) {
        message.warning("Please upload banner and cover");
        return;
      }
      if (fileList?.length === 0 && !data.image) {
        message.warning("Please upload banner");
        return;
      }
      if (fileListCover?.length === 0 && !data.coverImage) {
        message.warning("Please upload cover");
        return;
      }
      setLoading(true);
      if (type === "edit") {
        let temp = {
          ...values,
          ["id"]: id,
        };
        if (fileList?.length > 0) {
          const responseBanner = await API_MANAGER.fileUpload(dataBanner);
          await axios.put(
            responseBanner?.data?.data?.url,
            fileList[0]?.originFileObj
          );
          temp["image"] = responseBanner?.data?.data
            ? responseBanner?.data?.data?.key
            : "";
        }
        if (fileListCover?.length > 0) {
          const responseCover = await API_MANAGER.fileUpload(dataCover);
          await axios.put(
            responseCover?.data?.data?.url,
            fileListCover[0]?.originFileObj
          );
          temp["coverImage"] = responseCover?.data?.data
            ? responseCover?.data?.data?.key
            : "";
        }
        await API_MANAGER.EditNews(temp).then(() => {
          message.success("Edited successfully");
        });
      } else {
        const responseBanner = await API_MANAGER.fileUpload(dataBanner);
        await axios.put(
          responseBanner?.data?.data?.url,
          fileList[0]?.originFileObj
        );
        const responseCover = await API_MANAGER.fileUpload(dataCover);
        await axios.put(
          responseCover?.data?.data?.url,
          fileListCover[0]?.originFileObj
        );
        const temp = {
          ...values,
          ["id"]: id,
          ["image"]: responseBanner?.data?.data?.key,
          ["coverImage"]: responseCover?.data?.data
            ? responseCover?.data?.data?.key
            : "",
        };

        await API_MANAGER.createNews(temp).then(() => {
          message.success("Created successfully");
        });
      }
      setLoading(false);

      navigate("/feed-management");
    } catch (error) {
      message.error("Something went wrong");
      setLoading(false);
    }
  };
  const getData = async () => {
    if (type == "view" || type == "edit") {
      await API_MANAGER.getNewsFeedDetail(id)
        .then(async (res) => {
          setData(res?.data?.data);
          form.setFieldValue("heading", res?.data?.data?.heading);
          setEditFileList((prevFileList) => [
            {
              ...prevFileList[0],
              url: res?.data?.data?.image,
            },
          ]);
          setEditFileListCover((prevFileList) => [
            {
              ...prevFileList[0],
              url: res?.data?.data?.coverImage,
            },
          ]);
          if (res?.data?.data?.likes > 0) {
            setIsLiked(true);
          }
          form.setFieldValue(
            "shortDescription",
            res?.data?.data?.shortDescription
          );
          form.setFieldValue("summary", res?.data?.data?.summary);
          if (res?.data?.data?.image) {
            const image = document.createElement("img");
            image.src = res?.data?.data?.image;
            image.onload = function () {
              const width = image.naturalWidth;
              const height = image.naturalHeight;
              setImageRatio({
                width: width,
                height: height,
              });
            };
          }
          if (res?.data?.data?.coverImage) {
            const image = document.createElement("img");
            image.src = res?.data?.data?.coverImage;
            image.onload = function () {
              const width = image.naturalWidth;
              const height = image.naturalHeight;

              setCoverRatio({
                width: width,
                height: height,
              });
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  useEffect(() => {
    getData();
  }, []);
  return (
    <SubLayout page={`News Feed`} showBack>
      <div className="detail_container">
        {type === "view" ? (
          <Row className="newsDetail">
            <Col xs={24} className="container">
              <span className="title">Title:</span>
              <span className="titleValue">{" " + data.heading} </span>
            </Col>
            <Col xs={24} className="container">
              <span className="title">Short Description:</span>
              <p className="titleValue">{" " + data.shortDescription} </p>
            </Col>
            <Col xs={24} className="container">
              <span className="title">Description:</span>
              <div dangerouslySetInnerHTML={{ __html: data?.summary }} />
            </Col>
            <Col xs={4} className="container">
              <span className="title">Banner:</span>
              <p className="imageContainer">
                <Image src={data?.image} />
              </p>
            </Col>
            <Col xs={4} className="container">
              <span className="title">Cover:</span>
              <p className="imageContainer">
                <Image src={data?.coverImage} />
              </p>
            </Col>
          </Row>
        ) : (
          <Form
            layout="vertical"
            form={form}
            requiredMark={false}
            onFinish={(values) => {
              handleSubmit(values);
            }}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Title*"
                  name="heading"
                  rules={[
                    {
                      required: true,
                      message: "Please enter title",
                    },
                  ]}
                >
                  <Input
                    className=""
                    placeholder="Enter title"
                    disabled={type === "view"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Short Description*"
                  name="shortDescription"
                  rules={[
                    {
                      required: true,
                      message: "Please enter short description",
                    },
                  ]}
                >
                  <Input
                    className=""
                    placeholder="Enter short description"
                    disabled={type === "view"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Description*"
                  name="summary"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the description",
                    },
                  ]}
                >
                  <ReactQuill
                    theme="snow"
                    className="textBox"
                    disable={type === "view"}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <label>
                  <span>Banner*</span> <span>{"(1092px,1456px)"}</span>
                </label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please upload banner",
                    },
                  ]}
                >
                  <Upload
                    {...props}
                    listType="picture-card"
                    fileList={
                      type === "edit" || type === "view"
                        ? editFileList
                        : fileList
                    }
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList?.length >= 1 || editFileList?.length >= 1
                      ? null
                      : uploadButton}
                  </Upload>
                  <Modal
                    open={previewOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <Image
                      alt="example"
                      className="previewImage"
                      src={previewImage}
                    />
                  </Modal>
                  <span>
                    {`( ${imageRatio?.width}px , ${imageRatio?.height}px)`}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <label>
                  <span>Cover*</span> <span>{"(284px,179px)"}</span>
                </label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please upload cover",
                    },
                  ]}
                >
                  <Upload
                    {...props}
                    listType="picture-card"
                    fileList={
                      type === "edit" || type === "view"
                        ? editFileListCover
                        : fileListCover
                    }
                    onPreview={handlePreview}
                    onChange={handleChangeCover}
                  >
                    {fileListCover?.length >= 1 ||
                    editFileListCover?.length >= 1
                      ? null
                      : uploadButton}
                  </Upload>
                  <Modal
                    open={previewOpen}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <Image
                      alt="example"
                      className="previewImage"
                      src={previewImage}
                    />
                  </Modal>
                  <span>
                    {`( ${coverRatio?.width}px , ${coverRatio?.height}px)`}
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  name="likes"
                  rules={[
                    {
                      required: false,
                      message: "Please upload banner",
                    },
                  ]}
                >
                  {isLiked ? (
                    <Button
                      className="primaryBtn"
                      onClick={() => message.warning("Feed already liked.")}
                    >
                      Liked
                    </Button>
                  ) : (
                    <Button
                      className="primaryBtn"
                      onClick={() => {
                        form.setFieldsValue({ likes: !buttonLiked });
                        setButtonLiked((prev) => !prev);
                      }}
                    >
                      {buttonLiked ? "Liked" : "Like"}
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {type != "view" && (
              <Row gutter={24}>
                <Col span={24} className="buttonCol">
                  <Form.Item>
                    <Button
                      className="btn"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </div>
    </SubLayout>
  );
}

export default NewsFeedDetail;
