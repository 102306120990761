import React, { useState } from "react";
import { Row, Col, Form, Input, Button, message, Image } from "antd";
import MailIcon from "../../Assets/login/mail.svg";
import API_MANAGER from "../../API";
import OTP from "../../components/common/forgot/OTP";
import NewPassword from "../../components/common/forgot/NewPassword";
import { useNavigate } from "react-router-dom";
function Forgot() {
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerify, setOtpVerified] = useState(false);
  const [token, setToken] = useState(false);

  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const ForgotPassword = async (values) => {
    try {
      setLoading(true);
      setEmail(values?.email);
      await API_MANAGER.forgotPassword(values);
      setLoading(false);
      setOtpSent(true);
      message.success("OTP sent to your mail.");
    } catch (err) {
      if (err?.response?.data?.message?.message) {
        message.warning(err?.response?.data?.message?.message);
      } else {
        message.warning("Something went wrong, please try again!");
      }

      setLoading(false);
    }
  };
  return (
    <div className="login_main_container">
      <div className="login_container">
        {!otpVerify ? (
          <>
            {!otpSent ? (
              <>
                <Row>
                  <Col span={12} className="login-form-container">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      <Image src="/assets/login/banner.svg" preview={false} />
                    </div>
                    <div className="form-outer-div">
                      <div className="form-inner-div">
                        <p className="heading1 forgot-text">Forgot Password</p>
                        <p className="subHeading1">
                          To reset your password, please enter the email used to
                          register your account.
                        </p>

                        <Form
                          form={form}
                          onFinish={ForgotPassword}
                          layout="vertical"
                        >
                          <Row
                            style={{
                              marginBottom: "18px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Col span={24}>
                              <Form.Item
                                name="email"
                                label={
                                  <span className="label-items">Email*</span>
                                }
                                required={false}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter your email!",
                                  },
                                  {
                                    type: "email",
                                  },
                                ]}
                              >
                                <Input
                                  type="email"
                                  placeholder="Email"
                                  prefix={<img src={MailIcon} alt="mail" />}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Button
                                  htmlType="submit"
                                  style={{ width: "100%" }}
                                  className="submitBtn"
                                  loading={loading}
                                >
                                  Submit
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </div>
                  </Col>
                  <Col span={12} className="banner-image">
                    <Image
                      src="/assets/login/login-banner.png"
                      preview={false}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <OTP
                email={email}
                setOtpVerified={setOtpVerified}
                setToken={setToken}
              />
            )}
          </>
        ) : (
          <NewPassword email={email} token={token} />
        )}
      </div>
    </div>
  );
}

export default Forgot;
