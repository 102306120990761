import React, { useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import {
  Row,
  Col,
  Table,
  Modal,
  Input,
  Form,
  message,
  Button,
  Tooltip,
  Select,
} from "antd";
import DeleteIcon from "../../Assets/delete.svg";
import EditIcon from "../../Assets/editIcon.svg";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import API_MANAGER from "../../API";
import { DeleteCandidate } from "../../components/common/Popup";
// import CustomPagination from "../../components/common/CustomPagination";

function PickListDetail() {
  const { state } = useLocation();
  const [edit_detail, setEditDetail] = useState(false);
  const [Id, setId] = useState("");
  const [data, setData] = useState([]);
  const [fundUsi, setFundUsi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('name');
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [createDetail, setCreateDetail] = useState(false);
  const [rowData, setRowData] = useState({});
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [search, setSearch] = useState(null);
  // const [page, setPage] = useState(1);

  const userType = localStorage.getItem("userType");

  const column = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (item, row, index) => {
        return (
          <span className="cursor-pointer">{10 * (1 - 1) + index + 1}</span>
        );
      },
    },
    {
      title: "Picklist Value's",
      dataIndex: "name",
      filters: [
        {
          text: "A-Z",
          value: "name",
        },
        {
          text: "Z-A",
          value: "-name",
        },
        {
          text: "Latest",
          value: "-createdAt",
        },
        {
          text: "Oldest",
          value: "createdAt",
        },
      ],
      defaultFilteredValue: ["name"],
      filterMultiple: false,
      onFilter: (value, record) => {
        setFilter(value);
        return record;
      },
    },
    {
      title: "Action",
      dataIndex: "delete",
      render: (_, row) => (
        <>
          <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
            <span>
              {userType === "admin" ? (
                <img
                  style={{ width: "35px" }}
                  src={EditIcon}
                  alt="edit"
                  className="cursor-pointer"
                  onClick={() => {
                    setEditDetail(true);
                    setRowData(row);
                    setId(row._id);
                  }}
                />
              ) : (
                <img
                  style={{ width: "35px" }}
                  src={EditIcon}
                  alt="edit"
                  className="cursor-pointer"
                />
              )}
            </span>
            <span>
              {userType === "admin" ? (
                <img
                  src={DeleteIcon}
                  alt="delete"
                  style={{ width: "35px" }}
                  className="cursor-pointer"
                  onClick={() => {
                    setId(row._id);
                    setDeleteModal(true);
                  }}
                />
              ) : (
                <img
                  src={DeleteIcon}
                  alt="delete"
                  style={{ width: "35px" }}
                  className="cursor-pointer"
                />
              )}
            </span>
          </Tooltip>
        </>
      ),
    },
  ];
  const deleteConfirm = async () => {
    try {
      setConfirmLoading(true);
      await API_MANAGER.deletePicklist(Id);
      message.success("Successfully deleted picklist value");
      getData();
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message || "Something went wrong"
      );
    }
    setConfirmLoading(false);
    setDeleteModal(false);
  };
  const handleUpdate = async (values) => {
    setLoading(true);
    try {
      if (createDetail) {
        let params = { type: state.title, name: values.name };
        if (state.title === "FUND_NAME") {
          params.correspondingValue = values.correspondingValue;
        }
        await API_MANAGER.createPickListTag(params);
        form1.resetFields();
        message.success("Successfully created picklist value");
        setCreateDetail(false);
      } else {
        await API_MANAGER.updatePickListTag(
          { tagId: Id, name: values.name },
          state.title
        );
        message.success("Successfully updated picklist value");
        setEditDetail(false);
      }
      getData();
    } catch (err) {
      message.error(err?.response?.data?.message?.message);
    }
    setLoading(false);
  };
  const getData = async () => {
    setLoading(true);
    try {
      if (state.title === "FUND_NAME") {
        const resp = await API_MANAGER.getPickListTags({
          type: "FUND_USI_ABN",
          search: search,
          sort: filter
        });
        setFundUsi(resp?.data?.data?.tags);
      }
      const response = await API_MANAGER.getPickListTags({
        type: state.title,
        search: search,
        sort: filter,
        limit: 1000,
      });
      setData(response?.data?.data?.tags);
    } catch {
      message.error("Something went wrong");
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [search,filter]);
  useEffect(() => {
    if (rowData?.name) {
      form.setFieldValue("name", rowData?.name);
    }
  }, [rowData]);
  return (
    <SubLayout page="Picklist Detail View" showBack>
      <Row className="picklist_container">
        <Col xs={15}>
          <p className="heading">{state.heading}</p>
        </Col>
        <Col
          className="search_container"
          xs={5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Input.Search
            placeholder="Search"
            value={search}
            allowClear
            onChange={(e) => setSearch(e?.target?.value)}
          />
          {/* <p className="heading">{state.heading}</p> */}
        </Col>
        <Col
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="bulk_button"
            onClick={() => {
              setCreateDetail(true);
            }}
          >
            Create Picklist Value
          </Button>
        </Col>
        <Col xs={20} className="detail_container">
          <Row>
            <Col xs={24} className="table_container">
              <Table
                columns={column}
                dataSource={data}
                pagination={false}
                loading={loading}
                scroll={{
                  y: "calc(100vh - 400px)",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title={"Edit Value"}
        open={edit_detail}
        onCancel={() => {
          setEditDetail(false);
        }}
        centered
        footer={false}
      >
        <Form form={form} layout="vertical" onFinish={handleUpdate}>
          <Row>
            <Col xs={24}>
              <Form.Item name="name">
                <Input className="input" placeholder="Enter picklist value" />
              </Form.Item>
              <Col md={24} className="buttonCol">
                <Form.Item>
                  <Button
                    className="btn"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title={"Create Value"}
        open={createDetail}
        onCancel={() => {
          setCreateDetail(false);
        }}
        centered
        footer={false}
      >
        <Form form={form1} layout="vertical" onFinish={handleUpdate}>
          <Row>
            <Col xs={24} className="passwordInput">
              <Form.Item name="name">
                <Input className="input" placeholder="Enter picklist value" />
              </Form.Item>
              {state.title === "FUND_NAME" && (
                <Form.Item name="correspondingValue">
                  <Select placeholder="Fund USI ABN value">
                    {fundUsi.map((e, key) => {
                      return (
                        <Select.Option key={key} value={e.id}>
                          {e.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              <Col md={24} className="buttonCol">
                <Form.Item>
                  <Button
                    className="btn"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Form>
      </Modal>
      <DeleteCandidate
        confirmDelete={deleteConfirm}
        visible={openDeleteModal}
        text={"Picklist Value"}
        setVisible={setDeleteModal}
        loading={confirmLoading}
      />
    </SubLayout>
  );
}
export default PickListDetail;
