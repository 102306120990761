import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../components/layout";
import Dashboard from "../pages/dashboard";
import UserManagement from "../pages/user-management/UserManagement";
import UserDetail from "../pages/user-management/UserDetail";
import CandidateList from "../pages/candidate-management/CandidateList";
import CandidateDetails from "../pages/candidate-management/CandidateDetails";
import RecruiterList from "../pages/client-management/RecruiterList";
import RecruiterDetail from "../pages/client-management/RecruiterDetail";
import CampaignList from "../pages/campaign-management/CampaignList";
import CampaignAdvertDetail from "../pages/campaign-management/AdvertList";
import AdvertDetail from "../pages/campaign-management/AdvertDetail";
import CampaignCreateUpdate from "../pages/campaign-management/CampaignDetail";
import FeedManagement from "../pages/feed-management";
import JobsApplied from "../pages/candidate-management/JobsApplied";
import TransactionDetail from "../pages/transaction-details";
import NewsFeedDetail from "../pages/feed-management/NewsFeedDetail";
import Support from "../pages/support";
import Picklist from "../pages/settings/picklist";
import PickListDetail from "../pages/settings/picklistDetail";
import Subscription from "../pages/subscription/subscription";
import SubscriptionHistory from "../pages/subscription-history/index.js";
import CreateUpdateSubs from "../pages/subscription/subscription_create";
import ChangePassword from "../pages/settings/changePassword";
import AboutUs from "../pages/about-us";
import PrivacyPolicy from "../pages/privacypolicy";
import TermsAndConditions from "../pages/terms-conditions";
import SupportDetail from "../pages/support/supportDetail";
import ErrorPage from "../components/common/ErrorPage";
import ManualNotification from "../pages/settings/manualNotification";

const CustomRoutes = () => {
  return (
    <Layout>
      <Routes>
        {/* Dashboard Route */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />

        {/* User Management Route */}
        <Route path="/user-management" element={<UserManagement />} />
        <Route
          path="/user-management/user-detail/:type"
          element={<UserDetail />}
        />
        <Route
          path="/user-management/user-detail/:type/:id"
          element={<UserDetail />}
        />

        {/* Candidate Management Route */}
        <Route path="/candidate-list" element={<CandidateList />} />
        <Route
          path="/candidate-list/candidate-detail/:id/:type"
          element={<CandidateDetails />}
        />

        {/* Client Management Route */}
        <Route path="/client-list" element={<RecruiterList />} />
        <Route
          path="/client-list/client-detail/:type"
          element={<RecruiterDetail />}
        />
        <Route
          path="/client-list/client-detail/:type/:id"
          element={<RecruiterDetail />}
        />

        {/* Campaign Management Route */}
        <Route path="/campaign-list" element={<CampaignList />} />
        <Route
          path="/campaign-list/campaign-detail/:type"
          element={<CampaignCreateUpdate />}
        />
        <Route
          path="/campaign-list/campaign-detail/:type/:id"
          element={<CampaignCreateUpdate />}
        />

        {/* Advert Management Route */}
        <Route
          path="/campaign-list/advert-list/:id"
          element={<CampaignAdvertDetail />}
        />
        <Route
          path="/campaign-list/advert-detail/:id"
          element={<AdvertDetail />}
        />

        {/* Jobs Route */}
        <Route path="/jobs-applied/:id" element={<JobsApplied />} />

        {/* Transaction Route */}
        <Route path="/transaction-details" element={<TransactionDetail />} />

        {/* Subscription Route */}
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/subscription/:type" element={<CreateUpdateSubs />} />
        <Route path="/subscription-history" element={<SubscriptionHistory />} />

        {/* News Feed Route */}
        <Route path="/feed-management" element={<FeedManagement />} />
        <Route path="/feed-management/:type" element={<NewsFeedDetail />} />
        <Route path="/feed-management/:type/:id" element={<NewsFeedDetail />} />

        {/* Support Route */}
        <Route path="/support" element={<Support />} />
        <Route path="/support/support-detail" element={<SupportDetail />} />

        {/* Settings Picklist Route */}
        <Route path="/picklist" element={<Picklist />} />
        <Route
          path="/picklist/picklist-detail/:type"
          element={<PickListDetail />}
        />
        {/* Settings Change Password Route */}
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/manual-notification" element={<ManualNotification />} />

        <Route path="/about" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Layout>
  );
};

export default CustomRoutes;
