import React, { useState, useEffect } from "react";
import { Row, Col, message } from "antd";
import { useParams } from "react-router-dom";
import SubLayout from "../../components/layout/SubLayout";
import JobCard from "../../components/common/candidate/JobCard";
import API_MANAGER from "../../API";
function JobsApplied() {
  const { id } = useParams();
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const response = await API_MANAGER.getAppliedJobs(id);
      setData(response?.data?.data);
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    }
  };
  useEffect(() => {
    getData();
  }, [id]);
  const JobData = [
    {
      key: 1,
      companyName: "XYZ Company Pvt Ltd ",
      projectName: "Will CRO",
      location: "USA",
      jobTitle: "Software developer",
      salary: "50,000 - 123,887 P.A",
      roster: "1-3 Years",
      jobDuration: "2 years",
      employmentType: "Full time",
      closeDate: "22-12-2023",
    },
    {
      key: 1,
      companyName: "XYZ Company Pvt Ltd ",
      projectName: "Will CRO",
      location: "USA",
      jobTitle: "Software developer",
      salary: "50,000 - 123,887 P.A",
      roster: "1-3 Years",
      jobDuration: "2 years",
      employmentType: "Full time",
      closeDate: "22-12-2023",
    },
    {
      key: 1,
      companyName: "XYZ Company Pvt Ltd ",
      projectName: "Will CRO",
      location: "USA",
      jobTitle: "Software developer",
      salary: "50,000 - 123,887 P.A",
      roster: "1-3 Years",
      jobDuration: "2 years",
      employmentType: "Full time",
      closeDate: "22-12-2023",
    },
    {
      key: 1,
      companyName: "XYZ Company Pvt Ltd ",
      projectName: "Will CRO",
      location: "USA",
      jobTitle: "Software developer",
      salary: "50,000 - 123,887 P.A",
      roster: "1-3 Years",
      jobDuration: "2 years",
      employmentType: "Full time",
      closeDate: "22-12-2023",
    },
    {
      key: 1,
      companyName: "XYZ Company Pvt Ltd ",
      projectName: "Will CRO",
      location: "USA",
      jobTitle: "Software developer",
      salary: "50,000 - 123,887 P.A",
      roster: "1-3 Years",
      jobDuration: "2 years",
      employmentType: "Full time",
      closeDate: "22-12-2023",
    },
    {
      key: 1,
      companyName: "XYZ Company Pvt Ltd ",
      projectName: "Will CRO",
      location: "USA",
      jobTitle: "Software developer",
      salary: "50,000 - 123,887 P.A",
      roster: "1-3 Years",
      jobDuration: "2 years",
      employmentType: "Full time",
      closeDate: "22-12-2023",
    },
    {
      key: 1,
      companyName: "XYZ Company Pvt Ltd ",
      projectName: "Will CRO",
      location: "USA",
      jobTitle: "Software developer",
      salary: "50,000 - 123,887 P.A",
      roster: "1-3 Years",
      jobDuration: "2 years",
      employmentType: "Full time",
      closeDate: "22-12-2023",
    },
    {
      key: 1,
      companyName: "XYZ Company Pvt Ltd ",
      projectName: "Will CRO",
      location: "USA",
      jobTitle: "Software developer",
      salary: "50,000 - 123,887 P.A",
      roster: "1-3 Years",
      jobDuration: "2 years",
      employmentType: "Full time",
      closeDate: "22-12-2023",
    },
  ];
  console.log(data);
  return (
    <SubLayout page="Jobs Applied" showBack>
      <div className="applied_jobs_container">
        <Row gutter={[16, 16]}>
          {JobData?.map((item, index) => (
            <Col xs={24} md={12} lg={8} xxl={6} key={index}>
              <JobCard data={item} />
            </Col>
          ))}
        </Row>
      </div>
    </SubLayout>
  );
}

export default JobsApplied;
