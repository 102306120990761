import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Image, Progress } from "antd";
import { useNavigate, useParams } from "react-router-dom";
// import Cookies from "js-cookie";
import AlertImg from "../../Assets/login/alert.svg";
import LoaderGif from "../../Assets/loader.gif";
import API_MANAGER from "../../API";
function SetPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [form] = Form.useForm();
  const { token } = useParams();
  const LoginUser = async (values) => {
    try {
      setLoading(true);
      await API_MANAGER.setPassword(values, token);
      message.success("Password successfully set");
      setLoading(false);
      navigate(`/login`);
    } catch (err) {
      if (err?.response?.data?.message?.message) {
        message.warning(err?.response?.data?.message?.message);
      } else {
        message.warning("Something went wrong, please try again!");
      }
      setLoading(false);
    }
  };

  const validatePassword = (e) => {
    setProgressPercentage(0);
    if (e?.match(/[A-Z]/) || e?.match(/[a-z]/) || e?.match(/[1-9]/)) {
      setProgressPercentage(25);
    }
    if (
      (e?.match(/[a-z]/) && e?.match(/[A-Z]/)) ||
      (e?.match(/[a-z]/) && e?.match(/[1-9]/)) ||
      (e?.match(/[1-9]/) && e?.match(/[A-Z]/))
    ) {
      setProgressPercentage(50);
    }
    if (e?.match(/[1-9]/) && e?.match(/[a-z]/) && e?.match(/[A-Z]/)) {
      setProgressPercentage(75);
    }
    if (
      e?.length >= 8 &&
      e?.match(/[1-9]/) &&
      e?.match(/[a-z]/) &&
      e?.match(/[A-Z]/)
    ) {
      setProgressPercentage(100);
    }
  };
  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword) {
      setPasswordNotMatch(false);
    } else if (
      password?.length > 0 &&
      confirmPassword?.length > 0 &&
      password !== confirmPassword
    ) {
      setPasswordNotMatch(true);
    } else {
      setPasswordNotMatch(false);
    }
  }, [confirmPassword, password]);
  return (
    <>
      {loading ? (
        <div className="loaderContainer">
          <img src={LoaderGif} alt="" />
        </div>
      ) : (
        <Row className="login_main_container">
          <Col className="login_container">
            <Row>
              {/* login form section */}
              <Col span={12} className="login-form-container">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <Image src="/assets/login/banner.svg" preview={false} />
                </div>
                {/* login form start */}
                <Row className="form-outer-div">
                  <Col xs={24} className="headingCol">
                    <p className="heading">Change Password</p>
                  </Col>
                  <Col xs={24} className="headingCol">
                    <p className="innerHeading">Set your new password below.</p>
                  </Col>
                  <Col className="form-inner-div">
                    <Form onFinish={LoginUser} form={form} layout="vertical">
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            name="password"
                            autoComplete="false"
                            label={
                              <span className="label-items">New Password*</span>
                            }
                            required={false}
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },
                              {
                                type: "password",
                              },
                            ]}
                          >
                            <Input.Password
                              autoComplete="new-password"
                              placeholder="New password"
                              type="password"
                              onChange={(e) => {
                                validatePassword(e?.target?.value);
                                setPassword(e?.target?.value);
                              }}
                              style={{
                                background: "transparent",
                                color: "white",
                                border: "1px solid white",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        {progressPercentage ? (
                          <Row align={"middle"} gutter={16}>
                            <Col
                              className="progressbar_margin"
                              style={{
                                width: "209px",
                              }}
                            >
                              <Progress
                                percent={progressPercentage}
                                className="formProgressBar"
                                showInfo={false}
                              />
                            </Col>
                            <Col className="progressbar_margin">
                              {progressPercentage < 100 ? (
                                <span className="weakText">Weak</span>
                              ) : (
                                <span className="strongText">Strong</span>
                              )}
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Row>
                      <div>
                        {progressPercentage > 0 &&
                          progressPercentage !== 100 && (
                            <Row>
                              <div className="errorDescBox">
                                Your password must contain at least:{" "}
                                <span>
                                  8 Characters, 1 Upper Case Letter, 1 Lower
                                  Case Letter, 1 Number
                                </span>
                              </div>
                            </Row>
                          )}
                      </div>

                      <Row>
                        <Col span={24} style={{ marginTop: "5px" }}>
                          <Form.Item
                            label={
                              <span className="label-items">
                                Confirm Password*
                              </span>
                            }
                            name="confirmPassword"
                            required={false}
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },
                            ]}
                          >
                            <Input.Password
                              autoComplete="new-password"
                              placeholder="Confirm password"
                              type="password"
                              onChange={(e) =>
                                setConfirmPassword(e?.target?.value)
                              }
                              style={{
                                background: "transparent",
                                color: "white",
                                border: "1px solid white",
                              }} // Set the input background to transparent
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {passwordNotMatch ? (
                        <Row align={"middle"} gutter={8} className="alertMsg">
                          <Col>
                            <img src={AlertImg} alt="alert" />
                          </Col>
                          <Col>
                            <span>Passwords doesn’t match</span>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <Row>
                        <Col span={24}>
                          <Form.Item>
                            <Button
                              htmlType="submit"
                              style={{ width: "100%" }}
                              className="submitBtn"
                            >
                              Change Password
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Col>
              {/* banner image section */}
              <Col span={12} className="banner-image">
                <Image src="/assets/login/login-banner.png" preview={false} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}

export default SetPassword;
