import React from "react";
import { Row, Col, Card, Tooltip } from "antd";
import ActiveUser from "../../../Assets/dashboard/user.svg";
import NewUser from "../../../Assets/dashboard/newUser.svg";
import TotalUser from "../../../Assets/dashboard/user.svg";
import Advert from "../../../Assets/dashboard/advert.svg";
import DownloadIcon from "../../../Assets/dashboard/download.svg";
import GrowthIcon from "../../../Assets/dashboard/greenUp.svg";
import RedIcon from "../../../Assets/dashboard/redDown.svg";

function CustomCards(props) {
  let Img = ActiveUser;
  if (props.growth === "activeuser") {
    Img = ActiveUser;
  } else if (props.cardImage === "newuser") {
    Img = NewUser;
  } else if (props.cardImage === "totaluser") {
    Img = TotalUser;
  } else if (props.cardImage === "advert") {
    Img = Advert;
  }
  const userType = localStorage.getItem("userType");
  return (
    <div>
      <Card className="dashboardCard" style={{ height: "160px" }}>
        <Row align={"top"}>
          <Col xs={24}>
            <Col>
              <Col xs={24}>
                <h1 className="Cardheading">{props?.heading}</h1>
              </Col>
              <Row>
                <Col xs={20} className="cardContent">
                  {props?.amount}{" "}
                  <span>
                    <img
                      style={{ height: "20px" }}
                      src={
                        props?.growth !== undefined
                          ? props?.growth
                            ? GrowthIcon
                            : RedIcon
                          : ""
                      }
                    />
                  </span>
                </Col>
                <Col
                  xs={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img className="" src={Img} style={{ height: "30px" }} />
                </Col>
              </Row>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                {props?.download && (
                  <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
                    <img
                      className="cursor-pointer"
                      src={DownloadIcon}
                      alt={"downloadIcon"}
                      onClick={() => {
                        userType === "admin"
                          ? props.handleFunction(
                              props.reportType,
                              props?.heading
                            )
                          : "";
                      }}
                    />
                  </Tooltip>
                )}
              </Col>
            </Col>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default CustomCards;
