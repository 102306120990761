import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Button, Col, Input, Radio, Row, Table, Form, message } from "antd";
import moment from "moment";
import API_MANAGER from "../../API";
import CustomPagination from "../../components/common/CustomPagination";
const { TextArea } = Input;

const ManualNotification = () => {
  const [form] = Form.useForm();
  const [value, setValue] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onFinish = async (values) => {
    try {
      let arr = [];
      if (values.receiverNum === 2) {
        arr = [0, 1];
      } else {
        arr = [values.receiverNum];
      }
      values.receiver = arr;
      values.type = "CUSTOM";
      values.sender = "ADMIN";
      await API_MANAGER.sendBulkNotification(values);
      message.success("Successfully sent notification");
      setPage(1);
      getData(1);
      form.setFieldValue("message", "");
      form.setFieldValue("receiverNum", null);
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "sno",
      width: "80px",
      render: (item, _, index) => <span> {10 * (page - 1) + index + 1}</span>,
    },
    {
      title: "Platform",
      dataIndex: "receiverType",
      width: "200px",
    },
    {
      title: "Notification Description",
      dataIndex: "message",
      width: "600px",
      render: (text) => {
        return (
          <span
            style={{
              display: "flex",
              flexWrap: "wrap",
              wordBreak: "break-word",
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Sent Date",
      dataIndex: "createdAt",
      render: (item) => <span>{moment(item).format("DD/MM/YYYY")}</span>,
    },
  ];
  const getData = async () => {
    try {
      const response = await API_MANAGER.getManualNotification(page);
      setData(response?.data?.data);
      console.log(data);
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <SubLayout page={"Manual Notification"}>
      <div className="manualNotificationMainDiv">
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
        >
          <Row className="noti_section">
            <Col span={24}>Notification</Col>
            <Col span={12} className="noti_textArea">
              <Form.Item
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Message is required.",
                  },
                  { max: 150, message: "Max length should be 150 characters." },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="Max length is 150 characters"
                  // maxLength={150}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="radio_btn_sec">
            <Col span={6}>
              <Form.Item
                name="receiverNum"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one receiver.",
                  },
                ]}
              >
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={1}>Client</Radio>
                  <Radio value={0}>Candidate</Radio>
                  <Radio value={2}>Both</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6} align={"right"}>
              <Button className="black_color_button" htmlType="submit">
                Send
              </Button>
            </Col>
          </Row>
        </Form>

        <Table
          className="table_section"
          dataSource={data?.manualNotifications}
          bordered
          columns={columns}
          pagination={false}
          scroll={{
            y: "calc(100vh - 500px)",
          }}
        />
        <div className="w-100">
          <CustomPagination
            currentPage={page}
            setCurrentPage={setPage}
            total={data?.count}
            itemPerPage={10}
          />
        </div>
      </div>
    </SubLayout>
  );
};
export default ManualNotification;
