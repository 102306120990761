import React, { useEffect, useState } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Row, Col, Form, Button, message, Tooltip } from "antd";
import ReactQuill from "react-quill";
import { EditFilled } from "@ant-design/icons";
import API_MANAGER from "../../API";
function PrivacyPolicy() {
  const [form] = Form.useForm();
  const [type, setType] = useState("view");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      values.heading = "Privacy Policy";
      await API_MANAGER.createPrivacyPolicy(values);
      message.success("Successfully Updated Privacy Policy");
      setType("view");
      getData();
    } catch {
      message.error("Something Went Wrong");
    }
    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getPrivacyPolicy();
      setData(response?.data?.data);
      form.setFieldsValue({
        heading: response?.data?.data?.heading,
        description: response?.data?.data?.description,
      });
    } catch {
      message.error("Something Went Wrong");
    }
    setLoading(false);
  };
  const userType = localStorage.getItem("userType");
  useEffect(() => {
    getData();
  }, []);
  return (
    <SubLayout page={`Privacy Policy`}>
      {type !== "edit" ? (
        <div className="detail_container">
          <Row justify={"end"}>
            <Col>
              <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
                <EditFilled
                  className="editIcon"
                  onClick={() => {
                    userType === "admin" ? setType("edit") : "";
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <p className="title">Description:</p>
              <div
                className="titleValue"
                dangerouslySetInnerHTML={{ __html: data?.description }}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <div className="detail_container">
          <Form
            layout="vertical"
            form={form}
            requiredMark={false}
            onFinish={(values) => {
              handleSubmit(values);
            }}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Description*"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the description",
                    },
                  ]}
                >
                  <ReactQuill
                    theme="snow"
                    className="textBox reactQuillBox"
                    disable={type === "view"}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            {type != "view" && (
              <Row gutter={24} style={{ marginTop: "36px" }}>
                <Col span={24} className="buttonCol">
                  <Col span={2}>
                    <Button onClick={() => setType("view")}>Cancel</Button>
                  </Col>
                  <Form.Item>
                    <Button
                      className="btn"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      )}
    </SubLayout>
  );
}
export default PrivacyPolicy;
