import { Button, Col, Input, Modal, Row, message } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API_MANAGER from "../../API";
function ValidationModal({ validateModal, setValidateModal, setIsValidate }) {
  const [passwordText, setPasswordText] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleOk = () => {
    // setValidateModal(false);
    // setIsValidate(true);
    const values = {
      email: localStorage.getItem("email"),
      password: passwordText,
    };
    LoginUser(values);
  };
  const handleCancel = () => {
    setValidateModal(false);
    backUrlFunction();
  };
  const backUrlFunction = () => {
    navigate("/candidate-list", {
      state: {
        page: state?.page,
        filter: state?.filter,
        search: state?.search,
        path: state?.path,
      },
    });
  };
  const LoginUser = async (values) => {
    try {
      values;
      await API_MANAGER.login(values);
      setValidateModal(false);
      setIsValidate(true);
    } catch (err) {
      if (err?.response?.data?.message?.message) {
        message.warning(
          err?.response?.data?.message?.message ||
            "Invalid password, please enter valid password."
        );
      } else {
        message.warning("Something went wrong, please try again!");
      }
    }
  };
  return (
    <div>
      <Modal
        title="Password Validation"
        open={validateModal}
        getContainer="#modalMount"
        className="blur_background"
        centered
        closable={false}
        maskClosable={false}
        footer={[
          <Row key={"footer_key"}>
            <Col span={24} align={"right"} className="justify_items_end">
              <Button className="cancel_btn" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                key="submit"
                className="black_color_button"
                onClick={handleOk}
              >
                Validate
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row className="passwordInput">
          <Input.Password
            placeholder="Enter Password"
            onChange={(e) => setPasswordText(e.target.value)}
          />
        </Row>
      </Modal>
    </div>
  );
}
export default ValidationModal;
