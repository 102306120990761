import React from "react";
import { Col, Row, Modal } from "antd";
import YellowImage from "../../Assets/campaign/yellow_circle.svg";
import YellowImageHalf from "../../Assets/campaign/yellow_circle_half.svg";
import LeftIcon from "../../Assets/campaign/leftIcon.svg";
import WalletIcon from "../../Assets/campaign/walletIcon.svg";
import GreenTick from "../../Assets/campaign/greenTick.svg";
import dpImage from "../../Assets/subscription/standard_card_image.svg";
function PreviewSubscription({ visible, setVisible, previewData }) {
  const numberWithCommas = (number) => {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
  return (
    <>
      {previewData.subscriptionFor !== "Client" ? (
        <Modal
          centered
          onCancel={() => setVisible(false)}
          open={visible}
          footer={null}
          closable={false}
          className="PhoneViewModalMainContainer1"
        >
          <Col xs={24} className="PhoneViewModalMainContainer1">
            <Row>
              <Col className="innerContainer">
                <div className="phone_modal_main_content_row">
                  <img src={YellowImage} className="yellow_icon" />
                  <img src={YellowImageHalf} className="yellow_icon_half" />
                  <Row className="titleMainRow">
                    <Col xs={1}>
                      <img src={LeftIcon} />
                    </Col>
                    <Col xs={23} align={"center"}>
                      <span>My Subscription</span>
                    </Col>
                  </Row>
                  <Row className="titleRow">
                    <Col xs={24} className="titleColumn">
                      <Row>
                        <Col className="wallet">
                          <img src={WalletIcon} className="walletIcon" />
                        </Col>
                        <Col xs={1} />
                        <Col xs={17}>
                          <Col>
                            <p className="title">{previewData.name}</p>
                          </Col>
                          <Col className="titleDesc">
                            {previewData?.default_price
                              ? "$" +
                                numberWithCommas(previewData?.default_price)
                              : ""}
                            {previewData?.recurringType
                              ? "/" + previewData?.recurringType
                              : ""}
                          </Col>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="content_main_div">
                    <Col xs={24}>
                      <Col xs={24} className="content_row">
                        <Col xs={24}>
                          <p className="title">{previewData.name}</p>
                        </Col>
                        <Col xs={24} className="titleDesc">
                          {previewData?.description}
                        </Col>
                        <Col xs={24} style={{ margin: "20px 0px 20px 0px" }}>
                          <span className="priceSpan">
                            {previewData?.default_price
                              ? "$" +
                                numberWithCommas(previewData?.default_price)
                              : ""}
                          </span>
                          <span className="recurrType">
                            {previewData?.recurringType
                              ? "/" + previewData?.recurringType
                              : ""}
                          </span>
                        </Col>
                        <Col xs={24}>
                          {previewData?.feature?.map((e, key) => {
                            return (
                              <Col
                                xs={24}
                                key={key}
                                style={{ marginBottom: "10px" }}
                              >
                                <Row>
                                  <Col xs={3}>
                                    <img src={GreenTick} />
                                  </Col>
                                  <Col xs={20} className="bulletPoint">
                                    {e.name}
                                  </Col>
                                </Row>
                              </Col>
                            );
                          })}
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Modal>
      ) : (
        <Modal
          centered
          onCancel={() => setVisible(false)}
          open={visible}
          footer={null}
          closable={false}
          className="PhoneViewModalClient1"
        >
          {" "}
          <div className="PhoneViewModalClient1">
          <Row
            justify={"center"}
            className="standard_plan_card_container"
            style={{ background: "#F8F9FF", border: "1px solid #122D8E" }}
          >
            <Col xs={24}>
              <Row gutter={12}>
                <Col>
                  <img src={dpImage} className="dpImage" />
                </Col>
                <Col>
                  <Col xs={24}>
                    <p className="title">{previewData.name}</p>
                  </Col>
                  <Col xs={24} style={{ marginTop: "10px" }}>
                    <p className="title">
                      {" "}
                      {previewData?.default_price
                        ? "$" + numberWithCommas(previewData?.default_price)
                        : ""}
                      {previewData?.recurringType
                        ? "/" + previewData?.recurringType
                        : ""}
                    </p>
                  </Col>
                </Col>
              </Row>
              <Row className="content_row">
                <Col >
                  {previewData?.feature?.map((e, key) => {
                    return (
                      <Col xs={24} key={key} style={{ marginBottom: "10px" }}>
                        <Row >
                          <Col xs={3}> 
                            <img src={GreenTick} />
                          </Col>
                          <Col xs={1}/>
                          <Col xs={20} className="bulletPoint">{e.name}</Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
          </div>
        </Modal>
      )}
    </>
  );
}

export default PreviewSubscription;
