import React, { useState, useEffect } from "react";
import SubLayout from "../../components/layout/SubLayout";
import { Row, Col, Table, Input, message, Button, Image, Tooltip } from "antd";
import { DeleteCandidate } from "../../components/common/Popup";
import API_MANAGER from "../../API";
import moment from "moment";
import DeleteIcon from "../../Assets/delete.svg";
import EditIcon from "../../Assets/editIcon.svg";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../components/common/CustomPagination";

function FeedManagement() {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const deleteConfirm = async () => {
    try {
      setConfirmLoading(true);
      await API_MANAGER.deleteNewsFeed(deleteId).then(() => {
        message.success("Successfully deleted news feed");
        setConfirmLoading(false);
        setDeleteModal(false);
        getData(search, 1);
      });
    } catch (error) {
      setConfirmLoading(false);

      message.error(
        error?.response?.data?.message?.message || "Something went wrong"
      );
    }
  };
  const userType = localStorage.getItem("userType");
  const columns = [
    {
      title: "No.",
      dataIndex: "id",
      width: '80px',
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer"> {10 * (page - 1) + index + 1}</span>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "heading",
      key: "heading",
      render: (item, row) => (
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate(`/feed-management/view/${row._id}`);
          }}
        >
          {item}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item, row) => (
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate(`/feed-management/view/${row._id}`);
          }}
        >
          {" "}
          {moment(item).format("DD/MM/YYYY")}{" "}
        </span>
      ),
    },
    {
      title: "Banner",
      dataIndex: "image",
      key: "image",
      render: (item) => <Image src={item} className="newsListImage" />,
    },
    {
      title: "Cover",
      dataIndex: "coverImage",
      key: "coverImage",
      render: (item) => <Image src={item} className="newsListImage" />,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, row) => (
        <>
          <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
            <span>
              <img
                className="cursor-pointer"
                src={EditIcon}
                alt="edit"
                style={{ width: "35px" }}
                onClick={() => {
                  userType === "admin"
                    ? navigate(`/feed-management/edit/${row._id}`)
                    : "";
                }}
              />{" "}
            </span>
            <span>
              {userType === "admin" ? (
                <img
                  src={DeleteIcon}
                  alt="delete"
                  style={{ width: "35px" }}
                  className="cursor-pointer"
                  onClick={() => {
                    setDeleteId(row._id);
                    setDeleteModal(true);
                  }}
                />
              ) : (
                <img
                  src={DeleteIcon}
                  alt="delete"
                  style={{ width: "35px" }}
                  className="cursor-pointer"
                />
              )}
            </span>
          </Tooltip>
        </>
      ),
    },
  ];
  const getData = async (search, page) => {
    try {
      setLoading(true);
      const response = await API_MANAGER.getNewsFeed(search, page);
      setData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
    }
  };
  useEffect(() => {
    getData(search, page);
  }, [search, page]);
  return (
    <SubLayout page="Feed Management List">
      <Row className="search_container" align={"middle"} gutter={32}>
        <Col>
          <Input.Search
            placeholder="Search"
            value={search}
            allowClear
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </Col>
        <Col className="btn_Col">
          <Button
            className="bulk_button"
            onClick={() => navigate(`/feed-management/create`)}
          >
            Add New Feed
          </Button>
        </Col>
      </Row>
      <Row className="table_container">
        <Table
          columns={columns}
          dataSource={data.result}
          pagination={false}
          className="w-100 table"
          rowKey={"id"}
          scroll={{
            y: "calc(100vh - 330px)",
          }}
          loading={loading}
          onChange={(e) => setPage(e?.current)}
        />
        <div className="w-100">
          <CustomPagination
            currentPage={page}
            setCurrentPage={setPage}
            total={data?.count}
            itemPerPage={10}
          />
        </div>
      </Row>
      <DeleteCandidate
        confirmDelete={deleteConfirm}
        visible={openDeleteModal}
        text={"News Feed"}
        setVisible={setDeleteModal}
        loading={confirmLoading}
      />
    </SubLayout>
  );
}
export default FeedManagement;
