import React from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import { useNavigate } from "react-router-dom";
// import API_MANAGER from "../../../API";

function CandidateDetails({ id, type, disable, prefilledData }) {
  const navigate = useNavigate();
  const createUser = async (payload) => {
    // setLoading(true);
    console.log(payload, id);
    try {
      // const response = await API_MANAGER.CreateRecruiterUser(payload);
      // setData(response?.data?.data);
      // setLoading(false);
    } catch (error) {
      message.error("Something went wrong!");
      // setLoading(false);
    }
  };
  const [form1] = Form.useForm();
  const recruterProfileOnFinish = async (values) => {
    console.log(values);
    createUser();
  };
  return (
    <>
      <div className="create_resource">
        <Form
          layout="vertical"
          form={form1}
          initialValues={prefilledData}
          onFinish={recruterProfileOnFinish}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Campaign Name"
                name="campaignName"
                rules={[
                  {
                    required: true,
                    message: "Please enter campaign name",
                  },
                ]}
              >
                <Input
                  className=""
                  placeholder="Campaign name"
                  disabled={disable}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please enter company name",
                  },
                ]}
              >
                <Input
                  className=""
                  placeholder="Company name"
                  disabled={disable}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Total Adverts"
                name="totalAdverts"
                autoComplete="off"
                rules={[
                  {
                    required: true,
                    message: "Please enter total adverts",
                  },
                ]}
              >
                <Input
                  className=""
                  placeholder="Total adverts"
                  disabled={disable}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Target Headcount"
                name="targetHeadcount"
                rules={[
                  {
                    required: true,
                    message: "Please enter target headcount",
                  },
                ]}
              >
                <Input
                  className=""
                  placeholder="Target headcount"
                  disabled={disable}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Total Applications"
                name="totalApplications"
                rules={[
                  {
                    required: true,
                    message: "Please accept the total applications",
                  },
                ]}
              >
                <Input
                  className=""
                  placeholder="Total applications"
                  disabled={disable}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Total Shortlisted"
                name="totalShortlisted"
                rules={[
                  {
                    required: true,
                    message: "Please accept the total shortlisted",
                  },
                ]}
              >
                <Input
                  className=""
                  placeholder="Total shortlisted"
                  disabled={disable}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Total Accepted"
                name="totalAccepted"
                rules={[
                  {
                    required: true,
                    message: "Please accept the total accepted",
                  },
                ]}
              >
                <Input
                  className=""
                  placeholder="Total accepted"
                  disabled={disable}
                />
              </Form.Item>
            </Col>
            <Col span={12} />
          </Row>
          {type !== "view" ? (
            <Row gutter={6} className="buttonCol">
              <Col>
                <Button onClick={()=>{navigate(-1)}}>Cancel</Button>
              </Col>
              <Col>
                <Button className="btn" type="primary" htmlType="submit">
                  Save
                </Button>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Form>
      </div>
    </>
  );
}
export default CandidateDetails;
