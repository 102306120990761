import { LeftOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

function SubLayout({
  page,
  whiteBg,
  showBack,
  isBackFunc,
  backUrlFunction,
  children,
}) {
  const navigate = useNavigate();
  const handleBack = () => {
    if (isBackFunc) {
      backUrlFunction();
    } else {
      navigate(-1);
    }
  };
  return (
    <div>
      <div></div>
      <div className={!whiteBg ? "box" : "boxWhite"}>
        {showBack && (
          <span>
            <LeftOutlined
              className="backIcon"
              onClick={() => (isBackFunc ? backUrlFunction() : handleBack())}
            />
          </span>
        )}

        <span className="pageTitleBox">{page}</span>
      </div>
      <div className={!whiteBg ? "childrenBox" : "childrenBoxWhite"}>
        {children}
      </div>
    </div>
  );
}

export default SubLayout;
