import { Button, Col, Form, Input, Row, Select, Tooltip, message } from "antd";
import PhoneNumber from "../common/phoneNo";
import API_MANAGER from "../../../API";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EditFilled } from "@ant-design/icons";

function UserDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { type } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [pageType, setPageType] = useState(type);
  const [loading, setLoading] = useState(false);

  const createData = async (data) => {
    setLoading(true);
    try {
      if (pageType === "edit") {
        data.id = id;
        const response = await API_MANAGER.editUserAdmin(data);
        if (response?.data?.data?._id === localStorage.getItem("userId")) {
          localStorage.setItem("firstName", response?.data?.data?.firstName);
          localStorage.setItem("lastName", response?.data?.data?.lastName);
          localStorage.setItem(
            "userType",
            response?.data?.data?.standardAdmin === true ? "standard" : "admin"
          );
        }
        message.success("Edited successfully");
      } else {
        await API_MANAGER.createUser(data);
        message.success("User created successfully");
      }
      if (type === "view") {
        setPageType("view");
        getData();
      } else {
        navigate(-1);
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message || "Something went wrong!"
      );
    }
    setLoading(false);
  };
  const onFinish = async (values) => {
    let data = { ...values };
    data.role = 2;
    data.standardAdmin = values.standardAdmin === "admin" ? false : true;
    createData(data);
  };
  const getData = async () => {
    try {
      if (pageType === "view" || pageType === "edit") {
        const response = await API_MANAGER.getUserDetail(id);
        setData(response?.data?.data);
        let contryCode = response?.data?.data?.countryCode?.includes('+') ? response?.data?.data?.countryCode : `+${response?.data?.data?.countryCode}`
        form.setFieldValue("firstName", response?.data?.data?.firstName);
        form.setFieldValue("lastName", response?.data?.data?.lastName);
        form.setFieldValue("countryCode", contryCode);
        form.setFieldValue("phoneNumber", response?.data?.data?.phoneNumber);
        form.setFieldValue("email", response?.data?.data?.userId?.email);
        form.setFieldValue(
          "standardAdmin",
          response?.data?.data?.userId?.standardAdmin === true
            ? "standard_user"
            : "admin"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const userType = localStorage.getItem("userType");
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="create_user">
      {pageType === "view" ? (
        <>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col>
              <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
                <EditFilled
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    userType === "admin" ? setPageType("edit") : "";
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          <Row className="detail_row">
            <Col xs={3} className="detail_title">
              {" "}
              First Name:{" "}
            </Col>
            <Col xs={4} className="detail_title_value">
              {" "}
              {data?.firstName}{" "}
            </Col>
            <Col xs={3} className="detail_title">
              {" "}
              Last Name:{" "}
            </Col>
            <Col xs={4} className="detail_title_value">
              {" "}
              {data?.lastName}{" "}
            </Col>
            <Col xs={2} className="detail_title">
              {" "}
              Email:{" "}
            </Col>
            <Col xs={4} className="detail_title_value">
              {" "}
              {data?.userId?.email}{" "}
            </Col>
          </Row>
          <Row className="detail_row">
            <Col xs={3} className="detail_title">
              {" "}
              Contact No:{" "}
            </Col>
            <Col xs={4} className="detail_title_value">
              {" "}
              {data?.countryCode + "-" + data?.phoneNumber}{" "}
            </Col>
            <Col xs={3} className="detail_title">
              {" "}
              Permissions:{" "}
            </Col>
            <Col xs={4} className="detail_title_value">
              {" "}
              {data?.userId?.standardAdmin === true
                ? "Standard User"
                : "Admin"}{" "}
            </Col>
          </Row>
        </>
      ) : (
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="First Name*"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name",
                  },
                ]}
              >
                <Input className="" placeholder="First name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name*"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name",
                  },
                ]}
              >
                <Input className="" placeholder="Last name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Email*"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                  {
                    type: "email",
                    message: "Enter valid email",
                  },
                ]}
              >
                <Input
                  className=""
                  placeholder="Email"
                  type="email"
                  disabled={data?.userId?.isVerified}
                />
              </Form.Item>
            </Col>
            <Col xs={12} className="inputCol">
              <label className="inputCol1">Contact No*</label>
              <PhoneNumber />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="standardAdmin"
                label="Permissions*"
                rules={[
                  {
                    required: true,
                    message: "Permissions",
                  },
                ]}
              >
                <Select
                  placeholder="Select permissions"
                  options={[
                    {
                      value: "admin",
                      label: "Admin User",
                    },
                    {
                      value: "standard_user",
                      label: "Standard User",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={6} className="buttonCol">
            <Col>
              <Button
                onClick={() => {
                  if (type === "view") {
                    setPageType("view");
                  } else {
                    navigate(-1);
                  }
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="btn"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
}
export default UserDetails;
