import React from "react";
import { Avatar, Col, Modal, Row } from "antd";
import YellowImage from "../../Assets/campaign/yellow_circle.svg";
import YellowImageHalf from "../../Assets/campaign/yellow_circle_half.svg";
import LeftIcon from "../../Assets/campaign/leftIcon.svg";
import SaveIcon from "../../Assets/campaign/save.svg";
import ShareIcon from "../../Assets/campaign/share.svg";
import LocationIcon from "../../Assets/campaign/location.svg";
import EmploymentIcon from "../../Assets/campaign/EmploymentIcon.svg";
import HoursIcon from "../../Assets/campaign/HoursIcon.svg";
import IndustryIcon from "../../Assets/campaign/IndustryIcon.svg";
import JobDurationIcon from "../../Assets/campaign/JobDurationIcon.svg";
import Roster from "../../Assets/campaign/Roster.svg";
import Salary from "../../Assets/campaign/Salary.svg";
import Discipline from "../../Assets/campaign/Discipline.svg";
import CloseTimerIcon from "../../Assets/campaign/CloseIcon.svg";

import { UserOutlined } from "@ant-design/icons";
import moment from "moment";

const PhoneViewModal = ({ phoneViewData, phoneModal, setPhoneModal }) => {
  const handleOk = () => {
    setPhoneModal(false);
  };
  const handleCancel = () => {
    setPhoneModal(false);
  };
  return (
    <Modal
      centered
      footer={false}
      className="PhoneViewModalMainContainer"
      open={phoneModal}
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
    >
      <div className="phone_modal_main_content_row">
        <img src={YellowImage} className="yellow_icon" />
        <img src={YellowImageHalf} className="yellow_icon_half" />

        <Row className="titleMainRow">
          <Col xs={1}>
            <img src={LeftIcon} />
          </Col>
          <Col xs={23} align={"center"}>
            <span>Job Description</span>
          </Col>
        </Row>
        <div className="content_main_div">
          <Row className="content_row">
            <Col xs={20}>
              <Row justify={"space-between"}>
                <Col xs={4} align={"center"} className="align-center-justify ">
                  {" "}
                  <Avatar size="large" icon={<UserOutlined />} />
                </Col>
                <Col xs={19}>
                  <p className="Advert_title">
                    {phoneViewData?.jobTitle?.name || "---"}
                  </p>
                  <p className="Advert_Desc">{phoneViewData?.companyId?.companyName}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <img src={SaveIcon} />
              <img src={ShareIcon} />
            </Col>
          </Row>
          <Row
            className="location_row align-center-justify "
            justify={"space-between"}
          >
            <Col xs={2}>
              <img src={LocationIcon} />
            </Col>
            <Col xs={21}>
              <span className="location_text">{phoneViewData?.state?.name + ', ' + phoneViewData.suburb + ', '  + phoneViewData?.postalCode}</span>
            </Col>
          </Row>

          {/* Status Section */}
          <div className="status_main_div">
            <div className="status_inner_div">
              <Row>
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={4}>
                      <img src={EmploymentIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Employment Status</p>
                      {phoneViewData?.employmentType?.name}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} />
                <Col span={11}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img src={Salary} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Salary</p>
                      <p className="title_desc">
                        {phoneViewData?.salary
                          ? "" + phoneViewData?.salary + "/hr"
                          : "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row  className="mt-20">
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img src={Roster} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Roster</p>
                      <p className="title_desc">
                        {phoneViewData?.roster?.name || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={1} />
                <Col span={11}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img src={HoursIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Hours</p>
                      <p className="title_desc">
                        {phoneViewData?.hours || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-20">
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img src={IndustryIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Industry</p>
                      <p className="title_desc">
                        {phoneViewData?.industry?.name || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={1} />
                <Col span={11}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img src={Discipline} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Discipline</p>
                      <p className="title_desc">
                        {phoneViewData?.discipline?.name || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-20">
                <Col span={12}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img src={JobDurationIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Job Duration</p>
                      <p className="title_desc">
                        {phoneViewData?.jobDuration?.name || "---"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={1} />
                <Col span={11}>
                  <Row justify={"space-between"}>
                    <Col xs={5}>
                      <img src={CloseTimerIcon} />
                    </Col>
                    <Col xs={18}>
                      <p className="title_heading">Close date</p>
                      <p className="title_desc">{moment(phoneViewData?.endDate).format("DD/MM/YYYY") || "---"}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          {/* Job Description Section */}
          <Row className="mt-20 job_description_main_row">
            <Col xs={24} className="job_Desc_title">
              Job Description
            </Col>
            <Col xs={24} className="job_Desc_content">
              {phoneViewData?.jobDescription || "---"}
            </Col>
          </Row>
        </div>
      </div>

      {/* Content Section */}
    </Modal>
  );
};
export default PhoneViewModal;
