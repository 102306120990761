import React, { useState, useEffect } from "react";
import { Select, message, Spin, Row, Col } from "antd";
import SubLayout from "../../components/layout/SubLayout";
import SupportCard from "../../components/common/support/SupportCard";
import API_MANAGER from "../../API";
import CustomPagination from "../../components/common/CustomPagination";
import { useLocation } from "react-router-dom";

function Support() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState("ALL");
  const { state } = useLocation();
  const [page, setPage] = useState();
  const [filter, setFilter] = useState("Latest");
  const getData = async () => {
    let params = {
      page: page,
    };
    if (profile && profile !== "ALL") {
      params = { ...params, ["contactedBy"]: profile };
    }
    if (filter === "Replied") {
      params = { ...params, ["repliedTo"]: true };
    } else if (filter === "NotReplied") {
      params = { ...params, ["repliedTo"]: false };
    }
    if (page) {
      try {
        setLoading(true);
        const response = await API_MANAGER.getContacts(params);
        setData(response?.data?.data);
        setLoading(false);
      } catch (error) {
        message.error("Something went wrong!");
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if(page)
    getData();
  }, [page, filter, profile]);
  useEffect(() => {
    if (state && state?.path === "/support") {
      if (state?.page) {
        setPage(state.page);
      } else {
        setPage(1);
      }
      setFilter(state?.filter);
      setProfile(state?.profile)
      getData();
    } else {
      setPage(1);
    }
  }, [state]);

  return (
    <SubLayout page={"Support"}>
      <Row className="support_container">
        <Col>
          <Select
            placeholder="Select profile"
            defaultValue={"ALL"}
            value={profile}
            style={{ minWidth: "300px" }}
            onChange={(e) => {
              setPage(1);
              setProfile(e);
            }}
          >
            <Select.Option value="ALL">All</Select.Option>
            <Select.Option value="RECRUITER">Client</Select.Option>
            <Select.Option value="CANDIDATE">Candidate</Select.Option>
          </Select>
        </Col>
        <Col>
          <Select
            defaultValue={"Latest"}
            value={filter}
            style={{
              width: 120,
            }}
            onChange={(value) => {
              setFilter(value), setPage(1);
            }}
          >
            <Select.Option value="Latest">Latest</Select.Option>
            <Select.Option value="Replied">Replied</Select.Option>
            <Select.Option value="NotReplied">Not Replied</Select.Option>
          </Select>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <div className="supportCards">
          {data?.result?.map((item, index) => (
            <SupportCard
              key={index}
              item={item}
              getData={getData}
              page={page}
              filter={filter}
              profile={profile}
            />
          ))}
        </div>
        <div className="w-100">
          <CustomPagination
            currentPage={page}
            setCurrentPage={setPage}
            total={data.totalCount}
            itemPerPage={10}
          />
        </div>
      </Spin>
    </SubLayout>
  );
}

export default Support;
