import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Input,
  message,
  Switch,
  Upload,
  Button,
  Tooltip,
  Radio,
} from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import SubLayout from "../../components/layout/SubLayout";
import DeleteIcon from "../../Assets/delete.svg";
import { DeleteCandidate } from "../../components/common/Popup";
import { useLocation, useNavigate } from "react-router-dom";
import API_MANAGER from "../../API";
import moment from "moment";
import EditIcon from "../../Assets/editIcon.svg";
import CustomPagination from "../../components/common/CustomPagination";
function CandidateList() {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState();
  const [type, setType] = useState("PROFILE");
  const [noProfileData, setNoProfileData] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleEditFunc = (e, row) => {
    e.stopPropagation();
    navigate(`/candidate-list/candidate-detail/${row?.userId?._id}/edit`, {
      state: {
        data: row,
        page: page,
        search: search,
        path: "/candidate-list",
      },
    });
  };
  const getData = async (search) => {
    setLoading(true);
    const params = {
      page: page,
      search: search ? search : "",
    };
    if (page) {
      try {
        const response = await API_MANAGER.getCandidateList(params);
        setData(response?.data?.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        message.error("Something went wrong!");
        setLoading(false);
      }
    }
  };
  const deleteConfirm = async () => {
    setConfirmLoading(true);
    try {
      await API_MANAGER.deleteCandidate(deleteId);
      message.success("Candidate successfully deleted.");
      getData(search, 1);
      getDataNoProfile();
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message || "Something went wrong!"
      );
    }
    setConfirmLoading(false);
    setDeleteModal(false);
  };

  const hideUser = async (e, id) => {
    const data = {
      isActive: e,
      isHidden: e,
      isDeleted: false,
      hideReason: "from admin",
    };
    try {
      await API_MANAGER.hideUser(id, data);
      message.success("Candidate updated successfully.");
      getData(search, page);
    } catch (error) {
      message.error("Something went wrong!");
    }
  };
  const hiddenUserAPI = async (e, id) => {
    const data = {
      isHidden: e,
      hideReason: "from admin",
    };
    try {
      await API_MANAGER.hideUser(id, data);
      message.success("Candidate updated successfully.");
      getData(search, page);
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  const userType = localStorage.getItem("userType");
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (item, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page - 1) * 10 + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">{row?.name}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">{row?.email}</span>
      ),
    },
    {
      title: "Contact No.",
      dataIndex: "phone",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">
          {"+" + row?.countryCode + "-" + row?.phoneNumber}
        </span>
      ),
    },
    {
      title: (
        <span>
          Profile <br /> Progress
        </span>
      ),
      dataIndex: "profile_progress",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">
          {row?.profileProgress?.overAllProgress
            ? row?.profileProgress?.overAllProgress + "%"
            : "0%"}
        </span>
      ),
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      render: (text, row) => {
        return (
          <span>
            {row?.subscription[0]?.active ? (
              <span className="Live_text">Active</span>
            ) : (
              <span className="archived_text">Inactive</span>
            )}
          </span>
        );
      },
    },
    {
      title: (
        <span>
          Registration <br /> Date
        </span>
      ),
      dataIndex: "createdAt",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">
          {moment(row.createdAt).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      title: (
        <span>
          Last <br /> Login
        </span>
      ),
      dataIndex: "createdAt",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">
          {row?.userId?.lastLogin
            ? moment(row?.userId?.lastLogin).format("DD/MM/YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: (
        <span>
          Enable / <br /> Disable
        </span>
      ),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (_, row) => {
        return (
          <span onClick={(e) => e.stopPropagation()}>
            <Switch
              defaultChecked={row?.userId?.isActive}
              onClick={(e) => hideUser(e, row?.userId?._id)}
            />
          </span>
        );
      },
    },
    {
      title: (
        <span>
          Hide / <br /> Unhide
        </span>
      ),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (_, row) => {
        return (
          <span onClick={(e) => e.stopPropagation()}>
            <Switch
              defaultChecked={row?.userId?.isHidden}
              onClick={(e) => hiddenUserAPI(e, row?.userId?._id)}
            />
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "delete",
      render: (text, row) => (
        <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
          {userType === "admin" ? (
            <>
              <img
                src={EditIcon}
                alt="delete"
                style={{ width: "35px" }}
                className="cursor-pointer"
                onClick={(e) => {
                  handleEditFunc(e, row);
                }}
              />
              <img
                src={DeleteIcon}
                alt="delete"
                style={{ width: "35px" }}
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteId(row?.userId?._id);
                  setDeleteModal(true);
                }}
              />
            </>
          ) : (
            <>
              <img
                src={EditIcon}
                alt="delete"
                style={{ width: "35px" }}
                className="cursor-pointer"
              />
              <img
                src={DeleteIcon}
                alt="delete"
                style={{ width: "35px" }}
                className="cursor-pointer"
              />
            </>
          )}
        </Tooltip>
      ),
    },
  ];
  const columns2 = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (item, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page - 1) * 10 + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">{row?.email}</span>
      ),
    },
    {
      title: (
        <span>
          Profile <br /> Progress
        </span>
      ),
      dataIndex: "profile_progress",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">
          {row?.profileProgress?.overAllProgress
            ? row?.profileProgress?.overAllProgress + "%"
            : "0%"}
        </span>
      ),
    },
    {
      title: (
        <span>
          Registration <br /> Date
        </span>
      ),
      dataIndex: "createdAt",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">
          {moment(row.createdAt).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      title: (
        <span>
          Last <br /> Login
        </span>
      ),
      dataIndex: "createdAt",
      render: (text, row) => (
        <span className="cursor-pointer color-primary">
          {row?.lastLogin ? moment(row?.lastLogin).format("DD/MM/YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "delete",
      render: (text, row) => (
        <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
          {userType === "admin" ? (
            <>
              <img
                src={DeleteIcon}
                alt="delete"
                style={{ width: "35px" }}
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteId(row?._id);
                  setDeleteModal(true);
                }}
              />
            </>
          ) : (
            <>
              <img
                src={DeleteIcon}
                alt="delete"
                style={{ width: "35px" }}
                className="cursor-pointer"
              />
            </>
          )}
        </Tooltip>
      ),
    },
  ];
  // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const handleChange = async (file) => {
    try {
      const data = {
        file: file.fileList?.length > 0 ? file.fileList[0]?.originFileObj : [],
        extension:
          file?.fileList?.length > 0
            ? file?.fileList[0]?.name.split(".")[1]
            : "",
        role: "Candidate",
      };
      const response = await API_MANAGER.bulkUpload(data);
      if (response.data.isSuccess === true)
        message.success(
          `File successfully uploaded, ( uploadedUsers: ${response?.data?.data?.uploadedUsers}, existingUsers: ${response?.data?.data?.existingUsers} ).`
        );
      getData(search, page);
    } catch {
      message.error("Missing required fields");
    }
  };
  const props = {
    name: "file",
    type: "file",
    accept: ".xlsx,.csv,application/msexcel",
    multiple: false,
    customRequest() {
      setTimeout(() => {
        console.log("OK");
      }, 0);
    },
  };
  const getDataNoProfile = async () => {
    const params = {
      search: search ? search : "",
    };
    const response = await API_MANAGER.getCandidateListNoProfile(params);
    setNoProfileData(response?.data?.data);
  };
  const handleChangeExport =  async() => {
    const response = await API_MANAGER.getCandidateListNoProfileExport();
    const apiResponseArrayBuffer =
    response?.data; /* Your array buffer data from the API */
  const blob = new Blob([apiResponseArrayBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const blobUrl = URL.createObjectURL(blob);

  const downloadLink = document.createElement("a");
  downloadLink.href = blobUrl;
  downloadLink.download = `Cinchy.me_Candidates_No_profile.xlsx`; // Set the desired file name here
  downloadLink.click();
  URL.revokeObjectURL(blobUrl);
    console.log(response);
  }
  useEffect(() => {
    setPage(1);
    getData(search);
    getDataNoProfile();
  }, [search]);
  useEffect(() => {
    getData(search);
  }, [page]);
  useEffect(() => {
    if (state && state?.path === "/candidate-list") {
      setSearch(state.search);
      if (state?.page) {
        setPage(state.page);
      } else {
        setPage(1);
      }
      getData(search);
    } else {
      setPage(1);
    }
  }, [state]);
  useEffect(() => {
    getDataNoProfile();
  }, []);
  return (
    <SubLayout page="Candidate List">
      <Row>
        <Col>
          {" "}
          <div className="radioButtons2">
            <Radio.Group
              defaultValue={type}
              onChange={(e) => setType(e?.target?.value)}
            >
              <Radio.Button value="PROFILE">Profile </Radio.Button>
              <Radio.Button value="NOPROFILE">No Profile</Radio.Button>
            </Radio.Group>
          </div>
        </Col>
      </Row>
      <Row
        align={"middle"}
        justify={"space-between"}
        className="search_container"
        gutter={5}
      >
        <Col>
          <div style={{ maxWidth: "254px" }}>
            <Input.Search
              placeholder="Search"
              allowClear
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </div>
        </Col>
        {type === "PROFILE" ? (
          <Col>
            <div style={{ maxWidth: "254px" }}>
              <Upload {...props} showUploadList={false} onChange={handleChange}>
                <Button className="bulk_button">
                  <span>
                    <PaperClipOutlined />
                    Bulk Candidate Import
                  </span>
                </Button>
              </Upload>
            </div>
          </Col>
        ) : (
          <Col>
            <div style={{ maxWidth: "254px" }}>
                <Button className="bulk_button" onClick={handleChangeExport}>
                  <span>
                    <PaperClipOutlined />
                    Export Candidates
                  </span>
                </Button>
            </div>
          </Col>
        )}
      </Row>
      {type === "PROFILE" ? (
        <Row className="table_container">
          <Table
            columns={columns}
            dataSource={data?.result}
            pagination={false}
            className="w-100"
            rowKey={"id"}
            loading={loading}
            scroll={{
              y: "calc(100vh - 375px)",
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(
                    `/candidate-list/candidate-detail/${record?.userId?._id}/view`,
                    {
                      state: {
                        page: page,
                        search: search,
                        path: "/candidate-list",
                      },
                    }
                  );
                }, // click row
              };
            }}
          />

          <div className="w-100">
            <CustomPagination
              currentPage={page}
              setCurrentPage={setPage}
              total={data?.totalCount}
              itemPerPage={10}
            />
          </div>
        </Row>
      ) : (
        <Row className="table_container">
          <Table
            columns={columns2}
            dataSource={noProfileData?.result}
            pagination={false}
            className="w-100"
            rowKey={"id"}
            loading={loading}
            scroll={{
              y: "calc(100vh - 375px)",
            }}
          />
        </Row>
      )}
      <DeleteCandidate
        confirmDelete={deleteConfirm}
        visible={openDeleteModal}
        text={"Candidate"}
        setVisible={setDeleteModal}
        loading={confirmLoading}
      />
    </SubLayout>
  );
}

export default CandidateList;
