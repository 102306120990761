import React, { useEffect, useState } from "react";
import { Col, Row, message, Table, Switch, Pagination, Tooltip, Input } from "antd";
import { DeleteCandidate } from "../../common/Popup";
import { useParams } from "react-router-dom";
import API_MANAGER from "../../../API";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../../Assets/delete.svg";
import moment from "moment";

function AdvertDetail() {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState({});
  const [advertData, setAdvertData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const { id } = useParams();
  const userType = localStorage.getItem("userType");

  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: '75px',
      render: (_, row, index) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-detail/${row._id}`)}
          >
            {(page - 1) * 10 + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "Advert Name",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-detail/${row._id}`)}
          >
            {row?.jobTitle?.name}
          </span>
        );
      },
    },
    {
      title: "Discipline",
      dataIndex: "discipline",
      key: "discipline",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-detail/${row._id}`)}
          >
            {row?.discipline?.name}
          </span>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-detail/${row._id}`)}
          >
            {row?.state?.name}
          </span>
        );
      },
    },
    {
      title: "Open and Close Date",
      dataIndex: "OandC",
      key: "OandC",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-detail/${row._id}`)}
          >
            {moment(row.startDate).format("DD/MM/YYYY") +
              "-" +
              moment(row.endDate).format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      title: "Target Headcount",
      dataIndex: "targetHeadcount",
      key: "targetHeadcount",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-detail/${row._id}`)}
          >
            {row.targetHeadcount}
          </span>
        );
      },
    },
    {
      title: "Total Applications",
      dataIndex: "totalApplications",
      key: "totalApplications",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-detail/${row._id}`)}
          >
            {row.applied}
          </span>
        );
      },
    },
    {
      title: "Total Shortlisted",
      dataIndex: "totalShortlisted",
      key: "totalShortlisted",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-detail/${row._id}`)}
          >
            {row.shortlistOffered}
          </span>
        );
      },
    },
    {
      title: "Total Accepted",
      dataIndex: "totalAccepted",
      key: "totalAccepted",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-detail/${row._id}`)}
          >
            {row.roleAccepted}
          </span>
        );
      },
    },
    {
      title: (
        <span>
          Enable / <br /> Disable
        </span>
      ),
      dataIndex: "isActive",
      key: "isActive",
      render: (text, row) => (
        <Switch
          defaultChecked={row?.isActive}
          checked={row?.isActive}
          onClick={(e) => toggleStatus(e, row._id)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, row) => (
        <>
           <Tooltip title={userType === 'admin' ? "" : "Not Authorised"}>
          <span>
            {userType === 'admin' ? (
                <img
                  src={DeleteIcon}
                  alt="delete"
                  style={{ width: "35px" }}
                  className="cursor-pointer"
                  onClick={() => {
                    setDeleteId(row._id);
                  setDeleteModal(true);
              }}
            />
              ) : (
                <img
                  src={DeleteIcon}
                  alt="delete"
                  style={{ width: "35px" }}
                  className="cursor-pointer"
                />
              )}
          </span>
          </Tooltip>
        </>
      ),
    },
  ];
  const toggleStatus = async (status, id) => {
    setLoading(true);
    try {
      await API_MANAGER.toggleAdvert({ isActive: status }, id);
      message.success(
        `Succefully ${status !== true ? "disabled" : "enabled"} advert`
      );
    } catch (error){
      message.error(error.response.data.message.message);
    }
    getData();
    setLoading(false);
  };
  const deleteConfirm = async () => {
    try {
      setConfirmLoading(true);
      await API_MANAGER.deleteAdvert(deleteId);
      message.success("Successfully Deleted Advert");
      setDeleteModal(false);
      getData();
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message || "Something went wrong!"
      );
    }
    setConfirmLoading(false);
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    getData(pageNumber);
  };
  const getData = async (search, pageNumber) => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getCampaignDetail(id);
      setData(response?.data?.data);
      const advertResp = await API_MANAGER.getAdvertList(id, {
        search: search,
        page: pageNumber,
      });
      setAdvertData(advertResp?.data?.data);
    } catch (err) {
      message.error(
        err?.response?.data?.message?.message || "Something Went Wrong"
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    getData(search);
  }, [search]);
  console.log(data, "dadddddddd");
  return (
    <>
      <div className="detail_container">
        <Row>
          <Col className="heading">Campaign Detail</Col>
        </Row>
        <Row className="detail_row" >
          <Col xs={3} className="detail_title">
            Campaign Name:
          </Col>
          <Col xs={5} className="detail_title_value">
            {data.name}
          </Col>
          <Col xs={3} className="detail_title">
            Company Name:
          </Col>
          <Col xs={5} className="detail_title_value">
            {data?.companyId?.companyName}
          </Col>
          <Col xs={3} className="detail_title">
            Industry:
          </Col>
          <Col xs={5} className="detail_title_value">
            {data?.industry?.name}
          </Col> 
        </Row>
        <Row className="detail_row" >
        <Col xs={3} className="detail_title">
            Project Code:
          </Col>
          <Col xs={5} className="detail_title_value">
            {data?.projectCode}
          </Col> 
          <Col xs={3} className="detail_title">
            Location:
          </Col>
          <Col xs={5} className="detail_title_value">
            {data?.state?.name + ', ' + data?.suburb + ", " + data?.postalCode}
          </Col> 
          <Col xs={3} className="detail_title">
            Total Adverts:
          </Col>
          <Col xs={5} className="detail_title_value">
            {data?.advertCount}
          </Col>
        </Row>
        <Row className="detail_row">
          <Col xs={3} className="detail_title">
            Target Headcount
          </Col>
          <Col xs={5} className="detail_title_value">
            {data.targetHeadCount}
          </Col>
          <Col xs={3} className="detail_title">
            Total Applications:
          </Col>
          <Col xs={5} className="detail_title_value">
           {data.appliedJobsCount}
          </Col>
          <Col xs={3} className="detail_title">
            Total Shortlisted:
          </Col>
          <Col xs={5} className="detail_title_value">
           {data.roleOffered}
          </Col>
        </Row>
        <Row className="detail_row">
          <Col xs={3} className="detail_title">
            Total Accepted:
          </Col>
          <Col xs={5} className="detail_title_value">
          {data.rolesAccepted}
          </Col>
          <Col xs={3} className="detail_title">
            Created Date:
          </Col>
          <Col xs={5} className="detail_title_value">
            {moment(data?.createdAt).format("DD/MM/YYYY")}
          </Col>
          <Col xs={3} className="detail_title">
            Users:
          </Col>
          <Col xs={5} className="detail_title_value">
            {data?.users?.length}
          </Col>
        </Row>
      </div>
      <Row style={{marginTop: '20px'}} className="search_container">
        <Col>
          <Input.Search
            placeholder="Search"
            allowClear
            value={search}
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </Col>
      </Row>
      <div className="table_container">
        <Row>
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={advertData?.result}
              pagination={false}
              className="w-100 table"
              rowKey={"id"}
              loading={loading}
              scroll={{
                y: 240,
              }}
            />
          </Col>
          <div className="customPagination">
            <Pagination
              total={advertData?.totalCount}
              pageSize={10}
              responsive={true}
              current={page}
              onChange={(e) => handlePageChange(e)}
            />
          </div>
        </Row>
        <DeleteCandidate
          confirmDelete={deleteConfirm}
          visible={openDeleteModal}
          text={"Advert"}
          setVisible={setDeleteModal}
          loading={confirmLoading}
        />
      </div>
    </>
  );
}
export default AdvertDetail;
