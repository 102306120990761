import React from "react";
import { Row, Col, Form, Select, Button, Table, DatePicker } from "antd";
import SubLayout from "../../components/layout/SubLayout";
function TransactionDetail() {
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: '80px',
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Transaction Details",
      dataIndex: "transaction_detail",
    },
  ];
  const data = [
    {
      key: "1",
      si: 1,
      name: "John Brown",
      date: "06/18/2022",
      transaction_detail:
        "Scenario: Publishing and graphic design, Lorem ipsum and graphic Scenario ",
    },
    {
      key: "2",
      si: 2,
      name: "John Brown",
      date: "06/18/2022",
      transaction_detail:
        "Scenario: Publishing and graphic design, Lorem ipsum and graphic Scenario ",
    },
    {
      key: "3",
      si: 3,
      name: "John Brown",
      date: "06/18/2022",
      transaction_detail:
        "Scenario: Publishing and graphic design, Lorem ipsum and graphic Scenario ",
    },
    {
      key: "4",
      si: 4,
      name: "John Brown",
      date: "06/18/2022",
      transaction_detail:
        "Scenario: Publishing and graphic design, Lorem ipsum and graphic Scenario ",
    },
  ];
  return (
    <SubLayout page="Transaction List">
      <div>
        <Form>
          <Row gutter={16}>
            <Col span={5}>
              <Form.Item>
                <Select placeholder="Select profile">
                  <Select.Option value="">All</Select.Option>
                  <Select.Option value="recruiter">Recruiter</Select.Option>
                  <Select.Option value="Candidate">Candidate</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="start_date">
                <DatePicker  placeholder="Select start date" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="end_date">
                <DatePicker placeholder="Select end date" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    <Button className="w-100">Search</Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Button className="w-100">Download Report</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <Row style={{ marginTop: "6px" }}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={true}
            className="w-100"
          />
        </Row>
      </div>
    </SubLayout>
  );
}

export default TransactionDetail;
