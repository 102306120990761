import { useNavigate } from "react-router-dom";

export const IsTokenValid = () => {
  const navigate = useNavigate();
  let currentTime = new Date().getTime();
  let expireTime = localStorage.getItem("expireTime");
  if (currentTime > expireTime) {
    navigate("/login");
    return false;
  } else {
    return true;
  }
};
