import React, { useEffect, useState } from "react";
import { Row, Col, DatePicker, Button, Divider, message } from "antd";
import SubLayout from "../../components/layout/SubLayout";
import CustomCards from "../../components/common/dashboard/CustomCards";
import API_MANAGER from "../../API";
import moment from "moment";
import dayjs from "dayjs";
import { numberWithCommas } from "../../constants";
// import HELPERS from "../../utils/helper";

function Dashboard() {
  const [rtcData, setRtcData] = useState({});
  const [otherData, setOtherData] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(null);

  const getRTCData = async () => {
    try {
      const response = await API_MANAGER.getRTCDashboard();
      setRtcData(response?.data?.data?.realTimeCards);
    } catch {
      message.error("Something went wrong");
    }
  };

  const getOtherData = async (startDate, endDate) => {
    try {
      const response = await API_MANAGER.getOtherDashboard({
        startDate: startDate,
        endDate: endDate,
      });
      setOtherData(response?.data?.data?.otherDetails);
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message || "Something went wrong"
      );
    }
  };

  const handleDate = (e, date) => {
    setStartDate(date);
    if (date) {
      let endDate = dayjs(e).add(1, "day").format("DD-MM-YYYY");
      setEndDate(endDate);
    } else {
      setEndDate(null);
      getOtherData(null, null);
    }
  };
  const getReportsData = async (reportType, name) => {
    console.log(startDate, endDate);
    try {
      const response = await API_MANAGER.getReportDashboard({
        reportType: reportType,
        startDate: startDate
          ? moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
        endDate: endDate
          ? moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
      });

      const apiResponseArrayBuffer =
        response?.data; /* Your array buffer data from the API */
      const blob = new Blob([apiResponseArrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = `Cinchy.me_${name}.xlsx`; // Set the desired file name here
      downloadLink.click();
      URL.revokeObjectURL(blobUrl);
    } catch {
      message.error("Something went wrong");
    }
  };
  const disabledDate = (current) => {
    return moment(startDate, "DD-MM-YYYY").add(0, "days") >= current;
  };
  const handleDateFilter = async () => {
    getOtherData(
      moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
    );
  };

  useEffect(() => {
    getRTCData();
    getOtherData();
  }, []);
  return (
    <SubLayout page={"Dashboard"}>
      <div className="dashboard_main_container">
        <div className="dashboard_one_container">
          <Row>
            <Col>
              <p className="heading">Real Time Cards</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <CustomCards
                heading={"Daily Candidate Revenue"}
                amount={`$${numberWithCommas(
                  rtcData?.candidateRevenue?.value
                )}`}
                growth={rtcData?.candidateRevenue?.growth}
                color={"#F57F1F"}
                cardImage={"advert"}
              />
            </Col>
            <Col span={12}>
              <CustomCards
                heading={"Daily Client Revenue"}
                amount={`$${numberWithCommas(rtcData?.clientRevenue?.value)}`}
                growth={rtcData?.clientRevenue?.growth}
                color={"#F57F1F"}
                cardImage={"advert"}
              />
            </Col>
            <Col span={12}>
              <CustomCards
                heading={"Daily New Clients"}
                amount={numberWithCommas(rtcData?.clients?.value)}
                growth={rtcData?.clients?.growth}
                color={"#F57F1F"}
                cardImage={"revenue"}
              />
            </Col>
            <Col span={12}>
              <CustomCards
                heading={"Daily New Candidates"}
                amount={numberWithCommas(rtcData?.candidates?.value)}
                growth={rtcData?.candidates?.growth}
                color={"#F57F1F"}
                cardImage={"revenue"}
              />
            </Col>
            <Col span={12}>
              <CustomCards
                heading={"Daily New Adverts"}
                amount={rtcData?.adverts?.value}
                growth={rtcData?.adverts?.growth}
                color={"#F57F1F"}
                cardImage={"advert"}
              />
            </Col>
            <Col span={12}>
              <CustomCards
                heading={"Daily New Campaigns"}
                amount={rtcData?.campaigns?.value}
                growth={rtcData?.campaigns?.growth}
                color={"#F57F1F"}
                cardImage={"revenue"}
              />
            </Col>
            <Col span={12}>
              <CustomCards
                heading={"Bot Logs"}
                amount={rtcData?.botLogs?.value}
                growth={rtcData?.botLogs?.growth}
                color={"#F57F1F"}
                cardImage={"advert"}
              />
            </Col>
          </Row>
        </div>
        <div className="dashboard_one_container">
          <p className="heading"> Other Details</p>
          <div style={{ padding: "8px" }}>
            <Row gutter={16}>
              <Col span={6}>
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select start date"
                  style={{ height: "38px" }}
                  onChange={(e, date) => {
                    handleDate(e, date);
                  }}
                />
              </Col>
              <Col span={6}>
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select end date"
                  disabled={!endDate}
                  disabledDate={disabledDate}
                  value={endDate ? dayjs(endDate, "DD-MM-YYYY") : null}
                  style={{ height: "38px" }}
                  onChange={(e, date) => setEndDate(date)}
                />
              </Col>
              <Col span={3}>
                <Button
                  className="w-100  secondaryBtn"
                  onClick={handleDateFilter}
                  disabled={!startDate && !endDate}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </div>
          <Divider />
          <Row gutter={16}>
            <Col span={8}>
              <CustomCards
                heading={"$YTD Candidate Revenue"}
                amount={numberWithCommas(otherData?.revenue?.value)}
                color={"#F57F1F"}
                cardImage={"advert"}
                reportType={"TOTAL_YTD_CANDIDATE_REVENUE"}
                handleFunction={getReportsData}
                download={true}
              />
            </Col>
            <Col span={8}>
              <CustomCards
                heading={"New Candidates"}
                amount={numberWithCommas(otherData?.newCandidates?.value)}
                color={"#F57F1F"}
                cardImage={"newuser"}
                reportType={""}
                handleFunction={getReportsData}
              />
            </Col>
            <Col span={8}>
              <CustomCards
                heading={"No of Candidates"}
                amount={`${numberWithCommas(
                  otherData?.candidates?.value
                )} (${numberWithCommas(otherData?.candidates?.total)})`}
                color={"#F57F1F"}
                download={true}
                cardImage={"activeuser"}
                reportType={"TOTAL_CANDIDATES"}
                handleFunction={getReportsData}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <CustomCards
                heading={"Active Candidates"}
                amount={`${numberWithCommas(
                  otherData?.activeCandidates?.value
                )} (${numberWithCommas(otherData?.activeCandidates?.total)})`}
                color={"#F57F1F"}
                cardImage={"totaluser"}
                reportType={"TOTAL_ACTIVE_CANDIDATES"}
                handleFunction={getReportsData}
                download={true}
              />
            </Col>
            <Col span={8}>
              <CustomCards
                heading={"Delete Candidates"}
                amount={numberWithCommas(
                  otherData?.candidates?.total - otherData?.candidates?.value
                )}
                color={"#F57F1F"}
                cardImage={"advert"}
                reportType={""}
                handleFunction={getReportsData}
              />
            </Col>
            <Col span={8}>
              <CustomCards
                heading={"$YTD Client Revenue"}
                amount={numberWithCommas(otherData?.clientRevenue?.value)}
                color={"#F57F1F"}
                cardImage={"advert"}
                reportType={"TOTAL_YTD_CLIENT_REVENUE"}
                handleFunction={getReportsData}
                download={true}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <CustomCards
                heading={"No of Clients"}
                amount={`${numberWithCommas(
                  otherData?.clients?.value
                )} (${numberWithCommas(otherData?.clients?.total)})`}
                color={"#F57F1F"}
                cardImage={"totaluser"}
                reportType={"TOTAL_CLIENTS"}
                handleFunction={getReportsData}
                download={true}
              />
            </Col>
            <Col span={8}>
              <CustomCards
                heading={"No Of Adverts"}
                amount={numberWithCommas(otherData?.adverts?.value)}
                color={"#F57F1F"}
                cardImage={"advert"}
                reportType={"TOTAL_ADVERTS"}
                handleFunction={getReportsData}
                download={true}
              />
            </Col>
            <Col span={8}>
              <CustomCards
                heading={"No of Campaign"}
                amount={numberWithCommas(otherData?.campaigns?.value)}
                color={"#F57F1F"}
                cardImage={"advert"}
                reportType={"TOTAL_YTD_CAMPAIGNS"}
                handleFunction={getReportsData}
                download={true}
              />
            </Col>
          </Row>
        </div>
      </div>
    </SubLayout>
  );
}

export default Dashboard;
