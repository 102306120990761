import React, { useState } from "react";
import { Col, Input, Row, Form, Button, message } from "antd";
import SubLayout from "../../components/layout/SubLayout";
import API_MANAGER from "../../API";
import { useForm } from "antd/es/form/Form";

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const changePassword = async (data) => {
    setLoading(true);
    try {
      await API_MANAGER.updatePassword(data);
      message.success("Successfully updated password");
      form.resetFields();
    } catch (err) {
      message.error(
        err?.response?.data?.message?.message === "Incorrect password"
          ? "Incorrect current password"
          : err?.response?.data?.message?.message
      );
    }
    setLoading(false);
  };
  const onFinish = (values) => {
    if (!values) {
      message.warning("Please enter fields");
    }
    if (!values.currentPassword) {
      message.warning("Please enter current password");
    } else if (!values.newPassword) {
      message.warning("Please enter new password");
    } else if (!values.confirmPassword) {
      message.warning("Please enter confirm password");
    } else if (values?.newPassword !== values?.confirmPassword) {
      message.warning("New password and confirm password does not match!");
    } else if (!values?.newPassword.match(/[A-Z]/)) {
      message.warning("Password needs to have at least 1 capital letter");
    } else if (!values?.newPassword.match(/[a-z]/)) {
      message.warning("Password needs to have at least 1 small letter");
    } else if (
      !values?.newPassword.match(/[!@#$%^&*(),.?":{}|<>]/) ||
      !values?.newPassword.match(/[1-9]/)
    ) {
      message.warning(
        "Password needs to have at least 1 special character and 1 numeric character"
      );
    } else if (!(values?.newPassword.length >= 8)) {
      message.warning("Password needs to be at least 8 characters long");
    } else {
      changePassword(values);
    }
  };
  return (
    <SubLayout page={"Change Password"}>
      <Row className="containner_row">
        <Col md={10} className="containner">
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
          >
            <Col xs={24}>
              <Form.Item name="currentPassword" label="Current Password">
                <Input.Password
                  type="password"
                  placeholder="Current password"
                />
              </Form.Item>
              <label>New Password:</label>
              <Form.Item name="newPassword">
                <Input.Password type="password" placeholder="New password" />
              </Form.Item>
              <label>Confirm Password:</label>
              <Form.Item name="confirmPassword">
                <Input.Password
                  type="password"
                  placeholder="Confirm password"
                />
              </Form.Item>
            </Col>
            <Row className="buttonCol">
              <Button
                className="btn1"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Change Password
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </SubLayout>
  );
}
export default ChangePassword;
