import React from "react";
import { useParams, useLocation } from "react-router-dom";
import CampaignDetails from "../../components/common/campaign/CampaignDetails";
import SubLayout from "../../components/layout/SubLayout";

function CampaignCreateEdit() {
  const { id } = useParams();
  const { type } = useParams();
  const { state } = useLocation();
  return (
    <SubLayout
      page={`Campaign Detail ${
        type?.charAt(0)?.toUpperCase() + type?.substring(1, type.length)
        }`}
      showBack
    >
      <CampaignDetails
        id={id}
        type={type}
        disable={type === "view" ? true : false}
        prefilledData={state?.data}
      />
    </SubLayout>
  );
}
export default CampaignCreateEdit;
