import React, { useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Modal,
  Checkbox,
  Switch,
  Spin,
  message,
} from "antd";
import API_MANAGER from "../../../API";
// import API_MANAGER from "../../../API";
// import axios from "axios";
// import { s3PreUrl } from "../../../constants";

function AddExperience({
  addExperience,
  setAddExperience,
  jobTitle,
  industry,
  discipline,
  induction,
  skills,
  userId,
}) {
  const [experienceForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [showReferee, setShowReferee] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      values.userId = userId;
      values.jobDetails = values.skills;
      values.currentRole = currentStatus;
      await API_MANAGER.createCandidateExperience(values);
      message.success("Successfully added experience");
      setAddExperience(false);
    } catch (error) {
      message.error(error.response.data.message.message);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal
        centered
        footer={false}
        open={addExperience}
        title={"Experience Add"}
        className="resumeModal"
        onCancel={() => setAddExperience(false)}
      >
        <Form form={experienceForm} layout="vertical" onFinish={onFinish}>
          <Spin spinning={loading} />
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="Employer*" name="employerName">
                <Input placeholder="Employer" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Project Name*" name="projectName">
                <Input placeholder="Project Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="Job Title*" name="jobTitle">
                <Select className="" placeholder="industry">
                  {jobTitle?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Industry*" name="industry">
                <Select className="" placeholder="industry">
                  {industry?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="Discipline*" name="discipline">
                <Select className="" placeholder="discipline">
                  {discipline?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Inductions*" name="inductions">
                <Select
                  className=""
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="inductions"
                >
                  {induction?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item
                label="Job Description, Responsibilties, Skills*"
                name="skills"
              >
                <Select
                  className=""
                  mode="multiple"
                  maxTagCount="responsive"
                  placeholder="skills"
                >
                  {skills?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e.id}>
                        {e.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label="Start Date*" name="startDate">
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select start date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="End Date*" name="endDate">
                <DatePicker
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  placeholder="Select end date"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item name="hasReferee">
                <Checkbox
                  defaultChecked={showReferee}
                  onChange={() => setShowReferee(!showReferee)}
                >
                  Referee Details
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item name="currentRole">
                <span style={{ marginRight: "10px" }}>Current Role</span>
                <Switch
                  checked={currentStatus}
                  onClick={(e) => setCurrentStatus(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          {showReferee ? (
            <>
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item label="Referee Name" name="refereeName">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Referee Position" name="refereePosition">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item
                    label="Referee Email/ Phone Number"
                    name="refereeContact"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>{" "}
            </>
          ) : (
            ""
          )}

          <Row>
            <Col xs={24} className="buttonCol">
              <Button className="btn1" htmlType="submit">
                Create
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
export default AddExperience;
