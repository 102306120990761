import React from "react";
import { Col, Row } from "antd";
import YellowImage from "../../Assets/campaign/yellow_circle.svg";
import YellowImageHalf from "../../Assets/campaign/yellow_circle_half.svg";
import LeftIcon from "../../Assets/campaign/leftIcon.svg";
import WalletIcon from "../../Assets/campaign/walletIcon.svg";
import GreenTick from "../../Assets/campaign/greenTick.svg";

function CandidatePreview({ previewData, featureArr }) {
  const numberWithCommas = (number) => {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
  console.log(previewData, "previewData");
  return (
    <Row>
      <Col xs={24} className="previewContainer">
        <Row>
          <Col xs={24} className="preview">
            Preview
          </Col>
          <Col className="innerContainer">
            <div className="phone_modal_main_content_row">
              <img src={YellowImage} className="yellow_icon" />
              <img src={YellowImageHalf} className="yellow_icon_half" />
              <Row className="titleMainRow">
                <Col xs={1}>
                  <img src={LeftIcon} />
                </Col>
                <Col xs={23} align={"center"}>
                  <span>My Subscription</span>
                </Col>
              </Row>
              <Row className="titleRow">
                <Col xs={24} className="titleColumn">
                  <Row>
                    <Col className="wallet">
                      <img src={WalletIcon} className="walletIcon" />
                    </Col>
                    <Col xs={1} />
                    <Col xs={17}>
                      <Col>
                        <p className="title">{previewData.name}</p>
                      </Col>
                      <Col className="titleDesc">
                        {previewData?.default_price
                          ? "$" + numberWithCommas(previewData?.default_price)
                          : ""}
                        {previewData?.recurringType
                          ? "/" + previewData?.recurringType
                          : ""}
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="content_main_div w-100">
                <Row className="w-100">
                  <Col className="content_row w-100">
                    <Col>
                      <p className="title">{previewData.name}</p>
                    </Col>
                    <Col className="titleDesc">{previewData?.description}</Col>
                    <Col style={{ margin: "20px 0px 20px 0px" }}>
                      <span className="priceSpan">
                        {previewData?.default_price
                          ? "$" + numberWithCommas(previewData?.default_price)
                          : ""}
                      </span>
                      <span className="recurrType">
                        {previewData?.recurringType
                          ? "/" + previewData?.recurringType
                          : ""}
                      </span>
                    </Col>
                    <Col className="w-100">
                      {featureArr?.map((e, key) => {
                        return (
                          <Col
                            xs={24}
                            key={key}
                            style={{ marginBottom: "10px" }}
                          >
                            <Row>
                              <Col xs={3}>
                                <img src={GreenTick} />
                              </Col>
                              <Col xs={20} className="bulletPoint">
                                {e.name}
                              </Col>
                            </Row>
                          </Col>
                        );
                      })}
                    </Col>
                  </Col>
                </Row>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
export default CandidatePreview;
