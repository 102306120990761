import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Switch,
  Table,
  Tooltip,
  message,
  Form,
} from "antd";
import SubLayout from "../../components/layout/SubLayout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../Assets/delete.svg";
import API_MANAGER from "../../API";
import { DeleteCandidate } from "../../components/common/Popup";
import PhoneIcon from "../../Assets/subscription/phoneIcon.svg";
import PreviewSubscription from "./previewSubscription";
import EditIcon from "../../Assets/editIcon.svg";
import moment from "moment";
import { CloseOutlined, PlusCircleFilled } from "@ant-design/icons";
// import CustomPagination from "../../components/common/CustomPagination";

function Subscription() {
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [featureArr, setFeatureArr] = useState([]);
  const [editId, setEditId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [featureValue, setFeature] = useState({});
  const [subForm] = Form.useForm();
  const numberWithCommas = (number) => {
    var parts = number?.toString()?.split(".");
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts?.join(".");
  };
  console.log(editId, "lmml");
  const userType = localStorage.getItem("userType");
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer"> {10 * (1 - 1) + index + 1} </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Subscription Target",
      dataIndex: "subscriptionFor",
      key: "subscriptionFor",
    },
    {
      title: "Price",
      dataIndex: "default_price",
      key: "default_price",
      render: (text, row) => (
        <span>
          {"$" + numberWithCommas(row.default_price) + "/" + row.recurringType}
        </span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row) => (
        <span>{moment(row?.createdAt).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: (
        <span>
          Enable / <br /> Disable
        </span>
      ),
      dataIndex: "active",
      key: "active",
      align: "center",
      render: (text, row) => (
        <Switch
          checked={row?.active}
          onClick={(e) => {
            toggleStatus(e, row?._id);
          }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      render: (text, row) => {
        return (
          <div style={{ display: "flex" }}>
            <span>
              <div
                className="phoneIcon"
                onClick={() => {
                  {
                    row, text;
                  }
                  setPreviewModal(true);
                  setPreviewData(row);
                }}
              >
                <img
                  src={PhoneIcon}
                  alt="PhoneIcon"
                  style={{ width: "25px" }}
                  className="cursor-pointer"
                />
              </div>
            </span>
            <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
              <span>
                {userType === "admin" ? (
                  <>
                    <img
                      src={EditIcon}
                      alt="edit"
                      style={{ width: "35px" }}
                      className="cursor-pointer"
                      onClick={() => {
                        setEditId(row?._id);
                        setFeatureArr(row?.feature)
                        setEdit(true);
                      }}
                    />
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      style={{ width: "35px" }}
                      className="cursor-pointer"
                      onClick={() => {
                        setDeleteId(row._id);
                        setDeleteModal(true);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={EditIcon}
                      alt="edit"
                      style={{ width: "35px" }}
                      className="cursor-pointer"
                    />
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      style={{ width: "35px" }}
                      className="cursor-pointer"
                    />
                  </>
                )}
              </span>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const toggleStatus = async (status, id) => {
    setLoading(true);
    try {
      await API_MANAGER.subscriptionToggle({ status: status }, id);
      getData();
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
    setLoading(false);
  };
  const handleInputChange = () => {
    if (featureValue !== "") {
      setFeatureArr([...featureArr, featureValue]);
    }
    subForm.setFieldValue("feature", null);
    setFeature("");
  };
  const handleDeleteFeature = (key) => {
    const updatedFeature = [...featureArr];
    updatedFeature.splice(key, 1);
    setFeatureArr(updatedFeature);
  };
  const getData = async () => {
    setLoading(true);
    try {
      const response = await API_MANAGER.getSubscriptions({ search: search });
      setData(response?.data?.data);
    } catch {
      message.error("Something went wrong");
    }
    setLoading(false);
  };
  const deleteConfirm = async () => {
    setConfirmLoading(true);
    try {
      await API_MANAGER.deleteSubscription(deleteId);
      message.success("Successfully deleted subscription product");
      getData();
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message ||
          "Subscription product has active subscriptions"
      );
    }
    setConfirmLoading(false);
    setDeleteModal(false);
  };
  const handleUpdate = async (values) => {
    try {
      setLoading(true);
      values._id = editId;
      values.feature = featureArr;
      await API_MANAGER.editSubscription(values);
      message.success("Subscription features edited successfully.");
      setFeatureArr([]);
      subForm.resetFields();
      getData();
      setEdit(false);
    }  catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [search]);
  {
    deleteId;
  }
  return (
    <SubLayout page={"Subscription Detail"}>
      <Row className="search_container" align={"middle"} gutter={5}>
        <Col>
          <Input.Search
            placeholder="Search"
            value={search}
            allowClear
            onChange={(e) => setSearch(e?.target?.value)}
          />
        </Col>
        <Col className="btn_Col">
          <Button
            className="bulk_button"
            onClick={() => navigate(`/subscription/create`)}
          >
            Add Subscription
          </Button>
        </Col>
      </Row>

      <Row className="table_container">
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          className="w-100"
          rowKey={"id"}
          loading={loading}
          scroll={{
            y: "calc(100vh - 360px)",
          }}
        />
      </Row>
      <DeleteCandidate
        confirmDelete={deleteConfirm}
        visible={openDeleteModal}
        setVisible={setDeleteModal}
        text={"Subscription"}
        loading={confirmLoading}
      />
      <PreviewSubscription
        visible={previewModal}
        setVisible={setPreviewModal}
        previewData={previewData}
      />
      <Modal
        centered
        footer={false}
        open={edit}
        title={"Subscription Features Edit"}
        onCancel={() => setEdit(false)}
      >
        <Form form={subForm} layout="vertical" onFinish={handleUpdate}>
          <Row>
            <Col xs={24}>
              <Form.Item
                label="Features (Subscription pointers)"
                name="feature"
              >
                <Input
                  onChange={(e) =>
                    setFeature(
                      e?.target?.value?.trim() ? { name: e?.target?.value } : ""
                    )
                  }
                  suffix={<PlusCircleFilled onClick={handleInputChange} />}
                />
              </Form.Item>
              <div className="renderDiv">
                {featureArr.map((e, index) => (
                  <span className="renderSpan" key={index}>
                    <span className="renderTitle">{e.name}</span>
                    <span>
                      <CloseOutlined
                        className="crossIcon"
                        onClick={() => handleDeleteFeature(index)}
                      />
                    </span>
                  </span>
                ))}
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} className="buttonCol">
              <Button onClick={()=> {setEdit(false); setFeatureArr([])}}>
                Cancel
              </Button>
              <Button className="btn1" htmlType="submit" loading={loading}>
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </SubLayout>
  );
}
export default Subscription;
