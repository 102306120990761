import {
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Button,
  Form,
  message,
  Radio,
} from "antd";
import SubLayout from "../../components/layout/SubLayout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import API_MANAGER from "../../API";
import { CloseOutlined, PlusCircleFilled } from "@ant-design/icons";
import CandidatePreview from "./candidatePreview";
import ClientPreview from "./clientPreview";

function CreateUpdateSubs() {
  const [loading, setLoading] = useState(false);
  const [featureValue, setFeature] = useState({});
  const [featureArr, setFeatureArr] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [data, setData] = useState({});
  const [value, setValue] = useState(1);
  const [preview, setPreview] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const createData = async (data) => {
    setLoading(true);
    try {
      await API_MANAGER.createSubscription(data);
      message.success("Subscription created successfully");
      navigate("/subscription");
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
    setLoading(false);
  };

  const handleCheck = (e) => {
    setValue(e);
  };
  const handleInputChange = () => {
    if (featureValue !== "") {
      setFeatureArr([...featureArr, featureValue]);
    }
    form.setFieldValue("feature", null);
    setFeature("");
  };

  const handleDeleteFeature = (key) => {
    const updatedFeature = [...featureArr];
    updatedFeature.splice(key, 1);
    setFeatureArr(updatedFeature);
  };
  const onFinish = async (values) => {
    let data = {
      ...values,
      ["currency"]: "AUD",
      ["default_price"]: values.default_price - "0",
      ["feature"]: featureArr,
    };
    setData(data);
    createData(data);
  };

  const handleChange = (type, value) => {
    setPreview(true);
    setData({ ...data, [type]: value });
  };
  return (
    <SubLayout page={"Create Subscription"} showBack>
      <Row>
        <Col md={24} className="detail_container">
          <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={(values) => onFinish(values)}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Subscription Target*"
                  name="subscriptionFor"
                  rules={[
                    {
                      required: true,
                      message: "Please select subscription target",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select subscription target"
                    optionFilterProp="children"
                    options={[
                      {
                        value: "Candidate",
                        label: "Candidate",
                      },
                      {
                        value: "Client",
                        label: "Client",
                      },
                    ]}
                    onChange={(e) => setSubscriptionType(e)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Subscription Name*"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name",
                    },
                  ]}
                  onChange={(e) => handleChange("name", e?.target?.value)}
                >
                  <Input className="" placeholder="Subscription name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Description (About Subscription)"
                  name="description"
                >
                  <Input
                    className=""
                    placeholder="Description"
                    onChange={(e) =>
                      handleChange("description", e?.target?.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Price* (Subscription Price)"
                  name="default_price"
                  rules={[
                    {
                      required: true,
                      message: "Please enter default price",
                    },
                    {
                      min: 0,
                      message: "Please enter valid price",
                    },
                  ]}
                  onChange={(e) =>
                    handleChange("default_price", e?.target?.value)
                  }
                >
                  <Input
                    className=""
                    placeholder="1"
                    type="number"
                    min={0}
                    step="0.01"
                  />
                </Form.Item>
              </Col>
            </Row>
            {subscriptionType === "Client" && (
              <>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item name="standard_users" label="Account Users">
                      <InputNumber
                        placeholder="2"
                        type="number"
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="association_users"
                      label="Association Users"
                    >
                      <InputNumber
                        placeholder="2"
                        type="number"
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name="additional_standard_user_price"
                      label="Additional Account User Price"
                    >
                      <InputNumber
                        placeholder="2"
                        type="number"
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="additional_association_user_price"
                      label="Additional Association User Price"
                    >
                      <InputNumber
                        placeholder="2"
                        type="number"
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="recurringType"
                  label="Recurring Type* (Subscription Duration)"
                  rules={[
                    {
                      required: true,
                      message: "Please enter recurring type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select subscription target"
                    options={[
                      {
                        value: "day",
                        label: "day",
                      },
                      {
                        value: "week",
                        label: "week",
                      },
                      {
                        value: "month",
                        label: "month",
                      },
                      {
                        value: "year",
                        label: "year",
                      },
                    ]}
                    onChange={(e) => {
                      handleChange("recurringType", e);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="feature"
                  label="Feature (Subscription pointers)"
                >
                  <Input
                    onChange={(e) =>
                      setFeature(
                        e?.target?.value?.trim()
                          ? { name: e?.target?.value }
                          : ""
                      )
                    }
                    suffix={<PlusCircleFilled onClick={handleInputChange} />}
                  />
                </Form.Item>
                <div className="renderDiv">
                  {featureArr.map((e, index) => (
                    <span className="renderSpan" key={index}>
                      <span className="renderTitle">{e.name}</span>
                      <span>
                        <CloseOutlined
                          className="crossIcon"
                          onClick={() => handleDeleteFeature(index)}
                        />
                      </span>
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
            {subscriptionType === "Client" && (
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="cardType"
                    rules={[
                      {
                        required: true,
                        message: "Please select card type",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => handleCheck(e.target.checked)}
                      value={value}
                    >
                      <Radio value={1}>Type 1</Radio>
                      <Radio value={2}>Type 2</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={6} className="buttonCol">
              <Col>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
              </Col>
              <Col>
                <Button
                  className="btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      {preview && subscriptionType === "Client" && (
        <ClientPreview previewData={data} featureArr={featureArr} />
      )}
      {preview && subscriptionType === "Candidate" && (
        <CandidatePreview previewData={data} featureArr={featureArr} />
      )}
    </SubLayout>
  );
}
export default CreateUpdateSubs;
