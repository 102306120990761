import React from "react";
import SubLayout from "../../components/layout/SubLayout";
import ClientDetails from "../../components/common/recruiter/clientDetail";
import ClientCreate from "../../components/common/recruiter/clientCreate";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
function CreateRecruiter() {
  const { type } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const backUrlFunction = () => {
    navigate('/client-list', { state: { page: state?.page, filter: state?.filter, search: state?.search, path: state?.path } });
  };
  return (
    <SubLayout
      page={"Client details"}
      showBack
      backUrlFunction={backUrlFunction}
      isBackFunc={true}
    >
      {type === "view" ? <ClientDetails /> : <ClientCreate />}
    </SubLayout>
  );
}

export default CreateRecruiter;
