import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SubLayout from "../../components/layout/SubLayout";
import { Button, Col, Modal, Row, message } from "antd";
import GreenCheck from "../../Assets/support/greenCheckIcon.png";
import API_MANAGER from "../../API";
import moment from "moment";

function SupportDetail() {
  const { state } = useLocation();
  const [replybtn, setReplyBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [temp, setTemp] = useState(false);
  const [Id, setId] = useState("");
  const navigate = useNavigate();
    const backUrlFunction = () => {
    navigate("/support", {
      state: {
        page: state?.page,
        filter: state?.filter,
        profile: state?.profile,
        path: state?.path,
      },
    });
  };
  const handleReply = async () => {
    setLoading(true);
    try {
      await API_MANAGER.updateSupport(Id);
      setTemp(true);
    } catch {
      message.error("Something Went Wrong");
    }
    setReplyBtn(false);
    setLoading(false);
  };
  let contactNo = (item) => {
    return item?.contactedBy === "CANDIDATE"
      ? "+" + item?.candidate?.countryCode + "-" + item?.candidate?.phoneNumber
      : item?.userId?.countryCode + "-" + item?.userId?.phoneNumber;
  };
  return (
    <SubLayout
      page={"Support Detail"}
      showBack
      backUrlFunction={backUrlFunction}
      isBackFunc={true}
    >
      <Row className="support_detail">
        <Col span={4} className="title">
          Name:
        </Col>
        <Col span={19} className="titleValue">
          {state?.item?.name}
        </Col>
        <Col span={4} className="title">
          Email:
        </Col>
        <Col span={19} className="titleValue">
          {" "}
          {state?.item?.contactedBy === "CANDIDATE"
            ? state?.item?.userId?.email
            : state?.item?.email}
        </Col>
        <Col span={4} className="title">
          Subject:
        </Col>
        <Col span={19} className="titleValue">
          {state?.item?.subject?.name}
        </Col>
        <Col span={4} className="title">
          Contact Number:
        </Col>
        <Col span={19} className="titleValue">
          {contactNo(state?.item)}
        </Col>
        <Col span={4} className="title">
          Date:
        </Col>
        <Col span={19} className="titleValue">
          {moment(state?.item?.createdAt).format("DD-MM-YYYY")}
        </Col>
        <Col span={4} className="title">
          Content:
        </Col>
        <Col span={19} className="titleValue">
          {state?.item?.summary}
        </Col>
        {!state?.item?.isReported && (
          <Col span={4}>
            <Button
              className="primaryBtn"
              loading={loading}
              onClick={() => {
                setReplyBtn(true);
                setId(state?.item?._id);
              }}
            >
              Reply
            </Button>
          </Col>
        )}
        {!state?.item?.isReported && (
          <Col span={20}>
            {" "}
            {state?.item?.repliedTo || temp ? (
              <img
                style={{ height: "30px" }}
                src={GreenCheck}
                alt="greencheck"
              />
            ) : (
              ""
            )}{" "}
          </Col>
        )}
      </Row>
      <Modal
        footer={false}
        centered
        open={replybtn}
        onCancel={() => setReplyBtn(false)}
        wrapClassName="supportModal"
      >
        <Row>
          <Col md={24}>
            <h1>Choose Options to Reply</h1>
          </Col>
          <Col md={24} className="buttonCol">
            <a
              href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${
                state?.item?.contactedBy === "CANDIDATE"
                  ? state?.item?.userId?.email
                  : state?.item?.email
              }&su=${state?.item?.subject?.name}&body=${state?.item?.summary}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                loading={loading}
                className="reply_btn"
                onClick={handleReply}
              >
                Gmail
              </Button>
            </a>
            <a
              href={`mailto:${
                state?.item?.contactedBy === "CANDIDATE"
                  ? state?.item?.userId?.email
                  : state?.item?.email
              }?subject=${state?.item?.subject?.name}&body=${
                state?.item?.summary
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                loading={loading}
                className="reply_btn"
                onClick={handleReply}
              >
                Outlook
              </Button>
            </a>
          </Col>
        </Row>
      </Modal>
    </SubLayout>
  );
}
export default SupportDetail;
