import React from "react";
import ErrorImage from "../../Assets/layout/404.svg";
function ErrorPage() {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        height: "calc(100vh - 100px)",
        fontSize: "32px",
        textAlign: "center",
      }}
      className="errorPage"
    >
      <div>
        <img src={ErrorImage} />
        <p className="title">Page Not Found</p>
        <p className="desc">
          We are sorry, but it looks like
          <br /> that page does not exist anymore.
        </p>
      </div>
    </div>
  );
}

export default ErrorPage;
