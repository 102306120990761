import { EditFilled } from "@ant-design/icons";
import {
  Col,
  Row,
  Radio,
  message,
  Table,
  Switch,
  Spin,
  Tooltip,
  Modal,
  Form,
  Input,
  Button,
} from "antd";
import API_MANAGER from "../../../API";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "../../../Assets/delete.svg";
import EditIcon from "../../../Assets/editIcon.svg";
import GreenDot from "../../../Assets/greenStatus.svg";
import YellowDot from "../../../Assets/yellowStatus.svg";
import moment from "moment";
import MailIcon from "../../../Assets/MailIcon.svg";
import { DeleteCandidate } from "../Popup";
import GreenTick from "../../../Assets/client/GreenTracker.svg";
import RedCross from "../../../Assets/client/RedTracker.svg";
import PhoneNumber from "../common/phoneNo";

function CreateRecruiters() {
  const { id } = useParams();
  const [type, setType] = useState("CP");
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [userData, setUserData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [userEdit, setUserEdit] = useState(false);
  const [editUserId, setEditUserId] = useState("");
  const [commData, setCommData] = useState([]);
  const [form1] = Form.useForm();

  const getData = async () => {
    try {
      const response = await API_MANAGER.getRecruiterDetail({ userId: id });
      setData(response?.data?.data?.recruiter);
      setUserData(response?.data?.data?.users);
      setCampaignData(response?.data?.data?.campaign?.result);
    } catch {
      message.error("Something Went Wrong");
    }
  };
  const handleEditFunc = async (data) => {
    setEditUserId(data.id);
    form1.setFieldValue("firstName", data?.firstName);
    form1.setFieldValue("lastName", data?.lastName);
    form1.setFieldValue("countryCode", data?.countryCode);
    form1.setFieldValue("phoneNumber", data?.phoneNumber);
    form1.setFieldValue("email", data?.email);
    setUserEdit(true);
  };
  const handleUserEdit = async (values) => {
    try {
      values.userId = editUserId;
      await API_MANAGER.editRecruiter(values);
      message.success("Successfully edited user");
      getData();
      setUserEdit(false);
    } catch (error) {
      message.error(error?.response?.data?.message?.message);
    }
  };
  const toggleStatusUser = async (status, id) => {
    setLoading(true);
    try {
      await API_MANAGER.toggleUser({ isActive: status }, id);
      message.success(
        `Successfully ${status !== true ? "disabled" : "enabled"} user.`
      );
    } catch {
      message.error("Something went wrong");
    }
    setLoading(false);
  };

  const numberWithCommas = (number) => {
    var parts = number?.toString()?.split(".");
    if(parts?.length>0){
      parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parts?.join(".");
  };

  const toggleStatus = async (status, id) => {
    setLoading(true);
    try {
      await API_MANAGER.toggleCampaign({ isActive: status }, id);
      message.success(
        `Succefully ${status !== true ? "disabled" : "enabled"} campaign`
      );
    } catch {
      message.error("Something went wrong");
    }
    setLoading(false);
  };
  
  const handleResendMail = async (id) => {
    setLoading(true);
    try {
      await API_MANAGER.resendMail({
        id: id,
        userType: "recruiter",
      });
      message.success("SuccessFully sent mail");
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
    setLoading(false);
  };
  const deleteConfirm = async () => {
    setConfirmLoading(true);
    try {
      if (type === "UD") {
        await API_MANAGER.deleteRecruiter(deleteId);
        message.success("Successfully deleted client user");
      } else {
        await API_MANAGER.deleteCampaign(deleteId);
        message.success("Successfully deleted campaign feed");
      }
      getData();
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message || "something went wrong"
      );
    }
    setConfirmLoading(false);
    setDeleteModal(false);
  };
  const userType = localStorage.getItem("userType");
  const columns1 = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-list/${row._id}`)}
          >
            {10 * (1 - 1) + index + 1}
          </span>
        );
      },
    },
    {
      title: "Campaign Name",
      dataIndex: "name",
      key: "name",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-list/${row._id}`)}
          >
            {row.name}
          </span>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-list/${row._id}`)}
          >
            {moment(row.createdAt).format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      title: "Total Adverts",
      dataIndex: "advertCount",
      key: "advertCount",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-list/${row._id}`)}
          >
            {row?.advertCount}
          </span>
        );
      },
    },
    {
      title: "Target Headcount",
      dataIndex: "targetHeadCount",
      key: "targetHeadCount",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-list/${row._id}`)}
          >
            {row?.targetHeadCount}
          </span>
        );
      },
    },
    {
      title: "Total Applications",
      dataIndex: "appliedJobsCount",
      key: "appliedJobsCount",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-list/${row._id}`)}
          >
            {row?.appliedJobsCount}
          </span>
        );
      },
    },
    {
      title: "Total Shortlisted",
      dataIndex: "shortlistCount",
      key: "shortlistCount",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-list/${row._id}`)}
          >
            {row?.shortlistCount}
          </span>
        );
      },
    },
    {
      title: "Total Accepted",
      dataIndex: "offerAccepted",
      key: "offerAccepted",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => navigate(`/campaign-list/advert-list/${row._id}`)}
          >
            {row?.offerAccepted}
          </span>
        );
      },
    },
    {
      title: (
        <span>
          Enable / <br /> Disable
        </span>
      ),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (_, row) => {
        return (
          <span className="cursor-pointer">
            <Switch
              defaultChecked={row?.isActive}
              onClick={(e) => toggleStatus(e, row._id)}
            />
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "delete",
      key: "delete",
      render: (text, row) => (
        <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
          {userType === "admin" ? (
            <img
              src={DeleteIcon}
              alt="delete"
              style={{ width: "35px" }}
              className="cursor-pointer"
              onClick={() => {
                setDeleteId(row._id);
                setDeleteModal(true);
              }}
            />
          ) : (
            <img
              src={DeleteIcon}
              alt="delete"
              style={{ width: "35px" }}
              className="cursor-pointer"
            />
          )}
        </Tooltip>
      ),
    },
  ];
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span>
            <img src={row.isVerified ? GreenDot : YellowDot} alt="greendot" />{" "}
            {10 * (1 - 1) + index + 1}
          </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, row) => {
        return <span>{row?.firstName + " " + row?.lastName}</span>;
      },
    },
    {
      title: "User Type",
      dataIndex: "recruiterUserType",
      key: "recruiterUserType",
      render: (_, row) => {
        return row?.recruiterUserType ? row.recruiterUserType : "Admin";
      },
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact No",
      dataIndex: "contactNo",
      key: "contactNo",
      render: (_, row) => {
        return <span>{row?.countryCode + "-" + row?.phoneNumber}</span>;
      },
    },
    {
      title: "Registered Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, row) => {
        return <span>{moment(row?.createdAt).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: (
        <span>
          Enable / <br /> Disable
        </span>
      ),
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (text, row) => (
        <Switch
          defaultChecked={row?.isActive}
          onClick={(e) => {
            toggleStatusUser(e, row?._id);
          }}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      render: (_, row) => {
        return (
          <div style={{ display: "flex" }}>
            {!row.isVerified && (
              <span>
                <img
                  style={{ width: "35px" }}
                  src={MailIcon}
                  alt="mailIcon"
                  className="cursor-pointer"
                  onClick={() => handleResendMail(row?._id)}
                />
              </span>
            )}
            <Tooltip title={userType === "admin" ? "" : "Not Authorised"}>
              {userType === "admin" ? (
                <>
                  <span>
                    <img
                      style={{ width: "35px" }}
                      className="cursor-pointer"
                      src={EditIcon}
                      alt="edit"
                      onClick={() => {
                        handleEditFunc(row);
                      }}
                    />
                  </span>
                  <span>
                    <img
                      style={{ width: "35px" }}
                      src={DeleteIcon}
                      alt="delete"
                      className="cursor-pointer"
                      onClick={() => {
                        setDeleteModal(true);
                        setDeleteId(row?._id);
                      }}
                    />
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <img
                      style={{ width: "35px" }}
                      className="cursor-pointer"
                      src={EditIcon}
                      alt="edit"
                    />
                  </span>
                  <span>
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      style={{ width: "35px" }}
                      className="cursor-pointer"
                    />
                  </span>
                </>
              )}
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const CommunicationsColumns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/support/support-detail", {
                state: {
                  name: userData[0]?.firstName + userData[0]?.lastName,
                  contactedBy: "RECRUITER",
                  email: userData[0]?.email,
                  countryCode: userData[0]?.countryCode,
                  phoneNumber: userData[0]?.phoneNumber,
                  subject: row?.subject,
                  summary: row?.summary,
                  repliedTo: row?.repliedTo,
                  date: row?.createdAt,
                },
              });
            }}
          >
            {10 * (1 - 1) + index + 1}
          </span>
        );
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/support/support-detail", {
                state: {
                  name: userData[0]?.firstName + userData[0]?.lastName,
                  contactedBy: "RECRUITER",
                  email: userData[0]?.email,
                  countryCode: userData[0]?.countryCode,
                  phoneNumber: userData[0]?.phoneNumber,
                  subject: row?.subject,
                  summary: row?.summary,
                  repliedTo: row?.repliedTo,
                  date: row?.createdAt,
                },
              });
            }}
          >
            <span>{row?.subject?.name}</span>
          </span>
        );
      },
    },

    {
      title: "Message",
      dataIndex: "summary",
      key: "summary",
      render: (text, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/support/support-detail", {
                state: {
                  name: userData[0]?.firstName + userData[0]?.lastName,
                  contactedBy: "RECRUITER",
                  email: userData[0]?.email,
                  countryCode: userData[0]?.countryCode,
                  phoneNumber: userData[0]?.phoneNumber,
                  subject: row?.subject,
                  summary: row?.summary,
                  repliedTo: row?.repliedTo,
                  date: row?.createdAt,
                },
              });
            }}
          >
            <span>{row?.summary}</span>
          </span>
        );
      },
    },

    {
      title: "Replied",
      dataIndex: "repliedTo",
      key: "repliedTo",
      align: "center",
      render: (text, row) => {
        return (
          <span>
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate("/support/support-detail", {
                  state: {
                    name: userData[0]?.firstName + userData[0]?.lastName,
                    contactedBy: "RECRUITER",
                    email: userData[0]?.email,
                    countryCode: userData[0]?.countryCode,
                    phoneNumber: userData[0]?.phoneNumber,
                    subject: row?.subject,
                    summary: row?.summary,
                    repliedTo: row?.repliedTo,
                    date: row?.createdAt,
                  },
                });
              }}
            >
              {<img src={row?.repliedTo ? GreenTick : RedCross} />}
            </span>
          </span>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/support/support-detail", {
                state: {
                  name: userData[0]?.firstName + userData[0]?.lastName,
                  contactedBy: "RECRUITER",
                  email: userData[0]?.email,
                  countryCode: userData[0]?.countryCode,
                  phoneNumber: userData[0]?.phoneNumber,
                  subject: row?.subject,
                  summary: row?.summary,
                  repliedTo: row?.repliedTo,
                  date: row?.createdAt,
                },
              });
            }}
          >
            <span>{moment(row.createdAt).format("DD-MM-YYYY")}</span>
          </span>
        );
      },
    },
  ];
  const notificationColumn = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return <span>{10 * (1 - 1) + index + 1}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text) => {
        return (
          <span
            style={{
              display: "flex",
              flexWrap: "wrap",
              wordBreak: "break-word",
            }}
          >
            {text}
          </span>
        );
      },
    },

    {
      title: "Read",
      dataIndex: "isRead",
      key: "isRead",
      align: "center",
      render: (text) => {
        return (
          <span>
            <span>{<img src={text ? GreenTick : RedCross} />}</span>
          </span>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      render: (text) => {
        return (
          <span>
            <span>{moment(text).format("DD-MM-YYYY")}</span>
          </span>
        );
      },
    },
  ];
  const subsHistoryColumn = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return <span>{10 * (1 - 1) + index + 1}</span>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, row) => {
        return <span>{row?.subscriptionProductId?.name}</span>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (text, row) => {
        return (
          <span>
            {"$" +
              numberWithCommas(row?.subscriptionProductId?.default_price) +
              "/" +
              row?.subscriptionProductId?.recurringType}
          </span>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row) => {
        return (
          <span>
            <span>{moment(row?.createdAt).format("DD-MM-YYYY")}</span>
          </span>
        );
      },
    },
  ];
  const getCommunications = async () => {
    try {
      const response = await API_MANAGER.getContactById(
        { contactedBy: "RECRUITER" },
        id
      );
      setCommData(response?.data?.data);
    } catch {
      message.error("Something went wrong");
    }
  };
  useEffect(() => {
    getData();
    getCommunications();
  }, []);
  const handleEdit = () => {
    navigate(`/client-list/client-detail/edit/${id}`, {
      state: { editType: type },
    });
  };
  return (
    <>
      <Row>
        <Col md={23}>
          <Row className="professional_detail_container">
            <Col className="radioButtons">
              <Radio.Group
                defaultValue={type}
                onChange={(e) => setType(e?.target?.value)}
              >
                <Radio.Button value="CP">Company Profile</Radio.Button>
                <Radio.Button value="UD">User Details</Radio.Button>
                <Radio.Button value="CL">Campaigns</Radio.Button>
                <Radio.Button value="CM">Communications</Radio.Button>
                <Radio.Button value="NT">Notification</Radio.Button>
                <Radio.Button value="SH">Subscription History</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          {type === "CP" && (
            <Row>
              <Col md={24} className="create_resource">
                <Row>
                  <Col
                    xs={11}
                    style={{ marginLeft: "20px" }}
                    className="title_value"
                  >
                    {
                      <img
                        style={{ width: "80px", borderRadius: "10px" }}
                        src={data?.companyLogo}
                      />
                    }
                  </Col>
                  <Col md={12} className="edit_col">
                    <EditFilled className="edit_btn" onClick={handleEdit} />
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col md={12} className="detail_col">
                    <Row>
                      <Col md={8} className="title">
                        Company Name:
                      </Col>
                      <Col md={16} className="title_value">
                        {data.companyName}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="detail_col">
                    <Row>
                      <Col md={8} className="title">
                        Trading Name:
                      </Col>
                      <Col md={16} className="title_value">
                        {data.tradingName}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="detail_col">
                    <Row>
                      <Col md={8} className="title">
                        ACN/ABN:
                      </Col>
                      <Col md={16} className="title_value">
                        {data.companyAbn}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="detail_col">
                    <Row>
                      <Col md={8} className="title">
                        Billing Name:
                      </Col>
                      <Col md={16} className="title_value">
                        {data.billingName}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="detail_col">
                    <Row>
                      <Col md={8} className="title">
                        Billing Email:
                      </Col>
                      <Col md={16} className="title_value">
                        {data.billingEmail}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="detail_col">
                    <Row>
                      <Col className="title">Marketing Permissions:</Col>
                      <Col
                        style={{ marginLeft: "20px" }}
                        className="title_value"
                      >
                        {data.marketingPermission ? (
                          <img src={GreenTick} />
                        ) : (
                          <img src={RedCross} />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="detail_col">
                    <Row>
                      <Col md={8} className="title">
                        Registered Address:
                      </Col>
                      <Col md={14} className="title_value">
                        {data?.officeAddress?.label}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {type === "CL" && (
            <>
              <Spin spinning={loading} delay={500} />
              <Row>
                <Col md={24} className="create_resource table_container">
                  <Table
                    columns={columns1}
                    dataSource={campaignData}
                    pagination={false}
                    className="w-100"
                    rowKey={"id"}
                    loading={loading}
                    scroll={{
                      y: 500,
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          {type === "UD" && (
            <Row>
              <Col md={24} className="create_resource table_container">
                <Table
                  columns={columns}
                  dataSource={userData}
                  pagination={false}
                  className="w-100"
                  rowKey={"id"}
                  loading={loading}
                  scroll={{
                    y: 500,
                  }}
                />
              </Col>
            </Row>
          )}
          {type === "CM" && (
            <Row>
              <Col md={24} className="create_resource table_container">
                <Table
                  columns={CommunicationsColumns}
                  dataSource={commData}
                  pagination={false}
                  className="w-100"
                  rowKey={"id"}
                  loading={loading}
                  // scroll={{
                  //   y: 500,
                  // }}
                />
              </Col>
            </Row>
          )}
          {type === "NT" && (
            <Row>
              <Col md={24} className="create_resource table_container">
                <Table
                  columns={notificationColumn}
                  dataSource={data?.notifications}
                  pagination={false}
                  className="w-100"
                  rowKey={"id"}
                  loading={loading}
                />
              </Col>
            </Row>
          )}
          {type === "SH" && (
            <Row>
              <Col md={24} className="create_resource table_container">
                <Table
                  columns={subsHistoryColumn}
                  dataSource={data?.orders}
                  pagination={false}
                  className="w-100"
                  rowKey={"id"}
                  loading={loading}
                  // scroll={{
                  //   y: 500,
                  // }}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <DeleteCandidate
        confirmDelete={deleteConfirm}
        visible={openDeleteModal}
        text={type === "UD" ? "User" : type === "CL" ? "Campaign" : ""}
        setVisible={setDeleteModal}
        loading={confirmLoading}
      />
      <Modal
        width={"800px"}
        title={"Edit User"}
        open={userEdit}
        onCancel={() => {
          setUserEdit(false);
        }}
        centered
        footer={false}
      >
        <div className="create_user">
          <Form
            layout="vertical"
            requiredMark={false}
            form={form1}
            onFinish={handleUserEdit}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="First Name*"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name",
                    },
                  ]}
                >
                  <Input className="" placeholder="First name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name*"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name",
                    },
                  ]}
                >
                  <Input className="" placeholder="Last name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Email*"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email",
                    },
                    {
                      type: "email",
                      message: "Enter valid email",
                    },
                  ]}
                >
                  <Input
                    className=""
                    placeholder="Email"
                    type="email"
                    disabled={data?.userId?.isVerified}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} className="inputCol">
                <label className="inputCol1">Contact No*</label>
                <PhoneNumber />
              </Col>
            </Row>
            <Row gutter={6} className="buttonCol">
              <Col>
                <Button
                  onClick={() => {
                    setUserEdit(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
}
export default CreateRecruiters;
