import {
  Col,
  Input,
  Row,
  Form,
  Button,
  message,
  Checkbox,
  Modal,
  Image,
} from "antd";
import API_MANAGER from "../../../API";
import "react-phone-number-input/style.css";
import PhoneNumber from "../common/phoneNo";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Upload from "antd/es/upload/Upload";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";

function CreateClient() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { type } = useParams();
  const [form1] = Form.useForm();
  const [marketing, setMarketing] = useState(false);
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  const [address, setAddress] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [imageChange, setImageChange] = useState(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const createProfile = async (payload) => {
    try {
      setLoading(true);
      let imageData = null;
      if (imageChange && fileList?.length > 0) {
        const dataImage = {
          extension:
            fileList?.length > 0 ? fileList[0]?.type.split("/")[1] : "",
          type: "PROFILE",
          contentType: fileList?.length > 0 ? fileList[0]?.type : "",
        };
        const responseImage = await API_MANAGER.fileUpload(dataImage);
        imageData = responseImage?.data?.data?.key;
        await axios.put(
          responseImage?.data?.data?.url,
          fileList[0]?.originFileObj
        );
        payload.companyLogo = imageData;
      } else if (imageChange) {
        payload.companyLogo = imageData;
      }
      await API_MANAGER.createRecruiter(payload);
      message.success("Successfully created client");
      navigate(`/client-list`);
    } catch (error) {
      message.error(
        error?.response?.data?.message?.message ||
          "Facing some issues, Please try again."
      );
    }
    setLoading(false);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const handleChange = async ({ fileList: newFileList }) => {
    setImageChange(true);
    setFileList(newFileList);
  };
  const handlePreview = async (file) => {
    setPreviewOpen(true);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url ? file.url : file.preview);
  };
  const handleCancel = () => setPreviewOpen(false);
  const editProfile = async (payload) => {
    try {
      setLoading(true);
      payload.userId = id;
      console.log(payload, marketing);
      let imageData = null;
      if (imageChange && fileList?.length > 0) {
        const dataImage = {
          extension:
            fileList?.length > 0 ? fileList[0]?.type.split("/")[1] : "",
          type: "PROFILE",
          contentType: fileList?.length > 0 ? fileList[0]?.type : "",
        };
        const responseImage = await API_MANAGER.fileUpload(dataImage);
        imageData = responseImage?.data?.data?.key;
        await axios.put(
          responseImage?.data?.data?.url,
          fileList[0]?.originFileObj
        );
        payload.companyLogo = imageData;
      } else if (imageChange) {
        payload.companyLogo = imageData;
      }
      await API_MANAGER.editRecruiter(payload);
      message.success("Successfully edited client");
      navigate(-1);
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    }
    setLoading(false);
  };
  const props = {
    name: "file",
    accept: "image/png, image/jpeg, image/jpg",
    multiple: false,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const onFinish = async (values) => {
    if (type !== "edit") {
      createProfile(values);
    } else {
      editProfile(values);
    }
  };
  const checkHandle = (e) => {
    setMarketing(e?.target?.checked);
  };
  const getData = async () => {
    try {
      const response = await API_MANAGER.getRecruiterDetail({ userId: id });
      const data = response?.data?.data?.recruiter;
      form1.setFieldsValue({
        companyName: data?.companyName,
        tradingName: data?.tradingName,
        companyAbn: data?.companyAbn,
        billingName: data?.billingName,
        billingEmail: data?.billingEmail,
        countryCode: data?.userId?.countryCode,
        phoneNumber: data?.userId?.phoneNumber,
        officeAddress: data?.officeAddress,
        marketingPermission: data?.marketingPermission,
      });
      if (data?.companyLogo && data?.companyLogo?.length > 0) {
        setFileList((prevFileList) => [
          {
            ...prevFileList[0],
            url: data?.companyLogo,
          },
        ]);
      }
      setAddress(data?.officeAddress);
    } catch {
      message.error("Something went wrong");
    }
  };
  useEffect(() => {
    if (type === "edit") {
      getData();
    }
  }, []);
  return (
    <>
      <Form
        layout="vertical"
        requiredMark={false}
        form={form1}
        onFinish={onFinish}
      >
        {type === "edit" ? (
          <div className="create_resource">
            <Row gutter={24}>
              <Col md={12}>
                <Form.Item
                  name="companyName"
                  label="Company Name*"
                  rules={[
                    {
                      required: true,
                      message: "Company name",
                    },
                  ]}
                >
                  <Input className="" placeholder="Company name" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item name="tradingName" label="Trading Name">
                  <Input className="" placeholder="Trading name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12}>
                <Form.Item name="companyAbn" label="ACN/ABN">
                  <Input className="" placeholder="ACN/ABN" />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item name="billingName" label="Billing Name">
                  <Input className="" placeholder="Billing name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col md={12}>
                <Form.Item
                  name="billingEmail"
                  label="Billing Email*"
                  rules={[
                    {
                      required: true,
                      message: "Billing email",
                    },
                  ]}
                >
                  <Input
                    className=""
                    placeholder="Billing email"
                    type="email"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="officeAddress"
                  label="Registered Address*"
                  rules={[
                    {
                      required: true,
                      message: "Please enter registered address",
                    },
                  ]}
                >
                  <GooglePlacesAutocomplete
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["au"], //to set the specific country
                      },
                    }}
                    apiKey="AIzaSyBgimhyTUzrdCN2YF7Z81vu0WJbTECDCkA"
                    selectProps={{
                      placeholder: "Add address",
                      value: address,
                      onChange: (e) => {
                        console.log(e);
                        form1.setFieldsValue({
                          officeAddress: e,
                        });
                        setAddress(e);
                      },
                    }}
                    options={{
                      types: ["geocode"],
                      componentRestrictions: { country: "AU" }, // 'AU' is the country code for Australia
                    }}
                    apiOptions={{ language: "en", region: "au" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Row gutter={16}>
                  <Col>Marketing Permission </Col>
                  <Col style={{ marginTop: "-4px" }}>
                    <Form.Item
                      name="marketingPermission"
                      valuePropName="checked"
                    >
                      <Checkbox onChange={checkHandle}></Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={{ display: "flex" }}>
                <span style={{ marginRight: "20px" }}>Logo</span>
                <span>
                  <Form.Item name="companyLogo">
                    <Upload
                      {...props}
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      listType="picture-card"
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item>
                </span>
              </Col>
            </Row>
            <Row gutter={6} className="buttonCol">
              <Col>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </Col>
            </Row>
            <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
              <Image
                alt="example"
                className="previewImage"
                src={previewImage}
              />
              
            </Modal>
          </div>
        ) : (
          <div className="create_resource">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="First Name*"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter first name",
                    },
                  ]}
                >
                  <Input className="" placeholder="First name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name*"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter last name",
                    },
                  ]}
                >
                  <Input className="" placeholder="Last name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Email*"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user email",
                    },
                  ]}
                >
                  <Input className="" placeholder="Email" type="email" />
                </Form.Item>
              </Col>
              <Col xs={12} className="inputCol">
                <label className="inputCol1">Contact No*</label>
                <PhoneNumber />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Company Name*"
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company name",
                    },
                  ]}
                >
                  <Input className="" placeholder="Company name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Trading Name" name="tradingName">
                  <Input className="" placeholder="Trading name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="ACN/ABN" name="companyAbn">
                  <Input className="" placeholder="Acn/Abn" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Billing Name" name="billingName">
                  <Input className="" placeholder="Billing name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Billing Email*"
                  name="billingEmail"
                  rules={[
                    {
                      required: true,
                      message: "Please enter billing email",
                    },
                    {
                      type: "email",
                      message: "Enter valid email",
                    },
                  ]}
                >
                  <Input
                    className=""
                    placeholder="Billing email"
                    type="email"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Registered Address*"
                  name="officeAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please enter registered address",
                    },
                  ]}
                >
                  {/* <Input className="" placeholder="Registered address" /> */}
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyBgimhyTUzrdCN2YF7Z81vu0WJbTECDCkA"
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["au"], //to set the specific country
                      },
                    }}
                    selectProps={{
                      placeholder: "Add address",
                      value: address,
                      onChange: (e) => {
                        console.log(e);
                        form1.setFieldsValue({
                          officeAddress: e,
                        });
                        setAddress(e);
                      },
                    }}
                    options={{
                      types: ["geocode"],
                      componentRestrictions: { country: "AU" }, // 'AU' is the country code for Australia
                    }}
                    apiOptions={{ language: "en", region: "au" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Row gutter={16} align={"top"}>
                  <Col>Marketing Permission </Col>
                  <Col style={{ marginTop: "-4px" }}>
                    <Form.Item
                      name="marketingPermission"
                      valuePropName="checked"
                    >
                      <Checkbox onChange={checkHandle}></Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={{ display: "flex" }}>
                <span style={{ marginRight: "20px" }}>Logo:</span>
                <span>
                  <Form.Item name="companyLogo">
                    <Upload
                      {...props}
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      listType="picture-card"
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item>
                </span>
              </Col>
            </Row>
            <Row gutter={6} className="buttonCol">
              <Col>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </Col>
            </Row>
            <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
              <Image
                alt="example"
                className="previewImage"
                src={previewImage}
              />
              
            </Modal>
          </div>
        )}
      </Form>
    </>
  );
}
export default CreateClient;
