import React, { useEffect, useState } from "react";
import {
  Input,
  DatePicker,
  Row,
  Col,
  Form,
  Select,
  Upload,
  message,
  Button,
  Modal,
} from "antd";
import { useForm } from "antd/es/form/Form";
import DocumentBox from "../../../components/common/DocumentBox";
import { EditFilled, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import API_MANAGER from "../../../API";
import { s3PreUrl } from "../../../constants";
import axios from "axios";

function OtherDetailEdit({
  data,
  handleOtherDetailEdit,
  otherDetailEdit,
  citizen,
  classData,
  conditions,
  userId,
  stateTags,
  visaTypeNo,
}) {
  const [fileList, setFileList] = useState([]);
  const [otherDetailForm] = useForm();
  const [editDocument, setEditDocument] = useState(false);
  const props = {
    name: "file",
    accept: "image/jpg,image/jpeg,.pdf",
    multiple: false,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const handleUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const setFields = () => {
    let visaTypeNo = "";
    let grantNo = "";
    let passportNumber = "";
    let nationality = "";
    let licenseNumber = "";
    let stateDL = "";
    let idNumber = "";
    let state = "";
    let expiry = null;
    let expiryDate = null;

    if (data?.documentType?.name === "VISA") {
      visaTypeNo = data?.documentData?.visaTypeNo?._id;
      grantNo = data?.documentData?.grantNo;
      expiryDate = data?.documentData?.expiryDate
        ? dayjs(data?.documentData?.expiryDate)
        : null;
    }
    if (data.documentType.name === "Passport") {
      passportNumber = data.documentData.passportNumber;
      nationality = data.documentData.nationality;
      expiry = data.documentData.expiry
        ? dayjs(data.documentData.expiry)
        : null;
    }
    if (data.documentType.name === "Driver Licence") {
      licenseNumber = data.documentData.licenseNumber
        ? data.documentData.licenseNumber
        : data.documentData.licenceNumber;
      stateDL = data.documentData.state;
      expiry = data.documentData.expiry
        ? dayjs(data.documentData.expiry)
        : null;
    }
    if (data.documentType.name === "Proof of Age/Photo Card") {
      idNumber = data.documentData.idNumber;
      state = data.documentData.state;
      expiry = data.documentData.expiry
        ? dayjs(data.documentData.expiry)
        : null;
    }

    otherDetailForm.setFieldsValue({
      citizen: citizen,
      visaTypeNo: visaTypeNo,
      grantNo: grantNo,
      passportNumber: passportNumber,
      nationality: nationality,
      licenseNumber: licenseNumber,
      stateDL: stateDL,
      idNumber: idNumber,
      state: state,
      issueDate: data.documentData.issueDate
        ? dayjs(data.documentData.issueDate)
        : null,
      expiry: expiry,
      expiryDate: expiryDate,
    });
  };
  const handleFinish = async (values) => {
    //Upload Doc
    const dataDocument = {
      extension: fileList?.length > 0 ? fileList[0]?.type.split("/")[1] : "",
      type: "DOCUMENT",
      name: fileList?.length > 0 ? fileList[0].originFileObj.name : "",
      contentType: fileList?.length > 0 ? fileList[0]?.type : "",
    };
    const responseDocument = await API_MANAGER.fileUpload(dataDocument);
    await axios.put(
      responseDocument?.data?.data?.url,
      fileList[0]?.originFileObj
    );
    values.document =
      fileList?.length > 0
        ? s3PreUrl + responseDocument.data?.data?.key
        : data?.document;
    const objData = {
      userId: userId,
      document: values?.document,
      documentData: {
        ...values,
      },
    };
    values.userId = userId;
    await API_MANAGER.editCandidateDocument(objData, data._id);
    message.success("Successfully edited documents");
    handleOtherDetailEdit();
  };
  useEffect(() => {
    setFields();
  }, []);
  return (
    <>
      <Modal
        centered
        footer={false}
        open={otherDetailEdit}
        title={"Other Detail Edit"}
        className="resumeModal"
        onCancel={() => handleOtherDetailEdit(false)}
      >
        <Form form={otherDetailForm} layout="vertical" onFinish={handleFinish}>
          <Row>
            <Col xs={24}>
              <Row>
                <Col>
                  <p className="title">{data?.documentType?.name}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={18}>
                  {editDocument ? (
                    <Upload {...props} onChange={(e) => handleUpload(e)}>
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  ) : (
                    <DocumentBox date={data?.createdAt} url={data?.document} />
                  )}
                </Col>
                <Col className="editResume" xs={6}>
                  <EditFilled onClick={() => setEditDocument(true)} />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={12}>
                  {data?.documentType?.name === "VISA" && (
                    <Form.Item name="visaTypeNo" label="Visa Type No*">
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {visaTypeNo?.map((e, key) => {
                          return (
                            <Select.Option key={key} value={e?._id}>
                              {e?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                  {data?.documentType?.name === "Passport" && (
                    <Form.Item name="passportNumber" label="Passport Number*">
                      <Input placeholder="Passport number" />
                    </Form.Item>
                  )}
                  {data?.documentType?.name === "Driver Licence" && (
                    <Form.Item name="licenseNumber" label="Licence Number*">
                      <Input placeholder="Licence number" />
                    </Form.Item>
                  )}
                  {data?.documentType?.name === "Proof of Age/Photo Card" && (
                    <Form.Item name="idNumber" label="ID Number*">
                      <Input placeholder="Id number" />
                    </Form.Item>
                  )}
                </Col>
                <Col xs={12}>
                  {data?.documentType?.name === "VISA" ? (
                    <Form.Item name="grantNo" label="Grant No*">
                      <Input placeholder="Grant No" />
                    </Form.Item>
                  ) : data?.documentType?.name === "Passport" ? (
                    <Form.Item name="nationality" label="Nationality*">
                      <Input placeholder="Nationality" />
                    </Form.Item>
                  ) : data?.documentType?.name === "Driver Licence" ? (
                    <Form.Item name="stateDL" label="State*">
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {stateTags?.map((e, key) => {
                          return (
                            <Select.Option key={key} value={e?.name}>
                              {e?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item name="state" label="State*">
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {stateTags?.map((e, key) => {
                          return (
                            <Select.Option key={key} value={e?.name}>
                              {e?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
              </Row>
              {data?.documentType?.name === "Driver Licence" && (
                <Row gutter={24}>
                  <Col xs={12}>
                    <Form.Item name="class" label="Class">
                      <Select
                        className=""
                        placeholder="Class"
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {classData?.map((e, key) => {
                          return (
                            <Select.Option key={key} value={e?.id}>
                              {e?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="conditions" label="Conditions">
                      <Select
                        className=""
                        placeholder="Conditions"
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {conditions?.map((e, key) => {
                          return (
                            <Select.Option key={key} value={e?.id}>
                              {e?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row gutter={24}>
                {data.documentType.name === "VISA" && (
                  <Col xs={12}>
                    <Form.Item label="Issue Date*" name="issueDate">
                      <DatePicker
                        className="w-100"
                        format={"DD-MM-YYYY"}
                        placeholder="Select expiry date"
                      />
                    </Form.Item>
                  </Col>
                )}
                {data.documentType.name === "VISA" ? (
                  <Col xs={12}>
                    <Form.Item label="Expiry*" name="expiryDate">
                      <DatePicker
                        className="w-100"
                        format={"DD-MM-YYYY"}
                        placeholder="Select expiry date"
                        // style={{ height: "38px" }}
                        // onChange={(e, date) => {setStartDate(e,date)}}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col xs={12}>
                    <Form.Item label="Expiry*" name="expiry">
                      <DatePicker
                        className="w-100"
                        format={"DD-MM-YYYY"}
                        placeholder="Select expiry date"
                        // style={{ height: "38px" }}
                        // onChange={(e, date) => {setStartDate(e,date)}}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="buttonCol">
              <Button className="btn1" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
export default OtherDetailEdit;
